import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';

import { NonProfitsService } from '../non-profits.service';
import { FoundationStatus, SearchFoundationByAdminPgnQuery, NonProfitVM, FoundationSearchTypeEnum, FoundationCategoryCodeEnum } from '../non-profits.model';
import { Router } from '@angular/router';
import { StorageService } from '@services/storage.service';
import { LoggedInUserVM } from '@models/user.model';
import { ExportDataService } from '@services/export-data.service';

@Component({
  selector: 'app-list-ngo',
  templateUrl: './list-ngo.component.html',
  styleUrls: ['./list-ngo.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListNgoComponent implements OnInit {
  public loggedInUser: LoggedInUserVM;
  public selected = [];
  modalTitle: string = '';
  modalData: NonProfitVM;
  isSaving: boolean = false;
  isDownloading: boolean = false;

  foundationStatus: typeof FoundationStatus = FoundationStatus;
  foundationSearchTypeEnum: typeof FoundationSearchTypeEnum = FoundationSearchTypeEnum;
  foundationCategoryCodeEnum: typeof FoundationCategoryCodeEnum = FoundationCategoryCodeEnum;


  ngxDatatableSSRConfig: NgxDatatableSSRConfig;
  getAllRequestModel: SearchFoundationByAdminPgnQuery;
  filterModel: SearchFoundationByAdminPgnQuery = {
    foundationSearchTypeFilter: "All",
    foundationCategoryCode: "",

    sortColumn: '',
    sortDirection: '',
    pageNumber: 0,
    pageSize: 0,
    searchText: '',
    getAll: false
  };

  rows: NonProfitVM[] = [];  

  configureNgxDatable() {
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.ngxDatatableSSRConfig.sortColumn = 'name';
    this.ngxDatatableSSRConfig.sortDirection = 'ASC';
    this.ngxDatatableSSRConfig.pageNumber = 1;
    this.ngxDatatableSSRConfig.pageSize = 15;
    this.ngxDatatableSSRConfig.searchText = '';
    this.ngxDatatableSSRConfig.getAll = false;
  }

  constructor(
    private service: NonProfitsService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private router: Router,
    private toaster: ToastrService,    
    private storageService: StorageService,
    private exportService: ExportDataService
  ) {
    this.config.centered = true;    
    this.loggedInUser = this.storageService.retrieve('user');
  }

  ngOnInit(): void {
    this.configureNgxDatable();
    this.loadListData();
  }  

  loadListData() {
    this.ngxDatatableSSRConfig.isLoading = true;
    this.getAllRequestModel = {
      sortColumn: this.ngxDatatableSSRConfig.sortColumn,
      sortDirection: this.ngxDatatableSSRConfig.sortDirection,
      pageNumber: this.ngxDatatableSSRConfig.pageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,

      foundationSearchTypeFilter: this.filterModel.foundationSearchTypeFilter,
      foundationCategoryCode: this.filterModel.foundationCategoryCode
    };

    this.rows = [];

    this.service
      .searchFoundations(this.getAllRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        this.rows = res.response;
        const totalRecords =
          this.rows?.length > 0 ? this.rows[0].totalRecords : 0;
        this.ngxDatatableSSRConfig.totalRecords = totalRecords;
        this.ngxDatatableSSRConfig.isLoading = false;
      });
  }

  private handleError(error: any) {
    this.toaster.error(error.error.message);
    this.ngxDatatableSSRConfig.isLoading = false;
    return throwError(error);
  }

  search() {
    this.ngxDatatableSSRConfig.onPageChanged(1);
    this.loadListData();
  }

  onPageSizeChanged(pageSize: number) {
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);
    this.loadListData();
  }

  onPageChanged(pageNum: number) {
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);
    this.loadListData();
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
  }  

  openModal(modalId: any, row: NonProfitVM) {
    let editModel: NonProfitVM = row;
    this.isSaving = false;
    this.modalService.dismissAll();
    
    this.modalTitle = 'Non-Profit Details';

    this.modalData = editModel ?? new NonProfitVM();

    this.modalService.open(modalId, { size: 'md', backdrop: 'static' });
  }  
}
