import { PaginationBaseModel } from "../../shared/models/pagination.model";

export class UserVM {
  userId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  userName: string;
  coverPictureUrl: string;
  coverPictureThumbUrl: string;
  profilePictureUrl: string;
  profilePictureThumbUrl: string;
  email: string;
  phoneNumber: string;
  description: string;
  userRole: string;
  ageRange: string;
  userStatus: string;
  isLoggedIn: boolean;
  lastLoginDate: string;
  createdDate: string;
  totalRecords: number;
}

export class GetUsersRequestModel extends PaginationBaseModel{
  isAdminUsersRequired: boolean;
}

export enum UserStatus {
  Pending = 0,
  Active = 1,
  Inactive = 2,
  Rejected = 3,
  Suspended = 4,
  PasswordChangeRequired = 5,
  Deleted = 6
}

export class DeleteUserRequestModel {
  deleteUserId: string;
  deleteComment: string;
}