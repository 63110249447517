import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';

export class NgxDatatableConfig {
  totalRecordSize:number = 0;
  pageLimit:number = 15;  
  pageSize:number = 15;
  currentPage:number = 1;
  tableOffset:number = 0;
  searchText: string = "";
  columnMode = ColumnMode;
  selectionType = SelectionType;
  footerHeight: number = 40;
  rows: any[] = [];
  limitOptions = [
    {
      key: '5',
      value: 5
    },
    {
      key: '10',
      value: 10
    },
    {
      key: '15',
      value: 15
    },
    {
      key: '20',
      value: 20
    },
    {
      key: '30',
      value: 30
    },
    {
      key: '50',
      value: 50
    }
  ];

  public onPageChanged(pageNum: number) {
    this.currentPage = pageNum;
    this.tableOffset = pageNum - 1;
  }

  public onPageSizeChanged(event: number) {
    this.pageLimit = event;
    this.pageSize = event;
    this.onPageChanged(1);
  }

  public onSort(event: number) {
    this.onPageChanged(1);
  }

  public updateTotalRecord(recordSize:number){
    this.totalRecordSize = recordSize;
    this.onPageChanged(1);
  }

  public updateTableData(response:any[]){
    this.rows = response;
    this.totalRecordSize = this.rows.length;
    //this.setCurrentPage();
  }

  // private setCurrentPage(){
  //   var totalPages = this.totalRecordSize/this.pageSize;
  //   var remainder = this.totalRecordSize%this.pageSize;
  //   var currentPageNumber = remainder > 0 ? totalPages : totalPages + 1;
  //   //this.onPageChanged(currentPageNumber);
    
  //   this.currentPage = currentPageNumber;
  //   this.tableOffset = currentPageNumber-1;
  // }
}
