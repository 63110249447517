import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StorageService } from '@services/storage.service';
import { LoggedInUserVM } from '@models/user.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {

  public user: LoggedInUserVM;

  constructor(private storageservice: StorageService,private router: Router) { }

  ngOnInit() {
    this.user = this.storageservice.retrieve('user');
  }

  onLogout(){
    this.storageservice.clear();
    this.router.navigate(['/auth/login']);
  }

}
