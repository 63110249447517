<app-breadcrumb [showBackLink]="true" [title]="'Profile'" [items]="['Reports', 'User']"
  [active_item]="'Profile'"></app-breadcrumb>
<!-- Container-fluid starts-->
<div class="container-fluid mt-4">
  <div class="user-profile social-app-profile">
    <div class="row">
      <div class="col-sm-12">
        <div class="card hovercard text-center mb-0">
          <div class="cardheader" style="background-image: url({{profileVM?.profileCoverPictureUrl}}) !important;">
          </div>
          <div class="user-image">
            <div class="avatar"><img alt="" [src]="profileVM?.profilePictureThumbUrl"></div>
          </div>
          <div class="info">
            <div class="row">
              <div class="col-sm-6 col-lg-4 order-sm-1 order-xl-0">
                <div class="row">
                  <div class="col-md-6">
                    <div class="ttl-info text-left">
                      <!-- <h6><i class="fa fa-envelope"></i>E-mail</h6><span>{{profileVM?.profileUsername}}</span> -->
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="ttl-info text-left">
                      <!-- <h6><i class="fa fa-calendar"></i>Username</h6><span>{{profileVM?.profileUsername}}</span> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-4 order-sm-0 order-xl-1">
                <div class="user-designation">
                  <div class="title"><a target="_blank" href="">{{profileVM?.profileFullname}}</a></div>
                  <div class="desc mt-2">{{profileVM?.profileUsername}}</div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-4 order-sm-2 order-xl-2">
                <div class="row">
                  <div class="col-md-6">
                    <div class="ttl-info text-left">
                      <!-- <h6><i class="fa fa-phone"></i>   Contact</h6><span> NA </span> -->
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="ttl-info text-left">
                      <!-- <h6><i class="fa fa-location-arrow"></i>Age Range</h6><span>B69 Near Schoool Demo Home</span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="follow">
              <div class="row">
                <div class="col-6 text-md-right border-right">
                  <div class="follow-num counter" [CountTo]="profileVM?.followerCount" [from]="0" [duration]="2">
                    {{profileVM?.followerCount}}</div>
                  <span>Follower</span>
                </div>
                <div class="col-6 text-md-left">
                  <div class="follow-num counter" [CountTo]="profileVM?.followingCount" [from]="0" [duration]="2">
                    {{profileVM?.followingCount}}</div>
                  <span>Following</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 social-app-tab">
        <div class="card hovercard mb-0" style="margin-top: -15px;">
          <div class="info market-tabs p-0 ">
            <div class="nav nav-tabs border-tab tabs-scoial" id="top-tab" role="tablist"
              style="display:inline !important ;">
              <ngb-tabset>
                <ngb-tab>
                  <ng-template ngbTabTitle class="nav-item">Campaigns</ng-template>
                  <ng-template ngbTabContent class="tab-content">
                    <div class="tab-pane fade show" id="campaigns" role="tabpanel" aria-labelledby="campaigns">
                      <div class="row">
                        <div *ngFor="let karmaCampaign of karmaCampaignsVM" class="col-xl-4 col-md-6">
                          <div class="campaign-content">
                            <div class="row">
                              <div class="col-xl-12">
                                <div class="card">

                                  <div class="card-body">
                                    <img class="mb-3" [src]="karmaCampaign.campaignCoverPictureUrl" alt=""
                                      style="width: 100%;">
                                    <div class="project-box border-0 p-0">
                                      <span class="badge badge-secondary">{{karmaCampaign.campaignType}}</span>
                                      <div class="campaign-name">
                                        <h6>{{karmaCampaign.campaignName}}</h6>
                                        <div class="media">
                                          <img class="img-20 mr-1 rounded-circle"
                                            [src]="karmaCampaign.campaignCreatorProfilePictureThumbUrl" alt=""
                                            data-original-title="" title="">
                                          <div class="media-body">
                                            <p>{{karmaCampaign.campaignCreatorUsername}}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row details">
                                        <div class="col-6"><span>Start Date </span></div>
                                        <div class="col-6 text-success">{{karmaCampaign.campaignStartDate}}</div>
                                        <div class="col-6"> <span>End Date</span></div>
                                        <div class="col-6 text-success">{{karmaCampaign.campaignEndDate}}</div>
                                        <div class="col-6"> <span>Duration</span></div>
                                        <div class="col-6 text-success"> {{ karmaCampaign.campaignDurationInDays }}
                                          {{karmaCampaign.campaignDurationInDays == 1 ? 'Day': 'Days'}}</div>
                                      </div>
                                      <div class="customers1">
                                        <ul>
                                          <li class="d-inline-block mr-3 bg-img">
                                            <img class="img-30" [title]="karmaCampaign.foundationName"
                                              [src]="karmaCampaign.foundationLogoPictureThumbUrl">
                                          </li>
                                          <li class="d-inline-block mr-3 bg-img">
                                            <img class="img-30" [title]="karmaCampaign.trackingAppName"
                                              [src]="karmaCampaign.trackingAppIconPictureThumbUrl">
                                          </li>
                                          <li class="d-inline-block bg-img">
                                            <img class="img-30" [title]="karmaCampaign.trackingMethodName"
                                              [src]="karmaCampaign.trackingMethodIconPictureThumbUrl">
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="project-status mt-4">
                                        <div class="media mb-0">
                                          <p>{{karmaCampaign.campaignProgressPercentage * 100 | number:'1.1-1'}}% </p>
                                          <div class="media-body text-right"><span></span></div>
                                        </div>
                                        <div class="progress">
                                          <div class="progress-bar-animated bg-success" role="progressbar"
                                            [style]="'width: ' + karmaCampaign.campaignProgressPercentage * 100 + '%'"
                                            aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-tab>
                <ngb-tab>
                  <ng-template ngbTabTitle class="nav-item">Posts</ng-template>
                  <ng-template ngbTabContent class="tab-content">
                    <div class="tab-pane fade show" id="posts" role="tabpanel" aria-labelledby="posts">
                      <div class="row">
                        <div *ngFor="let post of profilePostsVM" class="col-xl-4 col-md-6">
                          <div class="posts-content">
                            <div class="row">
                              <div class="col-xl-12">
                                <div class="card">
                                  <div class="card-body">
                                    <div class="new-users-social">
                                      <div class="media"><img class="rounded-circle image-radius m-r-15"
                                          [src]="post.postCreatorProfilePictureThumbUrl" alt="">
                                        <div class="media-body">
                                          <h6 class="mb-0 f-w-700">{{post.postCreatorUsername}}</h6>
                                          <p>{{post.postCreatedAt | date:'MM-dd-yyyy' }}</p>
                                        </div><span class="pull-right mt-0"><i data-feather="more-vertical"></i></span>
                                      </div>
                                    </div><img class="img-fluid" alt="" [src]="post.postImagePictureUrl">
                                    <div class="timeline-content">
                                      <p>
                                        {{post.postDescription}}
                                      </p>
                                      <!-- <p style="color: #fff; pointer-events:none;">
                                                  sdffdfsvdfs asf sdf f fs f sf s fsfsdfs f sdf sf s f sdf sd 
                                                  sdfsdfsdfsf fsf sf sf sfs f sf s fs f sf s f sfsfsf f sdf sd
                                                  dfgdfgdgdg df d fgd gd g dgdfgdfgdfgdfg dfg dfg df gd fgdg
                                                </p> -->
                                      <div class="like-content">
                                        <!-- <span><i class="fa fa-heart font-danger"></i></span> -->
                                        <span>
                                          <span>{{ post.postLikeCount }}</span>
                                          <span><i class="fa fa-heart font-danger"></i></span>
                                        </span>
                                        <span class="pull-right comment-number">
                                          <span>{{ post.postCommentCount }}</span>
                                          <span><i class="fa fa-comments-o"></i></span>
                                        </span>
                                      </div>
                                      <div class="social-chat hidden">
                                        <div class="your-msg">
                                          <div class="media"><img class="img-50 img-fluid m-r-20 rounded-circle" alt=""
                                              src="assets/images/user/1.jpg">
                                            <div class="media-body"><span class="f-w-500">Jason Borne <span>1 Year Ago
                                                  <i class="fa fa-reply font-primary"></i></span></span>
                                              <p>we are working for the dance and sing songs. this car is very awesome
                                                for
                                                the youngster. please
                                                vote this car and like our post</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="your-msg">
                                          <div class="media"><img class="img-50 img-fluid m-r-20 rounded-circle" alt=""
                                              src="assets/images/user/1.jpg">
                                            <div class="media-body"><span class="f-w-500">Issa Bell <span>1 Year Ago <i
                                                    class="fa fa-reply font-primary"></i></span></span>
                                              <p>we are working for the dance and sing songs. this car is very awesome
                                                for
                                                the youngster. please
                                                vote this car and like our post</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="text-center"><a href="#">More Commnets</a></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-tab>
                <ngb-tab>
                  <ng-template ngbTabTitle class="nav-item">Followers/Followings</ng-template>
                  <ng-template ngbTabContent class="tab-content">
                    <div class="tab-pane fade show" id="followings" role="tabpanel" aria-labelledby="followings">
                      <div class="row">
                        <div class="col-xl-6 col-md-6">
                          <div class="followers-content">
                            <div class="row">
                              <div class="col-xl-12">
                                <div class="card">
                                  <div class="card-header px-4 py-3">
                                    <h5 class="mb-0">
                                      <span class="pl-0">Followers</span>
                                    </h5>
                                  </div>

                                  <div class="card-body">
                                    <div class="social-list">
                                      <div *ngFor="let row of followersVM" class="media">
                                        <img src="{{ row.followerProfilePictureThumbUrl }}"
                                          class="img-50 img-fluid m-r-20 rounded-circle" alt="" />
                                        <div class="media-body">
                                          <span class="d-block">{{ row.followerFullname }}</span>
                                          <a (click)="service.getUserProfileDetails(row.followerUserId, row.followerCorporatePageId)"
                                            class="profile-username">
                                            @{{ row.followerUsername }}
                                          </a>
                                        </div>
                                      </div>
                                      <div class="list-data" *ngIf="!followersVM || followersVM.length == 0">
                                        No record found.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-6 col-md-6">
                          <div class="followings-content">
                            <div class="row">
                              <div class="col-xl-12">
                                <div class="card">
                                  <div class="card-header px-4 py-3">
                                    <h5 class="mb-0">
                                      <span class="pl-0">Followings</span>
                                    </h5>
                                  </div>
                                  <div class="card-body">
                                    <div class="social-list">
                                      <div *ngFor="let row of followingsVM" class="media">
                                        <img src="{{ row.followingProfilePictureThumbUrl }}"
                                          class="img-50 img-fluid m-r-20 rounded-circle" alt="" />
                                        <div class="media-body">
                                          <span class="d-block">{{ row.followingFullname }}</span>
                                          <a (click)="service.getUserProfileDetails(row.followingUserId, row.followingCorporatePageId)"
                                            class="profile-username">
                                            @{{ row.followingUsername }}
                                          </a>
                                        </div>
                                      </div>
                                      <div class="list-data" *ngIf="!followingsVM || followingsVM.length == 0">
                                        No record found.
                                      </div>

                                      <!-- <p style="color: #fff;">
                                                    sfkfhskf ksfksjdh fkjshdkf hksd hkhsdkhfkshdfkjhkh fkjshdkfbsdbfsdbf
                                                    sdfnsdnf  ksbdfkbsjkdbfk  ksdb fkb ksdfkksfk ksd fkkj sd fk k sdkfkj

                                                  </p> -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-tab>
                <ngb-tab>
                  <ng-template ngbTabTitle class="nav-item">Donations</ng-template>
                  <ng-template ngbTabContent class="tab-content col-xl-12">
                    <div class="tab-pane fade show gallery-popup" id="donations" role="tabpanel"
                      aria-labelledby="donations">
                      <div class="row">
                        <div class="col-xl-12 col-md-12">
                          <div class="donations-content">
                            <div class="row">
                              <div class="col-xl-12">
                                <div class="row">
                                  <div class="col-xl-4">
                                    <div class="card" style="height: 600px;">
                                      <div class="card-header px-4 py-3">Transactions</div>
                                      <div class="card-body p-3">

                                        <div (click)="getTransactionDetails(row.id)" *ngFor="let row of transactionsVM"
                                          class="card mb-0 bg-cardList p-2">
                                          <div class="d-flex">
                                            <svg *ngIf="row.transactionStatus != 'succeeded'"
                                              class="col-xl-2 px-0 align-self-center" xmlns="http://www.w3.org/2000/svg"
                                              width="32" height="32" viewBox="0 0 32 32">
                                              <g id="Group_25" data-name="Group 25" transform="translate(-5225 -1357)">
                                                <g id="Group_3" data-name="Group 3" transform="translate(5225 1357)">
                                                  <g id="Group_2" data-name="Group 2">
                                                    <g id="Ellipse_23" data-name="Ellipse 23" fill="none"
                                                      stroke="#ff3e14" stroke-width="2">
                                                      <circle cx="16" cy="16" r="16" stroke="none" />
                                                      <circle cx="16" cy="16" r="15" fill="none" />
                                                    </g>
                                                  </g>
                                                </g>
                                                <g id="Group_15" data-name="Group 15" transform="translate(5225 1357)">
                                                  <g id="Group_2-2" data-name="Group 2">
                                                    <g id="Ellipse_23-2" data-name="Ellipse 23" fill="none"
                                                      stroke="#ff3e14" stroke-width="2">
                                                      <circle cx="16" cy="16" r="16" stroke="none" />
                                                      <circle cx="16" cy="16" r="15" fill="none" />
                                                    </g>
                                                  </g>
                                                </g>
                                                <g id="cancel" transform="translate(5234.173 1366.173)">
                                                  <g id="Group_19" data-name="Group 19">
                                                    <path id="Path_214" data-name="Path 214"
                                                      d="M7.584,6.83,13.5.912a.534.534,0,0,0-.755-.755L6.829,6.076.911.157A.534.534,0,0,0,.156.912L6.075,6.83.156,12.749a.534.534,0,0,0,.755.755L6.829,7.585,12.748,13.5a.534.534,0,1,0,.755-.755Z"
                                                      transform="translate(0 -0.001)" fill="#ff4714" />
                                                  </g>
                                                </g>
                                              </g>
                                            </svg>
                                            <svg *ngIf="row.transactionStatus == 'succeeded'"
                                              class="col-xl-2 px-2 align-self-center" xmlns="http://www.w3.org/2000/svg"
                                              width="50" height="50" viewBox="0 0 32 32">
                                              <g id="Group_24" data-name="Group 24" transform="translate(-5225 -1410)">
                                                <g id="Group_14" data-name="Group 14" transform="translate(5225 1410)">
                                                  <g id="Group_2" data-name="Group 2" transform="translate(0 0)">
                                                    <g id="Ellipse_23" data-name="Ellipse 23" fill="none" stroke="Green"
                                                      stroke-width="2">
                                                      <circle cx="16" cy="16" r="16" stroke="none" />
                                                      <circle cx="16" cy="16" r="15" fill="none" />
                                                    </g>
                                                    <g id="tick" transform="translate(7.069 9.409)">
                                                      <g id="Group_1" data-name="Group 1" transform="translate(0)">
                                                        <path id="Path_213" data-name="Path 213"
                                                          d="M17.367,68.256a.881.881,0,0,0-1.246,0L5.562,78.814,1.5,74.755A.881.881,0,0,0,.258,76l4.681,4.681a.882.882,0,0,0,1.246,0L17.367,69.5A.881.881,0,0,0,17.367,68.256Z"
                                                          transform="translate(0 -67.997)" fill="Green" />
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </svg>
                                            <div class="d-block col-xl-6">
                                              <div class="f-12 text-ellipsis">{{ row.benefittingFoundationName }}</div>
                                              <div class="f-10">{{ row.transactionTimeToString }}</div>
                                            </div>
                                            <div class="d-block col-xl-4 pr-2">
                                              <div class="text-right">${{ row.transactionTotalAmount }}</div>
                                              <div class="f-10 text-right text-nowrap">{{ row.transactionStatus }}</div>
                                            </div>
                                          </div>
                                        </div>
                                        <div *ngIf="!transactionsVM || transactionsVM.length == 0"
                                          style="display: block; text-align: center;">
                                          No record found.
                                        </div>

                                        <!-- <p style="color:#fff">Lorem ipsum, dolor sit amet consectetur fgdf adffgdffgfdgfd sfdgfsfb sdfgf gf dsfghdhgregd adipisicing elit. Distinctio asdfg strgthj grghyf dfesghm fghty</p> -->

                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-xl-8">
                                    <div class="card" style="height: 600px;">
                                      <div class="card-header px-4 py-3">Transactions Details</div>
                                      <div class="card-body p-2">
                                        <div *ngIf="transactionDetailsVM != null" class="col-xl-12">
                                          <div class="row col-xl-12">
                                            <svg class="col-xl-1 mt-4 align-self-center"
                                              xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                                              viewBox="0 0 32 32">
                                              <g id="Group_24" data-name="Group 24" transform="translate(-5225 -1410)">
                                                <g id="Group_14" data-name="Group 14" transform="translate(5225 1410)">
                                                  <g id="Group_2" data-name="Group 2" transform="translate(0 0)">
                                                    <g id="Ellipse_23" data-name="Ellipse 23" fill="none" stroke="Green"
                                                      stroke-width="2">
                                                      <circle cx="16" cy="16" r="16" stroke="none" />
                                                      <circle cx="16" cy="16" r="15" fill="none" />
                                                    </g>
                                                    <g id="tick" transform="translate(7.069 9.409)">
                                                      <g id="Group_1" data-name="Group 1" transform="translate(0)">
                                                        <path id="Path_213" data-name="Path 213"
                                                          d="M17.367,68.256a.881.881,0,0,0-1.246,0L5.562,78.814,1.5,74.755A.881.881,0,0,0,.258,76l4.681,4.681a.882.882,0,0,0,1.246,0L17.367,69.5A.881.881,0,0,0,17.367,68.256Z"
                                                          transform="translate(0 -67.997)" fill="Green" />
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </svg>
                                            <div class="col-xl-4 mt-4">
                                              <div class="f-10 ">{{transactionDetailsVM.transactionStatus ==
                                                'succeeded'? 'Completed' : 'Failed'}}</div>
                                              <div>${{transactionDetailsVM.transactionTotalAmount | number:'2.2-2'}}</div>
                                              <div class="f-10">{{transactionDetailsVM.transactionTimeToString}} </div>
                                            </div>
                                          </div>
                                          <div class="d-block col-xl-12 px-0 mt-4">
                                            <div class="col-xl-4">Payment Summary</div>
                                            <div class="row mx-0">
                                              <span class="col-xl-4 f-13 font-light-color">Donation Amount</span>
                                              <span
                                                class="col-xl-2 f-13 font-light-color">${{transactionDetailsVM.donationAmount | number:'2.2-2'}}</span>
                                            </div>
                                            <div class="row mx-0">
                                              <span class="col-xl-4 f-13 font-light-color">Knobl Fee</span>
                                              <span
                                                class="col-xl-2 f-13 font-light-color">${{transactionDetailsVM.platformFeeAmount | number:'2.2-2'}}</span>
                                            </div>
                                            <div class="row mx-0">
                                              <span class="col-xl-4 f-13 font-light-color">Transaction Fee</span>
                                              <span
                                                class="col-xl-2 f-13 font-light-color">${{transactionDetailsVM.paymentGatewayFeeAmount | number:'2.2-2'}}</span>
                                            </div>
                                          </div>
                                          <div class="d-block col-xl-12 mt-4">
                                            <div>Transaction Details</div>
                                            <div>
                                              <span class="f-13 font-light-color">Transaction Id:</span>
                                              <span class="f-13 font-light-color">
                                                {{transactionDetailsVM.transactionId}}</span>
                                            </div>
                                            <div>
                                              <span class="f-13 font-light-color">Benefitting:</span>
                                              <span class="f-13 font-light-color">
                                                {{transactionDetailsVM.benefittingFoundationName}}</span>
                                            </div>
                                            <div>
                                              <span class="f-13 font-light-color">Campaign:</span>
                                              <span class="f-13 font-light-color">
                                                {{transactionDetailsVM.campaignName}}</span>
                                            </div>
                                          </div>
                                          <div class="d-block col-xl-12 mt-4">
                                            <div>Payment Method:</div>
                                            <div class="card mt-2 p-4"
                                              style="height: 150px;width:300px;background-color:rgb(231, 157, 20);justify-content: end;">
                                              <div class="text-white">**** **** ****
                                                {{transactionDetailsVM.paymentCard?.cardLast4}}</div>
                                              <div class="transactionDetails-text">
                                                <span class="text-white">
                                                  {{transactionDetailsVM.paymentCard?.cardHolderName}}
                                                </span>
                                                <span class="text-white">
                                                  {{transactionDetailsVM.paymentCard?.cardExpMonth}}/{{transactionDetailsVM.paymentCard?.cardExpYear}}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div *ngIf="transactionDetailsVM == null" class="d-block text-center">
                                          <!-- No data found. -->
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </ng-template>
                </ngb-tab>
              </ngb-tabset>


            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->