<!-- content starts-->
<div class="content-wrapper">
    <app-breadcrumb [items]="['Reports','Campaigns']" [active_item]="'Creator'"></app-breadcrumb>
    <div class="card p-0 mb-0 pb-3">
      <div class="card-body px-0 pt-2 pb-0">
        <div class="ngxdt business-list">
    
          <div class="ngxdt-header">
            
            <div class="ngxdt-search">
              <div class="ngxdt-search-wrapper">
                <i class="fa fa-search"></i>
                <input type="text" (keydown)="($event.key === 'Enter')?search():true" (focus)="ngxDatatableSSRConfig.showSearchButton(true)" (blur)="ngxDatatableSSRConfig.showSearchButton(false)" placeholder="Search" [(ngModel)]="ngxDatatableSSRConfig.searchText">
                <div class="btn-search" >
                  <button *ngIf="ngxDatatableSSRConfig.searchBtnVisible" (click)="search()">
                    <i class="fa fa-filter"></i>
                  </button>
                </div>
                
              </div>
            </div>
          </div>
      
          <div class="ngxdt-body">
            <ngx-datatable
              #table
              class="bootstrap"
              [messages]="{
                emptyMessage: ngxDatatableSSRConfig.isLoading ? ngxDatatableSSRConfig.loadingMessage : ngxDatatableSSRConfig.emptyMessage | translate,
                totalMessage: ngxDatatableSSRConfig.totalMessage | translate,
                selectedMessage: false
              }"
              [footerHeight]="ngxDatatableSSRConfig.footerHeight"
              [limit]="ngxDatatableSSRConfig.pageSize"
              [rows]="rows"
              [columnMode]="ngxDatatableSSRConfig.columnMode.force"
              [offset]="ngxDatatableSSRConfig.tableOffset"
              [loadingIndicator]="false"
              [selectionType]="ngxDatatableSSRConfig.selectionType.single"
              [selected]="selected"
              (select)="openModal(modalId);"
              [scrollbarH]="true"
              [scrollbarV]="true"
              [virtualization]="false"
            >
              <ngx-datatable-column [width]="50" headerClass="make-center" cellClass="make-center" prop="id" name="#" [sortable]="false" [draggable]="false">
                <ng-template let-rowIndex="rowIndex + 1" let-value="value" ngx-datatable-cell-template >
                  {{(ngxDatatableSSRConfig.pageSize * (ngxDatatableSSRConfig.pageNumber - 1)) + rowIndex}}.
                </ng-template>
              </ngx-datatable-column>
              
              <ngx-datatable-column [width]="150" prop="campaignCreatorUsername" name="Campaign Creator" [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="150" prop="campaignName" name="Campaign Name" [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>              
              <ngx-datatable-column [width]="100" prop="campaignPledgeAmount" name="Pledged Amount" [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value | number:'2.2-2' }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="100" prop="campaignTotalRaisedAmount" name="Donated Amount" [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value | number:'2.2-2' }}
                </ng-template>
              </ngx-datatable-column>
              
              <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template>
                  <div>
                    <div class="page-size-control">
                      Show
                      <select class="page-size-dropdown" [(ngModel)]="ngxDatatableSSRConfig.pageSize" (ngModelChange)="onPageSizeChanged($event)">
                        <option *ngFor="let opt of ngxDatatableSSRConfig.limitOptions" [ngValue]="opt.value">{{opt.key}}</option>
                      </select>
                      entries
                    </div>
                    <div class="pagination-control">
                      <ngb-pagination
                            #ngbPage
                            [boundaryLinks]="false"
                            [collectionSize]="ngxDatatableSSRConfig.totalRecords"
                            [pageSize]="ngxDatatableSSRConfig.pageSize"
                            [page]="ngxDatatableSSRConfig.pageNumber"
                            [maxSize]="5"
                            (pageChange)="onPageChanged($event)">
                      </ngb-pagination>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-footer>
      
            </ngx-datatable>
          </div>
  
        </div>
      </div>
    </div>
  </div>
  <!-- content Ends-->
  
  <!-- Modal starts -->
  <ng-template #modalId let-modal>
    <!-- <form #modalForm="ngForm" class="theme-form">
      <div class="modal-header">
        <span class="modal-title">{{modalTitle}}</span>
      </div>
      <div class="modal-body">
        <input type="hidden" [(ngModel)]="modalData.id" name="id" [value]="modalData.id">
        <input type="hidden" [(ngModel)]="modalData.creatorUserId" name="creatorUserId" [value]="modalData.creatorUserId">
        
        <div class="row">
          <div class="col-md-4">
            <img [src]="modalData.profilePictureThumbUrl" class="img-thumbnail" alt="Business image" >
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">Business Name:
                  <input type="text" 
                      [(ngModel)]="modalData.name" 
                      name="name" 
                      class="form-control"
                      disabled>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">Business Username:
                  <input type="text"
                      [(ngModel)]="modalData.username" 
                      name="username" 
                      class="form-control"
                      disabled>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">Business E-mail:
                  <input type="email"
                      [(ngModel)]="modalData.email" 
                      name="email" 
                      class="form-control"
                      disabled>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">Business Website:
                  <input type="text"
                      [(ngModel)]="modalData.website" 
                      name="website" 
                      class="form-control"
                      disabled>
                </label>
              </div>
            </div>          
          </div>       
        </div>      
        <hr class="hr-gray mb-3 mt-2"/>
        <div class="row">
          <div class="col-md-4">
            <img [src]="modalData.creatorUserProfilePictureUrl" class="img-thumbnail" alt="Business user image" >
          </div>
          <div class="col-md-8 mt-3 mt-md-0">
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">Full Name:
                  <input type="text" 
                        [(ngModel)]="modalData.creatorUserFullName" 
                        name="creatorUserFullName" 
                        class="form-control"
                        disabled>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">Individual Username of Applicant:
                  <input type="text" 
                        [(ngModel)]="modalData.creatorUserUsername" 
                        name="creatorUserUsername" 
                        class="form-control"
                        disabled>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer mt-3">
        <button *ngIf="modalData.statusId == 1 || modalData.statusId == 3" type="button" class="btn btn-orange" (click)="updateStatus(modalData.id, enumBusinessStatus.Approve)" [disabled]="isSaving">
          <i *ngIf="isSaving" class="fa" [ngClass]="{'fa-spin fa-spinner': isSaving}"></i>
          {{isSaving?"Updating":"APPROVE"}}
        </button>
        <button *ngIf="modalData.statusId == 1" type="button" class="btn btn-gray ml-4" (click)="updateStatus(modalData.id, enumBusinessStatus.Decline)" [disabled]="isSaving">
          <i *ngIf="isSaving" class="fa" [ngClass]="{'fa-spin fa-spinner': isSaving}"></i>
          {{isSaving?"Updating":"DECLINE"}}
        </button>
      </div>
    </form> -->
  </ng-template>
  <!-- Modal ends -->
  

