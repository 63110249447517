<div class="image-data">
    <div class="image-container" tooltipClass="image-tooltip" triggers="hover" [autoClose]="true"
        [ngbTooltip]="imageTooltipContent" animation="true" container="body" placement="top">        
        <img [src]="imageUrl" alt="" [title]="data" [ngStyle]="{'border-radius': borderRadius}">
    </div>
    <span class="data">{{data}}</span>
</div>

<ng-template #imageTooltipContent>
    <img [src]="imageUrl" width="100" height="100" />
</ng-template>