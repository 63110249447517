<div class="notification-box" (click)="toggleNotificationMobile()">
  <svg xmlns="http://www.w3.org/2000/svg" width="19.79" height="24.171" viewBox="0 0 19.79 24.171">
    <g id="alarm" transform="translate(0.039 0.039)">
      <g id="Group_33" data-name="Group 33" transform="translate(0 0)">
        <path id="Path_11" data-name="Path 11" d="M22.369,17.359c-.627-.935-1.484-2.216-1.484-5.861V9.856A7.677,7.677,0,0,0,15.41,2.513V2.19a2.19,2.19,0,1,0-4.38,0v.323A7.677,7.677,0,0,0,5.554,9.856V11.5c0,3.645-.858,4.926-1.484,5.861a3.1,3.1,0,0,0-.706,1.8c0,1.785,3.721,2.411,6.738,2.627a3.262,3.262,0,0,0,6.235,0c3.017-.216,6.738-.842,6.738-2.627A3.1,3.1,0,0,0,22.369,17.359ZM12.124,2.19a1.1,1.1,0,0,1,2.19,0v.087a6.9,6.9,0,0,0-2.19,0V2.19ZM13.219,23a2.171,2.171,0,0,1-1.911-1.137c.776.033,1.45.042,1.911.042s1.135-.009,1.911-.042A2.171,2.171,0,0,1,13.219,23Zm0-2.19c-5.015,0-8.761-.867-8.761-1.643a2.084,2.084,0,0,1,.521-1.2c.664-.992,1.669-2.492,1.669-6.471V9.856a6.571,6.571,0,0,1,13.141,0V11.5c0,3.978,1.005,5.478,1.669,6.471a2.084,2.084,0,0,1,.521,1.2C21.98,19.939,18.234,20.807,13.219,20.807Z" transform="translate(-3.364 0)" fill="#a1a1a1" stroke="#707070" stroke-width="0.079"/>
      </g>
    </g>
  </svg>
  
  <span class="badge badge-pill badge-secondary">4</span>
</div>
<ul class="notification-dropdown onhover-show-div" [class.active]="openNotification">
  <li>
    <h6 class="main-title mb-0">Notifications</h6>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3"></i>New Applicant Added<span class="pull-right">10 min.</span>
    </p>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3"></i>Applicant Stage Change<span class="pull-right">10 min</span>
    </p>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3"></i>New Documents Added<span class="pull-right">10 min</span>
    </p>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3"></i>Applicant Stage Change<span class="pull-right">10 min</span>
    </p>
  </li>
  <li>
    <a class="btn btn-save" href="/all-notifications">Check All Notification</a>
  </li>
</ul>