import { Component, Input, OnInit } from '@angular/core';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ContentReporterVM, GetContentReportersRequestModel } from '../user-content.model';
import { UserContentService } from '../user-content.service';

@Component({
  selector: 'app-user-content-reporter-list',
  templateUrl: './user-content-reporter-list.component.html',
  styleUrls: ['./user-content-reporter-list.component.scss']
})
export class UserContentReporterListComponent implements OnInit {

  @Input() reportedContentTypeId: number;
  @Input() contentReferenceId: number;
  @Input() onlyRecentReportersRequired: boolean;

  public selected = [];
  ngxDatatableSSRConfig: NgxDatatableSSRConfig;
  getAllRequestModel: GetContentReportersRequestModel;
  rows: ContentReporterVM[] = [];

  configureNgxDatable(){
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.ngxDatatableSSRConfig.sortColumn = 'reportedContentId';
    this.ngxDatatableSSRConfig.sortDirection = 'DESC';
    this.ngxDatatableSSRConfig.pageNumber = 1;
    this.ngxDatatableSSRConfig.pageSize = 15;
    this.ngxDatatableSSRConfig.searchText = '';
    this.ngxDatatableSSRConfig.getAll = false;
  }

  constructor(
    private service: UserContentService,
    private toaster: ToastrService) 
  {
  }

  ngOnInit(): void {
    this.configureNgxDatable();
    this.getAllRequestModel = new GetContentReportersRequestModel();
    this.loadListData();
  }

  loadListData(){
    this.ngxDatatableSSRConfig.isLoading = true;
    this.getAllRequestModel = {
      sortColumn : this.ngxDatatableSSRConfig.sortColumn,
      sortDirection:this.ngxDatatableSSRConfig.sortDirection,
      pageNumber: this.ngxDatatableSSRConfig.pageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,

      reportedContentTypeId: this.reportedContentTypeId,
      contentReferenceId: this.contentReferenceId,
      onlyRecentReportersRequired: this.onlyRecentReportersRequired
    };

    this.rows = [];

    this.service.getAllContentReporters(this.getAllRequestModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      this.rows = res.response;
      const totalRecords = this.rows?.length > 0 ? this.rows[0].totalRecords : 0;
      this.ngxDatatableSSRConfig.totalRecords = totalRecords;
      this.ngxDatatableSSRConfig.isLoading = false;
    });
  }

  private handleError(error: any) {
    this.toaster.error(error.error.message);
    this.ngxDatatableSSRConfig.isLoading = false;
    return throwError(error);
  }

  search(){
    this.ngxDatatableSSRConfig.onPageChanged(1);    
    this.loadListData();
  }  

  onPageSizeChanged(pageSize:number){
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);    
    this.loadListData();
  }

  onPageChanged(pageNum:number){
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);    
    this.loadListData();
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
  }

}
