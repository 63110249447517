import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResponseModel } from '@models/response.model';
import { DataService } from '@services/data.service';

import { GetBusinessesRequestModel, UpdateStatusRequestModel } from './business.model';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(private service: DataService) { }

  //get all with pagination
  public getAllBusinesses(data: GetBusinessesRequestModel = new GetBusinessesRequestModel()): Observable<ResponseModel> { 
    let url = `/campaignapi/business/getall`;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  //update status
  public updateStatus(data: UpdateStatusRequestModel): Observable<ResponseModel> { 
    let url = `/campaignapi/business/update`;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
}
