import { Component, OnInit, Renderer2 } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbDateParserFormatter, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';

import { AllCampaignsReportVM, CampaignFilterType, GetCampaignsReportRequestModel } from '../reports.model';
import { ReportsService } from '../reports.service';
import { Router } from '@angular/router';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-report-donations',
  templateUrl: './report-donations.component.html',
  styleUrls: ['./report-donations.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class ReportDonationsComponent implements OnInit {

  public selected = [];

  onStartDatesChange(data) {
    this.filterModel.campaignStartDateStart = this._parserFormatter.format(data.startDate);
    this.filterModel.campaignStartDateEnd = this._parserFormatter.format(data.endDate);
    this.search();
  }
  onEndDatesChange(data) {
    this.filterModel.campaignEndDateStart = this._parserFormatter.format(data.startDate);
    this.filterModel.campaignEndDateEnd = this._parserFormatter.format(data.endDate);
    this.search();
  }


  //***/DateRange***

  modalTitle: string = "Campaign Details";
  modalData: AllCampaignsReportVM;
  isSaving: boolean = false;

  ngxDatatableSSRConfig: NgxDatatableSSRConfig;
  getAllRequestModel: GetCampaignsReportRequestModel;
  filterModel: GetCampaignsReportRequestModel = {
    campaignStartDateStart: null,
    campaignStartDateEnd: null,
    campaignEndDateStart: null,
    campaignEndDateEnd: null,
    campaignTypeId: 0,
    activityTypeId: 0,
    campaignFilterType: CampaignFilterType.All.toString(),

    sortColumn: '',
    sortDirection: '',
    pageNumber: 0,
    pageSize: 0,
    searchText: '',
    getAll: false
  };

  rows: AllCampaignsReportVM[] = [];

  configureNgxDatable() {
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.ngxDatatableSSRConfig.sortColumn = 'foundationName';
    this.ngxDatatableSSRConfig.sortDirection = 'DESC';
    this.ngxDatatableSSRConfig.pageNumber = 1;
    this.ngxDatatableSSRConfig.pageSize = 15;
    this.ngxDatatableSSRConfig.searchText = '';
    this.ngxDatatableSSRConfig.getAll = false;
  }


  constructor(
    private service: ReportsService,
    private router: Router,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private _parserFormatter: NgbDateParserFormatter,
    private renderer: Renderer2,
    private toaster: ToastrService
  ) {

  }

  ngOnInit(): void {
    this.configureNgxDatable();
    this.loadListData();
  }

  // getKarmagyFee(donatedAmount: number){
  //   let applicationFee = (donatedAmount * 0.08) + 0.30;
  //   let stripeFee = ((donatedAmount * 2.9 / 100) + 0.30);
  //   let karmagyFee = applicationFee - stripeFee;
  //   return karmagyFee;
  // }

  // getStripeFee(donatedAmount: number){
  //   let applicationFee = (donatedAmount * 0.08) + 0.30;
  //   let stripeFee = ((donatedAmount * 2.9 / 100) + 0.30);
  //   let karmagyFee = applicationFee - stripeFee;
  //   return stripeFee;
  // }

  loadListData() {
    this.getAllRequestModel = new GetCampaignsReportRequestModel();

    this.ngxDatatableSSRConfig.isLoading = true;
    this.getAllRequestModel = {
      sortColumn: this.ngxDatatableSSRConfig.sortColumn,
      sortDirection: this.ngxDatatableSSRConfig.sortDirection,
      pageNumber: this.ngxDatatableSSRConfig.pageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,

      activityTypeId: this.filterModel.activityTypeId,
      campaignTypeId: this.filterModel.campaignTypeId,
      campaignFilterType: this.filterModel.campaignFilterType,

      campaignStartDateStart: this.filterModel.campaignStartDateStart,
      campaignStartDateEnd: this.filterModel.campaignStartDateEnd,
      campaignEndDateStart: this.filterModel.campaignEndDateStart,
      campaignEndDateEnd: this.filterModel.campaignEndDateEnd
    };

    this.rows = [];

    this.service.getAllCampaignsReportData(this.getAllRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.rows = res.response;
        const totalRecords = this.rows?.length > 0 ? this.rows[0].totalRecords : 0;
        this.ngxDatatableSSRConfig.totalRecords = totalRecords;
        this.ngxDatatableSSRConfig.isLoading = false;
      });
  }

  private handleError(error: any) {
    this.toaster.error(error.error.message);
    this.ngxDatatableSSRConfig.isLoading = false;
    return throwError(error);
  }

  search() {
    this.ngxDatatableSSRConfig.onPageChanged(1);
    this.loadListData();
  }

  onPageSizeChanged(pageSize: number) {
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);
    this.loadListData();
  }

  onPageChanged(pageNum: number) {
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);
    this.loadListData();
  }

  getBackers(row: AllCampaignsReportVM) {
    this.router.navigate(['reports/backers'], { queryParams: { id: row.campaignId } });
  }

  getCampaignCreators(row: AllCampaignsReportVM) {
    this.router.navigate(['reports/campaign-creator'], { queryParams: { userId: row.campaignCreatorUserId, corporatePageId: row.campaignCreatorCorporatePageId } });
  }

  // getUserProfileDetails(row: AllCampaignsReportVM){
  //   this.router.navigate(['reports/user-profile'], { queryParams: { userId: row.campaignCreatorUserId, corporatePageId: row.campaignCreatorCorporatePageId } });
  // }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
  }

  getBody(): any[] {
    let data = [];
    this.rows.forEach(s => data.push([s.foundationName, s.campaignName, s.campaignCreatorUsername, s.campaignStartDate, s.campaignEndDate, s.campaignTotalPledgeAmount.toFixed(2), s.campaignTotalRaisedAmount.toFixed(2), this.getKnoblFee(s.campaignTotalRaisedAmount).toFixed(2), this.getAuthorizeFee(s.campaignTotalRaisedAmount).toFixed(2)]));
    return data;
  }

  public downloadAsPDF() {
    const doc = new jsPDF('l', 'mm', 'a4');
    autoTable(doc, {
      head: [['Non-Profit', 'Campaign Name', 'Campaign Creator', 'Start Date', 'End Date', 'Total Pledged', 'Total Collected', 'Knobl Fee', 'Authorize Fee']],
      body: this.getBody(),

    });
    doc.save('donations.pdf');
  }

  getKnoblFee(value: number): number {
    return (value > 0 ? (((value * 0.08) + 0.30) - ((value * 2.9 / 100) + 0.30)) : 0);
  }
  getAuthorizeFee(value: number): number {
    return (value > 0 ? (value * 2.9 / 100) + 0.30 : 0);
  }

  openModal(modalId: any) {
    // let editModel: AllCampaignsReportVM = this.selected[0];

    // this.isSaving = false;
    // this.modalService.dismissAll();
    // this.modalData = new AllCampaignsReportVM();

    // if(editModel !== null){
    //   this.modalTitle = "Campaign Details";
    //   // this.modalData.id = editModel.id;
    //   // this.modalData.creatorUserId = editModel.creatorUserId;
    //   // this.modalData.creatorUserFullName = editModel.creatorUserFullName;
    //   // this.modalData.creatorUserUsername = editModel.creatorUserUsername;
    //   // this.modalData.creatorUserProfilePictureUrl = editModel.creatorUserProfilePictureUrl;
    //   // this.modalData.creatorUserProfilePictureThumbUrl = editModel.creatorUserProfilePictureThumbUrl;
    //   // this.modalData.name = editModel.name;
    //   // this.modalData.username = editModel.username;
    //   // this.modalData.email = editModel.email;
    //   // this.modalData.website = editModel.website;

    //   // this.modalData.coverPictureUrl = editModel.coverPictureUrl;
    //   // this.modalData.coverPictureThumbUrl = editModel.coverPictureThumbUrl;
    //   // this.modalData.profilePictureUrl = editModel.profilePictureUrl;      
    //   // this.modalData.profilePictureThumbUrl = editModel.profilePictureThumbUrl;

    //   // this.modalData.applicationDate = editModel.applicationDate;
    //   // this.modalData.statusId = editModel.statusId;
    //   // this.modalData.status = editModel.status;      
    //   // this.modalData.totalRecords = editModel.totalRecords;
    // }
    // else{
    //   this.modalTitle = "";
    // }    
    // //this.modalService.open(modalId);
  }

}
