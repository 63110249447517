import { Component, Injectable, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';

import { BusinessService } from '../business.service';
import {
  BusinessAccountStatus,
  BusinessVM,
  GetBusinessesRequestModel,
  UpdateStatusRequestModel,
} from '../business.model';

@Component({
  selector: 'app-business-list',
  templateUrl: './business-list.component.html',
  styleUrls: ['./business-list.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})
export class BusinessListComponent implements OnInit {
  public selected = [];
  modalTitle: string = 'Business User Details';
  modalData: BusinessVM;
  isSaving: boolean = false;

  enumBusinessStatus: typeof BusinessAccountStatus = BusinessAccountStatus;

  ngxDatatableSSRConfig: NgxDatatableSSRConfig;
  getAllRequestModel: GetBusinessesRequestModel;
  rows: BusinessVM[] = [];
  configureNgxDatable() {
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.ngxDatatableSSRConfig.sortColumn = 'id';
    this.ngxDatatableSSRConfig.sortDirection = 'DESC';
    this.ngxDatatableSSRConfig.pageNumber = 1;
    this.ngxDatatableSSRConfig.pageSize = 15;
    this.ngxDatatableSSRConfig.searchText = '';
    this.ngxDatatableSSRConfig.getAll = false;
  }

  constructor(
    private service: BusinessService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private toaster: ToastrService
  ) {
    this.config.centered = true;
  }

  ngOnInit(): void {
    this.configureNgxDatable();
    this.getAllRequestModel = new GetBusinessesRequestModel();
    this.loadListData();
  }

  loadListData() {
    this.ngxDatatableSSRConfig.isLoading = true;
    this.getAllRequestModel = {
      sortColumn: this.ngxDatatableSSRConfig.sortColumn,
      sortDirection: this.ngxDatatableSSRConfig.sortDirection,
      pageNumber: this.ngxDatatableSSRConfig.pageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,
      isWebRequest: true,
    };

    this.rows = [];

    this.service
      .getAllBusinesses(this.getAllRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        this.rows = res.response;
        const totalRecords =
          this.rows?.length > 0 ? this.rows[0].totalRecords : 0;
        this.ngxDatatableSSRConfig.totalRecords = totalRecords;
        this.ngxDatatableSSRConfig.isLoading = false;
      });
  }

  private handleError(error: any) {
    this.toaster.error(error.error.message);
    this.ngxDatatableSSRConfig.isLoading = false;
    return throwError(error);
  }

  updateStatus(id: any, action: any) {
    this.isSaving = true;
    this.service
      .updateStatus({ businessUserId: id, action: action })
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        this.isSaving = false;
        this.modalService.dismissAll();
        this.loadListData();
      });
  }

  search() {
    this.ngxDatatableSSRConfig.onPageChanged(1);
    this.loadListData();
  }

  onPageSizeChanged(pageSize: number) {
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);
    this.loadListData();
  }

  onPageChanged(pageNum: number) {
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);
    this.loadListData();
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
  }

  openModal(modalId: any) {
    let editModel: BusinessVM = this.selected[0];

    this.isSaving = false;
    this.modalService.dismissAll();
    this.modalData = new BusinessVM();

    if (editModel !== null) {
      this.modalTitle = 'Business User Details';
      this.modalData.id = editModel.id;
      this.modalData.creatorUserId = editModel.creatorUserId;
      this.modalData.creatorUserFullName = editModel.creatorUserFullName;
      this.modalData.creatorUserUsername = editModel.creatorUserUsername;
      this.modalData.creatorUserProfilePictureUrl =
        editModel.creatorUserProfilePictureUrl;
      this.modalData.creatorUserProfilePictureThumbUrl =
        editModel.creatorUserProfilePictureThumbUrl;
      this.modalData.name = editModel.name;
      this.modalData.username = editModel.username;
      this.modalData.email = editModel.email;
      this.modalData.website = editModel.website;

      this.modalData.coverPictureUrl = editModel.coverPictureUrl;
      this.modalData.coverPictureThumbUrl = editModel.coverPictureThumbUrl;
      this.modalData.profilePictureUrl = editModel.profilePictureUrl;
      this.modalData.profilePictureThumbUrl = editModel.profilePictureThumbUrl;

      this.modalData.applicationDate = editModel.applicationDate;
      this.modalData.statusId = editModel.statusId;
      this.modalData.status = editModel.status;
      this.modalData.totalRecords = editModel.totalRecords;
    } else {
      this.modalTitle = '';
    }
    this.modalService.open(modalId, { backdrop: 'static' });
  }
}
