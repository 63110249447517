import { PaginationBaseModel } from "@models/pagination.model";

export class UserContent {
}

export class ReportedContentVM {
    contentReferenceId: number;

    reportedContentTypeId: number;
    reportedContentType: string;

    contentReportersTotalCount: number;
    contentReportersRecentCount: number;

    contentCreatorUserId: string;
    contentCreatorCorporatePageId: number;

    contentCreatorUsername: string;
    contentCreatorFullname: string;
    contentCreatorProfilePictureUrl: string;
    contentCreatorProfilePictureThumbUrl: string;

    totalRecords: number;
}
export class GetReportedContentsRequestModel extends PaginationBaseModel{
}


export class ContentReporterVM {
    reportedContentId: number;

    contentReporterUserId: string;
    contentReporterCorporatePageId: number;

    contentReporterUsername: string;
    contentReporterFullname: string;
    contentReporterProfilePictureUrl: string;
    contentReporterProfilePictureThumbUrl: string;

    reportedContentStatusId: number;
    reportedContentStatus: string;

    contentReportedAt: string;

    contentReviewerUserId: string;

    contentReviewerUsername: string;
    contentReviewerFullname: string;
    contentReviewerProfilePictureUrl: string;
    contentReviewerProfilePictureThumbUrl: string;

    contentReviewedAt: string;
    contentReviewComment: string;

    totalRecords: number;
}
export class GetContentReportersRequestModel extends PaginationBaseModel{
    contentReferenceId: number;
    reportedContentTypeId: number;
    onlyRecentReportersRequired: boolean;
}

export class ReviewContentRequestModel {
    contentReferenceId: number;
    reportedContentTypeId: number;
    reviewAction: string; // refer enum ReportedContentStatus
    contentReviewerUserId: string;
    contentReviewComment: string;
}

export enum ReportedContentStatus {
    Pending = "Pending",
    Review = "Review",
    Deleted = "Deleted",
    Allowed = "Allowed"
}

export class ReviewContentModalDataModel {
    isActionBtnVisible: boolean;
    isActionBtnDisabled: boolean;
    isActionAllowProcessing: boolean;
    isActionDeleteProcessing: boolean;

    contentReferenceId: number;
    reportedContentTypeId: number;
    reportedContentType: string;
}

export class GetCampaignRequestModel {
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
    campaignId: number;
}
export class CampaignVM {
    campaignId: number;
    campaignTypeId: number;
    campaignType: string;
    campaignCoverPictureUrl: string;
    campaignCoverPictureThumbUrl: string;
    campaignName: string;
    campaignDescription: string;
    campaignCreatorUserId: string;
    campaignCreatorUsername: string;
    campaignCreatorCorporatePageId: number;
    campaignCreatorProfilePictureUrl: string;
    campaignCreatorProfilePictureThumbUrl: string;
    campaignStartDate: string;
    campaignEndDate: string;
    campaignDurationInDays: number;
    trackingAppId: number;
    trackingAppName: string;
    trackingAppIconPictureUrl: string;
    trackingAppIconPictureThumbUrl: string;
    trackingMethodId: number;
    trackingMethodName: string;
    trackingMethodIconPictureUrl: string;
    trackingMethodIconPictureThumbUrl: string;
    trackingUnitId: number;
    trackingUnitName: string;
    isCampaignGoalAchieved: boolean;
    campaignActivityGoal: number;
    campaignActivityGoalText: string;
    campaignPledgeAmount: number;
    campaignPledgeAmountText: string;
    campaignTotalPledgeAmount: number;
    campaignTotalPledgeAmountText: string;
    foundationId: number;
    foundationName: string;
    foundationLogoPictureUrl: string;
    foundationLogoPictureThumbUrl: string;
    campaignStatusId: number;
    campaignStatus: string;
    calendarDaysCount: number;
    calendarDaysText: string;
    campaignProgress: string;
    campaignProgressPercentage: string;
    backersCount: number;
    isCampaignJoined: boolean;
    joiningType: string;
    joiningDate: string;
    currentPledgeAmount: number;
    campaignUserMappingId: number;
    campaignUserMappingStatusId: number;
    campaignUserMappingStatus: string;
}

export class GetPostRequestModel {
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
    postId: number;
}
export class PostVM {
    postId: number;
    postCreatorUserId: string;
    postCreatorUsername: string;
    postCreatorCorporatePageId: number;
    postCreatorProfilePictureUrl: string;
    postCreatorProfilePictureThumbUrl: string;
    isCampaignPost: boolean;
    campaignData?: CampaignData;
    postDescription: string;
    postImagePictureUrl: string;
    postImagePictureThumbUrl: string;
    isLinkAttached: boolean;
    postLinkData?: LinkData;
    postLikeCount: number;
    postCommentCount: number;
    postCreatedAt: string;
    postLastUpdatedAt: string;
    isPostLiked: boolean;
}

export class LinkData {
    postLinkUrl: string;
    linkWebSiteUrl: string;
    linkTitle: string;
    linkDescription: string;
    linkImageUrl: string;
}

export class CampaignData {
    campaignId: number;
    campaignTypeId: number;
    campaignType: string;
    campaignCoverPictureUrl: string;
    campaignCoverPictureThumbUrl: string;
    campaignName: string;
    campaignStartDate: string;
    campaignEndDate: string;
    trackingAppId: number;
    trackingAppName: string;
    trackingAppIconPictureUrl: string;
    trackingAppIconPictureThumbUrl: string;
    trackingMethodId: number;
    trackingMethodName: string;
    trackingMethodIconPictureUrl: string;
    trackingMethodIconPictureThumbUrl: string;
    trackingUnitId: number;
    trackingUnitName: string;
    campaignActivityGoal: number;
    isCampaignGoalAchieved: boolean;
    campaignPledgeAmount: number;
    foundationId: number;
    foundationName: string;
    foundationLogoPictureUrl: string;
    foundationLogoPictureThumbUrl: string;
    campaignStatusId: number;
    campaignStatus: string;
    campaignProgress: string;
    campaignProgressPercentage: string;
    campaignProgressAtPostCreation: string;
}

export class GetCommentRequestModel {
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
    commentId: number;
}
export class CommentVM {
    commentId: number;
    commentText: string;
    commentCreatorUserId: string;
    commentCreatorCorporatePageId: number | null;
    commentCreatorUsername: string;
    commentCreatorFullname: string;
    commentCreatorProfilePictureUrl: string;
    commentCreatorProfilePictureThumbUrl: string;
    commentCreatedAt: string;
    commentUpdatedAt: string;
    postId: number;
}