import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/components/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  private storage: any;
  storageSetDone: boolean = false;

  constructor(public loginService: AuthService,
    public router: Router) {
      this.setStorage();
  }

  private setStorage() {
    const rememberMe = localStorage.getItem('rememberMe');
    this.storage = (rememberMe && rememberMe == "true") ? localStorage : sessionStorage;
  }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    if(!this.storageSetDone) this.setStorage();
    let user = this.storage.getItem('user');
    if (!user || user === null) {
      this.router.navigate(['/auth/login']);
      return true
    }
    else if (user) {
      if (!Object.keys(user).length) {
        this.router.navigate(['/auth/login']);
        return true
      }
    }
    return true
  }

}
