import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';

export class NgxDatatableSSRConfig {

  sortColumn: string = '';
  sortDirection: string = 'ASC';
  pageNumber: number = 1;
  pageSize: number = 15;
  searchText: string = '';
  getAll: boolean = false;

  searchBtnVisible: boolean = false;

  totalRecords: number = 0;
  tableOffset: number = 0;

  columnMode = ColumnMode;
  selectionType = SelectionType;

  footerHeight: any = 40;
  headerHeight : any = "auto";
  rowHeight: any = "auto";

  emptyMessage: string = 'No data found.';
  loadingMessage: string = 'Please wait. Loading...';  
  totalMessage: string = 'Total';
  selectedMessage: any = false;

  isLoading: boolean = false;

  limitOptions = [    
    {
      key: '5',
      value: 5
    },
    {
      key: '10',
      value: 10
    },
    {
      key: '15',
      value: 15
    },
    {
      key: '20',
      value: 20
    },
    {
      key: '30',
      value: 30
    },
    {
      key: '50',
      value: 50
    }
  ];

  public onPageChanged(pageNum: number) {
    this.pageNumber = pageNum;
    this.tableOffset = pageNum - 1;
  }

  public onPageSizeChanged(pageNum: number) {
    this.pageSize = pageNum;
    this.onPageChanged(1);
  }

  public onSort(pageNum: number) {
    this.onPageChanged(1);
  }

  public updateTotalRecord(recordSize:number){
    this.totalRecords = recordSize;
    this.onPageChanged(1);
  }

  public showSearchButton(value:boolean = false){
    setTimeout(()=>{
      this.searchBtnVisible = value;
    },100);
  }

  public getMessage(){
    return {
      emptyMessage: this.isLoading ? this.loadingMessage : this.emptyMessage,
      totalMessage: this.totalMessage,
      selectedMessage: false
    }
  }
  
  // public hideSearchButton(){
  //   setTimeout(()=>{
  //     this.searchBtnVisible = false;
  //   },100);
  // }
  // public updateTableData(response:any){
  //   this.rows = response;
  //   this.totalRecordSize = this.rows.length;
  //   //this.setCurrentPage();
  // }

  // private setCurrentPage(){
  //   var totalPages = this.totalRecordSize/this.pageSize;
  //   var remainder = this.totalRecordSize%this.pageSize;
  //   var currentPageNumber = remainder > 0 ? totalPages : totalPages + 1;
  //   //this.onPageChanged(currentPageNumber);
    
  //   this.currentPage = currentPageNumber;
  //   this.tableOffset = currentPageNumber-1;
  // }
}
