import { Component, Injectable, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';

import { TrackingMethodsService } from './tracking-methods.service';
import { GetTrackingMethodsPgnRequestModel, TrackingMethodVM } from './tracking-methods.model';

@Component({
  selector: 'app-tracking-methods',
  templateUrl: './tracking-methods.component.html',
  styleUrls: ['./tracking-methods.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class TrackingMethodsComponent implements OnInit {
  ngxDatatableSSRConfig: NgxDatatableSSRConfig;

  // Modal
  public selected = [];
  modalTitle: string = "Tracking Methods Details";
  modalData: any;
  isSaving: boolean = false;
  isActive:boolean;
  requestModel: GetTrackingMethodsPgnRequestModel;
  rows: TrackingMethodVM[] = [];

  configureNgxDatable(){
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.ngxDatatableSSRConfig.sortColumn = 'trackingAppName';
    this.ngxDatatableSSRConfig.sortDirection = 'ASC';
    this.ngxDatatableSSRConfig.pageNumber = 1;
    this.ngxDatatableSSRConfig.pageSize = 15;
    this.ngxDatatableSSRConfig.searchText = '';
    this.ngxDatatableSSRConfig.getAll = false;
  }

  constructor(
    private service: TrackingMethodsService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private toaster: ToastrService)
  { 
    this.config.centered = true;
  }

  ngOnInit(): void {
    this.configureNgxDatable();
    this.loadListData();
  }  

  loadListData(){
    this.ngxDatatableSSRConfig.isLoading = true;
    this.requestModel = {
      sortColumn : this.ngxDatatableSSRConfig.sortColumn,
      sortDirection:this.ngxDatatableSSRConfig.sortDirection,
      pageNumber: this.ngxDatatableSSRConfig.pageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll
    };

    this.service.getTrackingMethods(this.requestModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      this.rows = res.response;
      const totalRecords = this.rows?.length > 0 ? this.rows[0].totalRecords : 0;
      this.ngxDatatableSSRConfig.totalRecords = totalRecords;
      this.ngxDatatableSSRConfig.isLoading = false;
    });
  }

  private handleError(error: any) {
    this.toaster.error(error.error.message);
    this.ngxDatatableSSRConfig.isLoading = false;
    return throwError(error);
  }

  
  search(){
    this.ngxDatatableSSRConfig.onPageChanged(1);
    this.loadListData();
  }  

  onPageSizeChanged(pageSize:number){
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);    
    this.loadListData();
  }

  onPageChanged(pageNum:number){
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);    
    this.loadListData();
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
  }
  
  openModal(modalId:any) {
    // let editModel: TrackingMethodVM = this.selected[0];

    // this.isSaving = false;
    // this.modalService.dismissAll();
    // this.modalData = new TrackingMethodVM();

    // if(editModel !== null){
    //   this.modalTitle = "Tracking Methods Details";

    //   this.modalData.id = editModel.id;
    //   this.modalData.name = editModel.name;      
    //   this.modalData.trackingAppId = editModel.trackingAppId;
    //   this.modalData.trackingAppName = editModel.trackingAppName;
    //   this.modalData.isActive = editModel.isActive;
    //   this.modalData.totalRecords = editModel.totalRecords;
    // }
    // else{
    //   this.modalTitle = "";
    // }    
    // this.modalService.open(modalId);
  }

}
