<!-- content starts-->
<div class="content-wrapper">
    <app-breadcrumb [items]="[]" [active_item]="'User Content'"></app-breadcrumb>
    <div class="card">
      <div class="card-body">
        <div class="ngxdt reported-content-list">
    
          <div class="ngxdt-header">            
            <div class="ngxdt-search">
              <div class="ngxdt-search-wrapper">
                <i class="fa fa-search"></i>
                <input type="text" (keydown)="($event.key === 'Enter')?search():true" (focus)="ngxDatatableSSRConfig.showSearchButton(true)" (blur)="ngxDatatableSSRConfig.showSearchButton(false)" placeholder="Search" [(ngModel)]="ngxDatatableSSRConfig.searchText">
                <div class="btn-search" >
                  <button *ngIf="ngxDatatableSSRConfig.searchBtnVisible" (click)="search()">
                    <i class="fa fa-filter"></i>
                  </button>
                </div>                
              </div>
            </div>
          </div>
      
          <div class="ngxdt-body">
            <ngx-datatable
              #table
              class="bootstrap"
              [messages]="{
                emptyMessage: ngxDatatableSSRConfig.isLoading ? ngxDatatableSSRConfig.loadingMessage : ngxDatatableSSRConfig.emptyMessage | translate,
                totalMessage: ngxDatatableSSRConfig.totalMessage | translate,
                selectedMessage: false
              }"
              [footerHeight]="ngxDatatableSSRConfig.footerHeight"
              [limit]="ngxDatatableSSRConfig.pageSize"
              [rows]="rows"
              [columnMode]="ngxDatatableSSRConfig.columnMode.force"
              [offset]="ngxDatatableSSRConfig.tableOffset"
              [loadingIndicator]="false"
              [selectionType]="ngxDatatableSSRConfig.selectionType.cell"
              [scrollbarH]="true"
              [scrollbarV]="true"
              [virtualization]="false"
            >
              <ngx-datatable-column [width]="50" headerClass="make-center" cellClass="make-center" prop="contentReferenceId" name="#" [sortable]="false" [draggable]="false">
                <ng-template let-rowIndex="rowIndex + 1" let-value="value" ngx-datatable-cell-template >
                  {{(ngxDatatableSSRConfig.pageSize * (ngxDatatableSSRConfig.pageNumber - 1)) + rowIndex}}.
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="150" prop="contentCreatorFullname" name="Content Creator" [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <span title="{{ value }}">
                    {{ value }}
                  </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="150" prop="contentCreatorUsername" name="User Name" [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <app-data-with-image [data]="value" title="{{ value }}" [imageUrl]="row.contentCreatorProfilePictureThumbUrl"></app-data-with-image>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="120" prop="reportedContentType" name="Content Type" [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="120" headerClass="make-center" cellClass="make-center" prop="contentReportersTotalCount" name="Total Reporters" [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a *ngIf="value > 0" (click)="showContentReporters(modalReporters, row, false)">{{ value }}</a>
                  <span *ngIf="value == 0">{{ value }}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="120" headerClass="make-center" cellClass="make-center" prop="contentReportersRecentCount" name="Recent Reporters" [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a *ngIf="value > 0" (click)="showContentReporters(modalReporters, row, true)">{{ value }}</a>
                  <span *ngIf="value == 0">{{ value }}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [minWidth]="100" name="Action" [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <Button type="button" class="btn btn-action-{{row.contentReportersRecentCount > 0 ? 'orange' : 'dark'}}" (click)="openModal(modalReviewContent, row)">
                    {{row.contentReportersRecentCount > 0 ? 'Review Content' : 'View Content'}}
                  </Button>
                </ng-template>
              </ngx-datatable-column>
              
              <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template>
                  <div>
                    <div class="page-size-control">
                      Show
                      <select class="page-size-dropdown" [(ngModel)]="ngxDatatableSSRConfig.pageSize" (ngModelChange)="onPageSizeChanged($event)">
                        <option *ngFor="let opt of ngxDatatableSSRConfig.limitOptions" [ngValue]="opt.value">{{opt.key}}</option>
                      </select>
                      entries
                    </div>
                    <div class="pagination-control">
                      <ngb-pagination
                            #ngbPage
                            [boundaryLinks]="false"
                            [collectionSize]="ngxDatatableSSRConfig.totalRecords"
                            [pageSize]="ngxDatatableSSRConfig.pageSize"
                            [page]="ngxDatatableSSRConfig.pageNumber"
                            [maxSize]="5"
                            (pageChange)="onPageChanged($event)">
                      </ngb-pagination>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-footer>
      
            </ngx-datatable>
          </div>
  
        </div>
      </div>
    </div>
  </div>
  <!-- content Ends-->
  
  <!-- Modal starts -->
  <ng-template #modalReporters let-modal>
    <div class="modal-header">
      <span class="modal-title">Content Reporters</span>
      <button type="button" class="btn btn-close" (click)="modal.close()">X</button>
    </div>
    <div class="modal-body pt-0">
      <div class="row">
        <div class="col-md-12">
          <app-user-content-reporter-list 
            [reportedContentTypeId] = "contentReportersModalData.reportedContentTypeId"
            [contentReferenceId] = "contentReportersModalData.contentReferenceId"
            [onlyRecentReportersRequired] = "contentReportersModalData.onlyRecentReportersRequired">
          </app-user-content-reporter-list>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #modalReviewContent let-modal>
    <div class="modal-header mb-3">
      <span class="modal-title mt-2">{{reviewContentModalData.reportedContentType}} Details</span>
      <button type="button" class="btn btn-close" (click)="modal.close()">X</button>
    </div>
    <div class="modal-body pt-0">
      <div class="row">
        <div class="col-md-12">
          <app-content-details-campaign 
            *ngIf="reviewContentModalData.reportedContentType == 'Campaign'" 
            [contentReferenceId]="reviewContentModalData.contentReferenceId">
          </app-content-details-campaign>
          <app-content-details-post 
            *ngIf="reviewContentModalData.reportedContentType == 'Post'" 
            [contentReferenceId]="reviewContentModalData.contentReferenceId">
          </app-content-details-post>
          <app-content-details-comment 
            *ngIf="reviewContentModalData.reportedContentType == 'Comment'" 
            [contentReferenceId]="reviewContentModalData.contentReferenceId">
          </app-content-details-comment>
        </div>
      </div>
    </div>
    <div class="modal-footer mt-3">      
      <button *ngIf="reviewContentModalData.isActionBtnVisible" type="button" class="btn btn-orange" (click)="reviewContent(reviewContentModalData, reviewAction.Allowed)" [disabled]="reviewContentModalData.isActionBtnDisabled">
        {{reviewContentModalData.isActionAllowProcessing?"Processing...":"ALLOW"}}
      </button>
      <button *ngIf="reviewContentModalData.isActionBtnVisible" type="button" class="btn btn-gray ml-4" (click)="reviewContent(reviewContentModalData, reviewAction.Deleted)" [disabled]="reviewContentModalData.isActionBtnDisabled">
        {{reviewContentModalData.isActionDeleteProcessing?"Processing...":"REMOVE"}}
      </button>
    </div>
  </ng-template>
  <!-- Modal ends -->
  
