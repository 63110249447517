import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';

export default class Validation {

  static PasswordPattern: string = "^.*(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!*@#$%^&+=]).*$";
  static PhonePattern: string = "^[0-9]{10}$";
  static EINPattern: string = "^[0-9]{2}[-][0-9]{7}$";
  static EINPattern1: string = "^[0-9]{2}[-]?[0-9]*$";

  static match(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);
      if (checkControl.errors && !checkControl.errors.match) {
        return null;
      }
      if (control.value !== checkControl.value) {
        controls.get(checkControlName).setErrors({ match: true });
        return { match: true };
      } else {
        return null;
      }
    };
  }

  static requiredFileType(allowedTypes: string[]) {
    return (control: AbstractControl) => {
      allowedTypes.forEach(s => s = s.toLowerCase());

      const file = control.value;

      if (file && file != "") {
        const extension = file.split('.').pop();
        if (extension && !allowedTypes.includes(extension.toLowerCase())) {
          control.setErrors({ requiredFileType: true });
          return { requiredFileType: true };
        }

        return null;
      }

      return null;
    };
  }

  static maxFileSize(sizeInKB: any): ValidatorFn {
    return (control: AbstractControl) => {
      let sizeInByte = sizeInKB * 1024;
      const file: File = control.value;
        
      if (file) {
        var size = file.size;
        //  var path = file.replace(/^.*[\\\/]/, "");
        //const fileSize = file[0].size;
        const fileSizeInKB = Math.round(sizeInKB / 1024);
        if (fileSizeInKB >= 10) {
          return {
            maxFileSize: true
          };
          // if (file && file != "") {
          //   const extension = file.split('.').pop();
          //   // if (extension && !allowedTypes.includes(extension.toLowerCase())) {
          //   //   control.setErrors({ requiredFileType: true });
          //   //   return { requiredFileType: true };
          //   // }

          //   return null;
          // }

          // return null;
        };
      }

    }
  }
}