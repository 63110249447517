import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { CountToModule } from 'angular-count-to';
import { GalleryModule } from '@ks89/angular-modal-gallery';

// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { AppComponent } from './app.component';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { LoginComponent } from './components/auth/login/login.component';
import { PopulateEnumPipe } from './shared/pipes/populate-enum.pipe';
import { SettingsComponent } from './components/settings/settings.component';
import { RptCampaignsComponent } from './components/reports/campaigns/rpt-campaigns/rpt-campaigns.component';
import { ListNgoComponent } from './components/non-profits/list-ngo/list-ngo.component';
import { TrackingMethodsComponent } from './components/masters/tracking-methods/tracking-methods.component';
import { TrackingAppsComponent } from './components/masters/tracking-apps/tracking-apps.component';
import { UserListComponent } from './components/user-management/user-list/user-list.component';
import { BusinessListComponent } from './components/business/business-list/business-list.component';
import { AdminUserListComponent } from './components/admin-user/admin-user-list/admin-user-list.component';
import { CampaignBackersListComponent } from './components/reports/campaign-backers-list/campaign-backers-list.component';
import { CampaignCreatorCampaignsComponent } from './components/reports/campaign-creator-campaigns/campaign-creator-campaigns.component';
import { UserProfileComponent } from './components/reports/user-profile/user-profile.component';
import { UserContentListComponent } from './components/user-content/user-content-list/user-content-list.component';
import { UserContentReporterListComponent } from './components/user-content/user-content-reporter-list/user-content-reporter-list.component';
import { ContentDetailsCampaignComponent } from './components/user-content/content-details-campaign/content-details-campaign.component';
import { ContentDetailsPostComponent } from './components/user-content/content-details-post/content-details-post.component';
import { ContentDetailsCommentComponent } from './components/user-content/content-details-comment/content-details-comment.component';
import { ReportPayoutsHistoryComponent } from './components/reports/report-payouts-history/report-payouts-history.component';
import { ReportDonationsComponent } from './components/reports/report-donations/report-donations.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    PopulateEnumPipe,
    ListNgoComponent,
    SettingsComponent,
    UserListComponent,
    RptCampaignsComponent,
    TrackingMethodsComponent,
    TrackingAppsComponent,
    BusinessListComponent,
    AdminUserListComponent,
    CampaignBackersListComponent,
    CampaignCreatorCampaignsComponent,
    UserProfileComponent,
    UserContentListComponent,
    UserContentReporterListComponent,
    ContentDetailsCampaignComponent,
    ContentDetailsPostComponent,
    ContentDetailsCommentComponent,
    ReportPayoutsHistoryComponent,
    ReportDonationsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    NgxDatatableModule,
    CountToModule,
    GalleryModule.forRoot(),
    DropzoneModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
