import { Component, Injectable, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';

import { TrackingAppsService } from './tracking-apps.service';
import { GetTrackingAppsPgnRequestModel, TrackingAppVM } from './tracking-apps.model';

@Component({
  selector: 'app-tracking-apps',
  templateUrl: './tracking-apps.component.html',
  styleUrls: ['./tracking-apps.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class TrackingAppsComponent implements OnInit {  
  ngxDatatableSSRConfig: NgxDatatableSSRConfig;

  // Modal
  public selected = [];
  modalTitle: string = "Tracking App Details";
  modalData: any;
  isSaving: boolean = false;
  isActive:boolean;
  requestModel: GetTrackingAppsPgnRequestModel;
  rows: TrackingAppVM[] = [];

  configureNgxDatable(){
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.ngxDatatableSSRConfig.sortColumn = 'displayOrder';
    this.ngxDatatableSSRConfig.sortDirection = 'ASC';
    this.ngxDatatableSSRConfig.pageNumber = 1;
    this.ngxDatatableSSRConfig.pageSize = 15;
    this.ngxDatatableSSRConfig.searchText = '';
    this.ngxDatatableSSRConfig.getAll = false;
  }

  constructor(
    private service: TrackingAppsService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private toaster: ToastrService) 
  { 
    this.config.centered = true;
  }

  ngOnInit(): void {
    this.configureNgxDatable();
    this.loadListData();
  }

  loadListData(){
    this.ngxDatatableSSRConfig.isLoading = true;
    this.requestModel = {
      sortColumn : this.ngxDatatableSSRConfig.sortColumn,
      sortDirection:this.ngxDatatableSSRConfig.sortDirection,
      pageNumber: this.ngxDatatableSSRConfig.pageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll
    };

    this.service.getTrackingApps(this.requestModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      this.rows = res.response;
      const totalRecords = this.rows?.length > 0 ? this.rows[0].totalRecords : 0;
      this.ngxDatatableSSRConfig.totalRecords = totalRecords;
      this.ngxDatatableSSRConfig.isLoading = false;
    });
  }

  private handleError(error: any) {
    this.toaster.error(error.error.message);
    this.ngxDatatableSSRConfig.isLoading = false;
    return throwError(error);
  }  

  search(){
    this.ngxDatatableSSRConfig.onPageChanged(1);
    this.loadListData();    
  }  

  onPageSizeChanged(pageSize:number){
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);    
    this.loadListData();
  }

  onPageChanged(pageNum:number){
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);
    this.loadListData();
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
  }
  
  openModal(modalId:any) {
    // let editModel: TrackingAppVM = this.selected[0];

    // this.isSaving = false;
    // this.modalService.dismissAll();
    // this.modalData = new TrackingAppVM();

    // if(editModel !== null){
    //   this.modalTitle = "Tracking App Details";

    //   this.modalData.id = editModel.id;
    //   this.modalData.name = editModel.name;
    //   this.modalData.isActive = editModel.isActive;
    //   this.modalData.totalRecords = editModel.totalRecords;
    // }
    // else{
    //   this.modalTitle = "";
    // }    
    // this.modalService.open(modalId);
  }

  onStatusChange(event, row)
  {
    // console.log('changed value: ',event);
    // console.log('row value: ',row);
  }

}
