import { Component, OnInit } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import { LoginModel } from '../auth.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hidePassword: boolean = true;
  loginModel = new LoginModel();
  isLogging:boolean = false;  

  constructor(
    private loginService: AuthService, 
    private storageservice: StorageService,
    private router: Router,
    private toaster: ToastrService) 
  {
    const rememberMe = localStorage.getItem('rememberMe');
    if(rememberMe){
      const storage = (rememberMe == "true") ? localStorage : sessionStorage;
      const token = storage.getItem('authorizationDataIdToken');
      const user = storage.getItem('user');
      if (token && user) {
        this.loginService.isLoggedIn = true;
        this.router.navigate(['/user-management/user-list']); // redirect to landing page 
      }      
    }
    this.storageservice.clear();
    this.loginService.isLoggedIn = false;
  }

  ngOnInit(): void {    
    this.hidePassword = true;
    this.isLogging = false;
  }

  changePasswordVisibility(){
    this.hidePassword = !this.hidePassword;
  }

  authenticate() {
    this.isLogging = true;
    if(this.loginModel.username == "" || this.loginModel.username == undefined){
      this.toaster.error("username cannot be empty");
      this.isLogging = false;
      return;
    }
    if(this.loginModel.password == "" || this.loginModel.password == undefined){
      this.toaster.error("password cannot be empty");
      this.isLogging = false;
      return;
    }

    this.loginService.isLoggedIn = false;

    this.loginService.signIn(this.loginModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess) {
          this.storageservice.setRememberMe(this.loginModel.rememberMe ? "true" : "false");
          this.storageservice.store('authorizationDataIdToken', res.response.accessToken);
          this.storageservice.store('user', res.response.user);
          this.loginService.isLoggedIn = true;
          this.isLogging = false;
          this.router.navigate(['/user-management/user-list']); // redirect to landing page 
        }
        this.isLogging = false;
    });
  }
  
  private handleError(error: any) {
    this.isLogging = false;
    if(error.status === 0 && error.statusText === 'Unknown Error'){
      return throwError(error);
    }
    else if(error.error.message === "Input validation failed!"){
      this.toaster.error(error.error.errors[0]);
    }
    else{
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }
}