<!-- content starts-->
<div class="content-wrapper">
  <app-breadcrumb [items]="['User Management', 'Non-Profit']" [active_item]="'List'"></app-breadcrumb>
  <div class="card">
    <div class="card-body">
      <div class="ngxdt non-profit-list">
        <div class="ngxdt-header">
          <div class="ngxdt-select">
            <select name="foundationSearchType" (change)="search()"
              [(ngModel)]="filterModel.foundationSearchTypeFilter">
              <option class="text-muted" value="All">All</option>
              <option class="text-muted" value="TrendingBenevity">Trending - Benevity</option>
              <option class="text-muted" value="TrendingKnobl">Trending - Knobl</option>
            </select>
          </div>
          <div *ngIf="filterModel.foundationSearchTypeFilter == 'All'" class="ngxdt-select d-flex">
            <select class="ml-auto select-orange" name="foundationSearchType" (change)="search()"
              [(ngModel)]="filterModel.foundationCategoryCode">
              <option class="text-muted" value="">Select Category</option>
              <!-- <option class="text-muted" *ngFor="let option of foundationCategoryCodeEnum | populateEnum"
                          [value]="option.key">{{option.value}}</option> -->
              <option class="text-muted" value="A">Arts, Culture & Humanities</option>
              <option class="text-muted" value="B">Education</option>
              <option class="text-muted" value="C">Environment</option>
              <option class="text-muted" value="D">Animal-Related</option>
              <option class="text-muted" value="E">Health Care</option>
              <option class="text-muted" value="F">Mental Health & Crisis Intervention</option>
              <option class="text-muted" value="G">Voluntary Health Associations & Medical Disciplines</option>
              <option class="text-muted" value="H">Medical Research</option>
              <option class="text-muted" value="I">Crime & Legal-Related</option>
              <option class="text-muted" value="J">Employment</option>
              <option class="text-muted" value="K">Food, Agriculture & Nutrition</option>
              <option class="text-muted" value="L">Housing & Shelter</option>
              <option class="text-muted" value="M">Public Safety, Disaster Preparedness & Relief</option>
              <option class="text-muted" value="N">Recreation & Sports</option>
              <option class="text-muted" value="O">Youth Development</option>
              <option class="text-muted" value="P">Human Services</option>
              <option class="text-muted" value="Q">International, Foreign Affairs & National Security</option>
              <option class="text-muted" value="R">Civil Rights, Social Action & Advocacy</option>
              <option class="text-muted" value="S">Community Improvement & Capacity Building</option>
              <option class="text-muted" value="T">Philanthropy, Voluntarism & Grantmaking Foundations</option>
              <option class="text-muted" value="U">Science & Technology</option>
              <option class="text-muted" value="V">Social Science</option>
              <option class="text-muted" value="W">Public & Societal Benefit</option>
              <option class="text-muted" value="X">Religion-Related</option>
              <option class="text-muted" value="Y">Mutual & Membership Benefit</option>
              <option class="text-muted" value="Z">Unknown</option>
            </select>
          </div>
          
          <div class="ngxdt-search">
            <div class="ngxdt-search-wrapper">
              <i class="fa fa-search"></i>
              <input type="text" (keydown)="$event.key === 'Enter' ? search() : true"
                (focus)="ngxDatatableSSRConfig.showSearchButton(true)"
                (blur)="ngxDatatableSSRConfig.showSearchButton(false)" placeholder="Search"
                [(ngModel)]="ngxDatatableSSRConfig.searchText" />
              <div class="btn-search">
                <button *ngIf="ngxDatatableSSRConfig.searchBtnVisible" (click)="search()">
                  <i class="fa fa-filter"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="ngxdt-body">
          <ngx-datatable #table class="bootstrap" [messages]="{
              emptyMessage: ngxDatatableSSRConfig.isLoading
                ? ngxDatatableSSRConfig.loadingMessage
                : (ngxDatatableSSRConfig.emptyMessage | translate),
              totalMessage: ngxDatatableSSRConfig.totalMessage | translate,
              selectedMessage: false
            }" [footerHeight]="ngxDatatableSSRConfig.footerHeight" [limit]="ngxDatatableSSRConfig.pageSize"
            [rows]="rows" [columnMode]="ngxDatatableSSRConfig.columnMode.force"
            [offset]="ngxDatatableSSRConfig.tableOffset" [loadingIndicator]="false"
            [selectionType]="ngxDatatableSSRConfig.selectionType.cell" [scrollbarH]="true" [scrollbarV]="true" [virtualization]="false">
            <ngx-datatable-column [width]="50" [maxWidth]="100" headerClass="make-center" cellClass="make-center" prop="id" name="#"
              [sortable]="false" [draggable]="false">
              <ng-template let-rowIndex="rowIndex + 1" let-value="value" ngx-datatable-cell-template>
                {{
                ngxDatatableSSRConfig.pageSize *
                (ngxDatatableSSRConfig.pageNumber - 1) +
                rowIndex
                }}.
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="200" prop="name" name="Name" [sortable]="false" [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <!-- <app-data-with-image [data]="value" title="{{ value }}"
                  [imageUrl]="row.logoUrl"></app-data-with-image> -->
                {{ value }}
              </ng-template>
            </ngx-datatable-column>            
            <ngx-datatable-column [width]="400" prop="description" name="Description" [sortable]="false"
              [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>            
            <ngx-datatable-column [width]="150" [maxWidth]="250" name="Action" headerClass="make-center" cellClass="make-center" [sortable]="false" [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <button type="button" class="btn btn-action-gray-filled ml-3" (click)="openModal(modalId, row)">View Details</button>                
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <div>
                  <div class="page-size-control">
                    Show
                    <select class="page-size-dropdown" [(ngModel)]="ngxDatatableSSRConfig.pageSize"
                      (ngModelChange)="onPageSizeChanged($event)">
                      <option *ngFor="let opt of ngxDatatableSSRConfig.limitOptions" [ngValue]="opt.value">
                        {{ opt.key }}
                      </option>
                    </select>
                    entries
                  </div>
                  <div class="pagination-control">
                    <ngb-pagination #ngbPage [boundaryLinks]="false"
                      [collectionSize]="ngxDatatableSSRConfig.totalRecords" [pageSize]="ngxDatatableSSRConfig.pageSize"
                      [page]="ngxDatatableSSRConfig.pageNumber" [maxSize]="5" (pageChange)="onPageChanged($event)">
                    </ngb-pagination>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- content Ends-->

<!-- Modal starts -->
<ng-template #modalId let-modal>
  <form #modalForm="ngForm" class="theme-form">
    <div class="modal-header">
      <span class="modal-title mt-2">{{ modalTitle }}</span>
      <button type="button" class="btn btn-close" (click)="modal.close()" [disabled]="isSaving">
        X
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-4">
          <img [src]="modalData.logoUrl" class="logo-image" alt="NGO Logo">
        </div>
        <div class="col-md-8 d-flex flex-column align-items-left justify-content-center">
          <!-- <span class="modal-text-value color-gray-lighter">[ {{modalData.benevityCauseId}} ]</span> -->
          <span class="modal-text-title color-orange">{{modalData.name}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h5 class="modal-text-title color-gray mt-4">Description:</h5>
          <p class="modal-text-value color-gray">{{modalData.description}}</p>
        </div>
      </div>
    </div>    
    <!-- <div class="modal-footer mt-3">
      <button type="button" class="btn btn-gray" (click)="modal.close()" [disabled]="isSaving">
        Cancel
      </button>
    </div> -->
  </form>  
</ng-template>
<!-- Modal ends -->