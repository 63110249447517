import { Component, Input, OnInit } from '@angular/core';
import { LoggedInUserVM } from '@models/user.model';
import { StorageService } from '@services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GetCommentRequestModel, CommentVM, GetPostRequestModel, PostVM } from '../user-content.model';
import { UserContentService } from '../user-content.service';

@Component({
  selector: 'app-content-details-comment',
  templateUrl: './content-details-comment.component.html',
  styleUrls: ['./content-details-comment.component.scss']
})
export class ContentDetailsCommentComponent implements OnInit {

  @Input('contentReferenceId') commentId: number;  
  loggedInUser: LoggedInUserVM;  
  isLoading: boolean = false;

  comment: CommentVM = null;
  //post: PostVM = null;
  
  constructor(
    private service: UserContentService,
    private storageservice: StorageService,
    private toaster: ToastrService)
  { 
    this.loggedInUser = this.storageservice.retrieve('user');
  }

  ngOnInit(): void {
    this.getData(this.commentId);
  }

  getData(commentId: number){    
    this.clearData();

    const commentRequestModel: GetCommentRequestModel = {
      userId: this.loggedInUser?.id,
      isCorporatePage: false,
      corporatePageId: 0,
      commentId: commentId
    };

    this.isLoading = true;    

    this.service.getComment(commentRequestModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      this.comment = res?.response;

      // if(this.comment == null){
      //   this.post = null;
      //   this.isLoading = false;
      //   return;
      // }

      // this.getPost(this.comment.postId);

      this.isLoading = false;
    });
  }

  // getPost(postId: number) {
  //   const requestModel: GetPostRequestModel = {
  //     userId: this.loggedInUser?.id,
  //     isCorporatePage: false,
  //     corporatePageId: 0,
  //     postId: postId
  //   };

  //   this.service.getPost(requestModel)
  //   .pipe(catchError((err) => this.handleError(err)))
  //   .subscribe(res => {
  //     this.post = res?.response;

  //     if(this.post == null){
  //       this.comment = null;
  //     }
  //   });
  // }

  private handleError(error: any) {
    this.isLoading = false;
    this.clearData();
    this.toaster.error(error.error.message);
    return throwError(error);
  }

  private clearData(){
    //this.post = null;
    this.comment = null;
  }

}
