import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResponseModel } from '../../shared/models/response.model';
import { DataService } from '../../shared/services/data.service';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(private service: DataService) { }

  //get all with pagination
  public getAllUsers(data: any): Observable<ResponseModel> { 
    let url = `/identityapi/users/get`;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  public updateAdminUser(data:any): Observable<ResponseModel> { 
    let url = `/identityapi/users/admin/update`;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  public createAdminUser(data:any): Observable<ResponseModel> { 
    let url = `/identityapi/users/admin/create`;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  public deleteUser(data:any): Observable<ResponseModel> { 
    let url = `/identityapi/users/delete`;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
  
}
