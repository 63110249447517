<div class="container-fluid p-0 overflow-hidden">
  <div class="row">
    <div class="col-12">
      <div class="login-card p-3">
        <div class="logo">
          <img class="img-fluid for-light p-1" src="assets/images/logo/logo-lg.png" alt="login" width="360">
          <img class="img-fluid for-dark p-1" src="assets/images/logo/logo-lg.png" alt="login" width="360">
        </div>
        <div class="login-main py-4 px-5">
          <form class="theme-form needs-validation">

            <div class="form-group pt-2">
              <label class="col-form-label text-muted f-11 py-0">
                Username
              </label>
              <input class="form-control py-2 mb-3" type="email" name="email" [(ngModel)]="loginModel.username" required
                placeholder="sample@example.com">
            </div>
            <div class="form-group">
              <label class="col-form-label d-block text-muted f-11 py-0">
                Password
                <div class="password-showhide-control mt-0">
                  <input [type]="hidePassword ? 'password' : 'text'" name="password" class="form-control"
                    [(ngModel)]="loginModel.password" required>
                  <button class="btn-showHide"
                    (click)="changePasswordVisibility()">{{hidePassword?"show":"hide"}}</button>
                </div>
              </label>
            </div>
            <div class="form-group mb-0">
              <div class="mt-2">
                <label class="col-form-label text-muted d-flex align-items-center" for="checkbox1">
                  <input id="checkbox1" name="rememberMe" class="mr-1 bg-dark" [(ngModel)]="loginModel.rememberMe"
                    [checked]="loginModel.rememberMe" type="checkbox">
                  <span>Remember me</span>
                </label>
              </div>
              <!-- <a class="link" href="forget-password.html">
                    Forgot password?
                  </a> -->
              <div class="text-end mt-4">
                <button (click)="authenticate()" [disabled]="isLogging" class="btn btn-block btn-submit" type="submit">
                  {{ isLogging ? 'Authenticating...' : 'Sign in' }}
                </button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>