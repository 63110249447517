<div *ngIf="isLoading" class="data-status">Loading data...</div>
<div *ngIf="!isLoading && post == null" class="data-status">No content found.</div>
<div *ngIf="!isLoading && post != null">  
  <div class="reported-content">
    <div class="content-post">
      <div class="post-header">
        <div class="post-header-wrapper">
          <div class="image-thumbnail post-creator-profile-img">
            <img [src]="post.postCreatorProfilePictureThumbUrl" alt="">
          </div>
          <div class="username">{{post.postCreatorUsername}}</div>
          <div class="dot-menu">...</div>
        </div>
      </div>
      <div *ngIf="post.isCampaignPost" class="post-campaign-widget">
        <div class="campaign-widget-wrapper">
          <div class="campaign-details">
            <div class="campaign-name">{{post.campaignData.campaignName}}</div>
            <div class="campaign-progress">              
              <div class="campaign-progressbar">
                <ngb-progressbar 
                  type="warning" 
                  [value]="post.campaignData.campaignProgressPercentage | number:'1.0-0'" 
                  height="8px"
                  [animated]="true"
                  max="100">
                </ngb-progressbar>
              </div>
              <div class="campaign-status">
                <div class="campaign-completion-value">{{post.campaignData.campaignProgressPercentage | number:"1.0-0"}}% Completed</div>
                <div class="campaign-end-date">End : {{post.campaignData.campaignEndDate | date:"MMMM d"}}</div>
              </div>
            </div>
          </div>
          <div class="image-thumbnail campaign-activity-logo">
            <img [src]="post.campaignData.trackingMethodIconPictureThumbUrl" alt="">
          </div>
          <div class="right-arrow-icon">
            <svg _ngcontent-jsg-c183="" xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 15 20">
              <path _ngcontent-jsg-c183="" id="Arrow" d="M5.5,1,15,20H0Z" transform="translate(-11, 4) rotate(215)" fill="#ff4714"></path>
            </svg>
          </div>
          <div class="image-thumbnail campaign-ngo-logo">
            <img [src]="post.campaignData.foundationLogoPictureThumbUrl" alt="">
          </div>
        </div>
      </div>
      <div class="post-image">
        <img *ngIf="post.isLinkAttached" [src]="post.postLinkData.linkImageUrl" alt="" />
        <img *ngIf="!post.isLinkAttached" [src]="post.postImagePictureUrl" alt="" />
      </div>
      <div *ngIf="post.isLinkAttached" class="post-shared-link">
        {{post.postLinkData.postLinkUrl}}
      </div>
      <div class="post-description">
        <span class="username">{{post.postCreatorUsername}}</span>
        <span class="post-description-text">{{post.postDescription}}</span>
      </div>
      <div class="post-footer">
        <div class="post-footer-wrapper">
          <div class="post-footer-left">
            <div class="post-likes">            
              <img class="icon" src="../../../../assets/images/content-details/Icon_Heart.png" alt="" />
              <span class="count">{{post.postLikeCount}}</span>
            </div>
            <div class="post-comments">            
              <img class="icon" src="../../../../assets/images/content-details/Comment Icon-01.png" alt="" />
              <span class="count">{{post.postCommentCount}}</span>
            </div>
            <div class="post-view-campaign-btn">
              VIEW CAMPAIGN
            </div>
          </div>
          <div class="post-footer-right">
            <div class="post-timestamp">
              {{ post.postCreatedAt | date:"MM/dd/YY" }} at {{ post.postCreatedAt | date:"hh:mm:ss a" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <ng-content select=".content-comment"></ng-content>
  </div>
</div>