import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-data-with-image',
  templateUrl: './data-with-image.component.html',
  styleUrls: ['./data-with-image.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DataWithImageComponent implements OnInit {
  
  @Input('data') data: string;
  @Input('imageUrl') imageUrl: string;
  @Input('borderRadius') borderRadius: string = '6px';

  constructor() { }

  ngOnInit(): void {
  }

}
