import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReportsService } from '../reports.service';
import { ToastrService } from 'ngx-toastr';
import { BackersRM, BackersVM, CampaignsRM, CampaignsVM, FollowersRM, FollowersVM, FollowingsRM, FollowingsVM, KarmaCampaignsRM, KarmaCampaignsVM, KarmaDataRM, KarmaDataVM, NonProfitRM, NonProfitVM, ProfilePostsRM, ProfilePostsVM, ProfileReportByUserVM, ProfileRM, ProfileVM, TransactionDetailsVM, TransactionsRM, TransactionsVM } from '../reports.model';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserProfileComponent implements OnInit {  

  sub: any;
  userId: string = '';
  isCorporatePage: boolean = false;
  corporatePageId: number = 0;

  private profileReportByUserVM: ProfileReportByUserVM;
  private profileRM: ProfileRM;
  private campaignsRM: CampaignsRM;
  private profilePostsRM: ProfilePostsRM;
  private karmaDataRM: KarmaDataRM;
  private karmaCampaignsRM: KarmaCampaignsRM;
  private nonProfitRM: NonProfitRM;
  private backersRM: BackersRM;

  profileVM: ProfileVM;
  // campaignsVM: CampaignsVM[] = [];
  profilePostsVM: ProfilePostsVM[] = [];
  karmaDataVM: KarmaDataVM[] = [];
  karmaCampaignsVM: KarmaCampaignsVM[] = [];
  nonProfitVM: NonProfitVM[] = [];
  backersVM: BackersVM[] = [];

  followersVM: FollowersVM[] = [];
  followingsVM: FollowingsVM[] = [];
  transactionsVM: TransactionsVM[] = [];
  transactionDetailsVM: TransactionDetailsVM = null;  

  url: any;
  
  loadingIndicator: boolean = true;
  reorderable: boolean = true;

  

  constructor(private service: ReportsService,
    private activateRouter: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService) {
      this.sub = this.activateRouter.queryParams.subscribe(params => {
        this.userId = params['userId'];
        this.corporatePageId = params['corporatePageId'];
        this.isCorporatePage = this.corporatePageId > 0;
      });
  }


  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

  ngOnInit(): void {    
    
    //this.getProfileReportByUserId();
    this.getProfileDataById();
    //this.getAllActiveCampaignByUserId();
    this.getAllPostsByUserId();
    this.getKarmaDeatilsByUserId();
    this.getConnectedCampaignsByUserId();
    this.getNonProfitByUserId();
    //this.getFundRaisedByUserId();
    this.getBackersByUserId();

    this.getFollowers();
    this.getFollowings();
    this.getTransactions();
  }

  // Followers and Followings
  getFollowers() {
    const requestModel: FollowersRM = {
      userId: this.service.loggedInUserId,
      isCorporatePage: false,
      corporatePageId: 0,

      profileUserId: this.userId,
      isProfileCorporatePage: this.isCorporatePage,
      profileCorporatePageId: this.corporatePageId,

      sortColumn: '',
      sortDirection: 'DESC',
      pageNumber: 1,
      pageSize: 10,
      searchText: '',
      getAll: true
    };

    this.service.getFollowers(requestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        console.log('getFollowers : \n');
        console.log(res.response);
        this.followersVM = res.response;
      });
  }

  getFollowings() {
    const requestModel: FollowingsRM = {
      userId: this.service.loggedInUserId,
      isCorporatePage: false,
      corporatePageId: 0,

      profileUserId: this.userId,
      isProfileCorporatePage: this.isCorporatePage,
      profileCorporatePageId: this.corporatePageId,

      sortColumn: '',
      sortDirection: 'DESC',
      pageNumber: 1,
      pageSize: 10,
      searchText: '',
      getAll: true
    };

    this.service.getFollowings(requestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        console.log('getFollowings : \n');
        console.log(res.response);
        this.followingsVM = res.response;
      });
  }

  // Transactions
  getTransactions() {
    this.transactionDetailsVM = null;
    
    const requestModel: TransactionsRM = {
      userId: this.userId,
      isCorporatePage: this.isCorporatePage,
      corporatePageId: this.corporatePageId,

      sortColumn: '',
      sortDirection: 'DESC',
      pageNumber: 1,
      pageSize: 10,
      searchText: '',
      getAll: true
    };

    this.service.getTransactions(requestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        console.log('getTransactions : \n');
        console.log(res.response);
        this.transactionsVM = res.response;
      });
  }

  getTransactionDetails(transactionId: number) {
    if(transactionId > 0){
      this.service.getTransactionDetails(transactionId)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        console.log('getTransactionDetails : \n');
        console.log(res.response);
        this.transactionDetailsVM = res.response;
      });
    }
    else{
      this.transactionDetailsVM = null;
    }    
  }

  getProfileReportByUserId() {
    this.service.getProfileReportByUserId(this.userId)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        console.log('getProfileReportByUserId : \n');
        console.log(res.response);
        this.profileReportByUserVM = res.response;
      });
  }

  getProfileDataById() {
    this.profileRM= new ProfileRM();
    this.profileRM.userId = this.userId;
    this.profileRM.profileUserId = this.userId;
    this.profileRM.getAll = true;

    this.service.getProfileDataById(this.profileRM)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        console.log('getProfileDataById : \n');
        console.log(res.response);
        this.profileVM = res.response;
//done
      });
  }

  // getAllActiveCampaignByUserId() {
  //   this.campaignsRM= new CampaignsRM();
  //   this.campaignsRM.userId = this.userId;
  //   this.campaignsRM.getAll = true;

  //   this.service.getAllActiveCampaignByUserId(this.campaignsRM)
  //     .pipe(catchError((err) => this.handleError(err)))
  //     .subscribe(res => {
  //       console.log('getAllActiveCampaignByUserId : ' + res.response);
  //       this.campaignsVM = res.response;
  //     });
  // }

  getAllPostsByUserId() {
    this.profilePostsRM= new ProfilePostsRM();
    this.profilePostsRM.userId = this.userId;
    this.profilePostsRM.profileUserId = this.userId;
    this.profilePostsRM.isUserPostRequired = true;
    this.profilePostsRM.getAll = true;

    this.service.getAllPostsByUserId(this.profilePostsRM)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        console.log('getAllPostsByUserId : \n');
        console.log(res.response);
//done
        this.profilePostsVM = res.response;
      });
  }

  getKarmaDeatilsByUserId() {
    this.karmaDataRM= new KarmaDataRM();
    this.karmaDataRM.userId = this.userId;
    this.karmaDataRM.profileUserId = this.userId;
    this.karmaDataRM.getAll = true;

    this.service.getKarmaDeatilsByUserId(this.karmaDataRM)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        console.log('getKarmaDeatilsByUserId : \n');
        console.log(res.response);
        this.karmaDataVM = res.response;
//done
      });
  }

  getConnectedCampaignsByUserId() {
    this.karmaCampaignsRM= new KarmaCampaignsRM();
    this.karmaCampaignsRM.userId = this.userId;
    this.karmaCampaignsRM.isCorporatePage = false;
    this.karmaCampaignsRM.corporatePageId = 0;
    this.karmaCampaignsRM.campaignFilterType = 'All';
    this.karmaCampaignsRM.getAll = true;
    this.karmaCampaignsRM.isRaisedAmountDataRequired = false;

    this.service.getConnectedCampaignsByUserId(this.karmaCampaignsRM)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        console.log('getConnectedCampaignsByUserId : \n');
        console.log(res.response);
        this.karmaCampaignsVM = res.response;
//done
      });
  }

  getNonProfitByUserId() {
    this.nonProfitRM= new NonProfitRM();
    this.nonProfitRM.userId = this.userId;
    this.nonProfitRM.getAll = true;

    this.service.getNonProfitByUserId(this.nonProfitRM)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.nonProfitVM = res.response;
        console.log('getNonProfitByUserId : \n');
        console.log(res.response);
//done
      });
  }

//   getFundRaisedByUserId() {
//     this.karmaCampaignsRM= new KarmaCampaignsRM();
//     this.karmaCampaignsRM.userId = this.userId;
//     this.karmaCampaignsRM.getAll = true;
//     this.karmaCampaignsRM.isRaisedAmountDataRequired = true;

//     this.service.getFundRaisedByUserId(this.karmaCampaignsRM)
//       .pipe(catchError((err) => this.handleError(err)))
//       .subscribe(res => {
//         this.karmaCampaignsVM = res.response;
//         console.log('getFundRaisedByUserId : \n');
//         console.log(res.response);
// //done
//       });
//   }

  getBackersByUserId() {
    this.backersRM= new BackersRM();
    this.backersRM.userId = this.userId;
    this.backersRM.profileUserId = this.userId;
    this.backersRM.getAll = true;

    this.service.getBackersByUserId(this.backersRM)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        console.log('getBackersByUserId : \n');
        console.log(res.response);
        this.backersVM = res.response;
//done
      });
  }

  private handleError(error: any) {
    this.toaster.error(error?.error?.message);
    return throwError(error);
  }
  
}
