import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public  screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);
	
	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;
	
	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if(evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if(window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => { 
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}
	
	MENUITEMS: Menu[] = [
		// {
		// 	title: 'Dashboard', path: '/dashboard', icon: 'dashboard', type: 'link'
		// },
		// {
		// 	title: 'User Management', path: '/user-management/user-list', icon: 'users', type: 'link', active: true
		// },
		{
			title: 'User Management', icon: 'users', type: 'sub', children: [
				{ title: 'User List (Individual)', path: '/user-management/user-list', type: 'link' },
				{ title: 'Business Users', path: 'business/list', type: 'link' },
				// { title: 'NGO List', path: 'non-profit/list', type: 'link' },
				{
					title: 'Non-Profit', type: 'sub', children: [						
						{ title: 'Non-Profit Search', path: '/non-profit/list', type: 'link' }
					]
				},
				{ title: 'Admin Users', path: '/admin-users', type: 'link' },
			]
		},
		{
			title: 'Masters', icon: 'masters', type: 'sub', children: [
				{ title: 'Tracking Apps', path: '/masters/tracking-apps', type: 'link' },
				{ title: 'Tracking Methods', path: '/masters/tracking-methods', type: 'link' },
			]
		},
		// {
		// 	title: 'Non-profits', icon: 'non-profits', type: 'sub', children: [
		// 		{ title: 'Create NGO', path: '/non-profits/create', type: 'link' },
		// 		{ title: 'All NGO', path: '/non-profits/list', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Business', path: 'business/list', icon: 'business', type: 'link'
		// },
		{
			title: 'Reports', icon: 'reports', type: 'sub', children: [
				{ title: 'Campaigns', path: '/reports/campaigns', type: 'link' },
				{ title: 'Donations', path: '/reports/donations', type: 'link' },
				{ title: 'Payouts History', path:'/reports/payouts-history', type:'link'},
			]
		},
		// {
		// title: 'Settings', path:'/settings', icon: 'settings', type: 'link'
		// },
		{
			title: 'User Content', path:'/user-content', icon: 'user-content', type: 'link'
		},
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	megaItems = new BehaviorSubject<Menu[]>([]);
	levelmenuitems = new BehaviorSubject<Menu[]>([]);
}
