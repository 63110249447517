import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'populateEnum'
})
export class PopulateEnumPipe implements PipeTransform {

  // transform(enumObj: Object): any {
  //   const keys = Object.keys(enumObj);
  //   return keys.slice(0,keys.length / 2).map(k => ({key: k, value: enumObj[k as any]}));
  // }
  
  transform(enumObj: Object) : any {
    return Object.keys(enumObj).filter(e => !isNaN(+e)).map(o => { return {key: +o, value: enumObj[o]}});
  }

}
