<!-- content starts-->
<div class="content-wrapper">
  <div class="">
    <div class="row d-flex align-items-start p-t-20">
      <div class="col-sm-12 col-xl-12 h-100">
        <div class="card shadow">
          <div class="p-3">
            <p>This is dashboard.</p>
          </div>
        </div>
      </div>      
    </div>
  </div>
</div>
<!-- content Ends-->