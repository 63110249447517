import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export interface TableData{
  head?: any[];
	body?: any[];
}

@Injectable({
    providedIn: 'root',
})

export class ExportDataService {
  // private exportToCsv(filename: string, rows: object[]) {
  //   if (!rows || !rows.length) {
  //     return;
  //   }
  //   const separator = ',';
  //   const keys = Object.keys(rows[0]);
  //   const csvContent =
  //     keys.join(separator) +
  //     '\n' +
  //     rows.map(row => {
  //       return keys.map(k => {
  //         let cell = row[k] === null || row[k] === undefined ? '' : row[k];
  //         cell = cell instanceof Date
  //           ? cell.toLocaleString()
  //           : cell.toString().replace(/"/g, '""');
  //         if (cell.search(/("|,|\n)/g) >= 0) {
  //           cell = `"${cell}"`;
  //         }
  //         return cell;
  //       }).join(separator);
  //     }).join('\n');

  //   const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  //   if (navigator.msSaveBlob) { // IE 10+
  //     navigator.msSaveBlob(blob, filename);
  //   } else {
  //     const link = document.createElement('a');
  //     if (link.download !== undefined) {
  //       // Browsers that support HTML5 download attribute
  //       const url = URL.createObjectURL(blob);
  //       link.setAttribute('href', url);
  //       link.setAttribute('download', filename);
  //       link.style.visibility = 'hidden';
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //   }
  // }

  private downloadAsCSV(title: string, data: TableData | any, dataType: 'table' | 'detail') {
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data.body[0]);
    const csv = data.body.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
  
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
  
    a.href = url;
    a.download = title + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  public downLoadFile(filename: string, data: TableData | any, dataType: 'table' | 'detail', downloadType: 'pdf' | 'csv') {
    // set title, if null
    if (!filename) filename = dataType + "_report";

    // set report file name
    let currentDate = new Date().toJSON().slice(0, 10);
    const customFilename = filename + "_" + currentDate;

    if (downloadType === 'csv') {
      this.downloadAsCSV(customFilename, data, dataType);
    }
    else if (downloadType === 'pdf') {
      if(dataType == 'table'){
        this.downloadAsPDF(customFilename, data);
      }
      else if(dataType == 'detail'){
        this.downloadHTMLAsPDF(customFilename, data);
      }
    }
    else {
      console.log('download format not supported');
    }
  }

  private downloadAsPDF(filename: string, data: TableData) {
    const doc = new jsPDF('l', 'mm', 'a4');
    autoTable(doc, {
      head: [data.head],
      body: data.body,
      //foot: [[filename]]
    });
    doc.save(filename + '.pdf');
  }

  private downloadHTMLAsPDF(filename: string, htmlContainer: HTMLElement) {
    const doc = new jsPDF('l', 'px', 'a4');
    // doc.internal.pageSize.width = 1200;
    // doc.internal.pageSize.height = 500;

    doc.html(htmlContainer, {
      callback: function (doc) {
        doc.save(filename + '.pdf');
      },
      width: 500,
      windowWidth: 800,      
      // image:{quality: 1, type:'jpeg'},
      // html2canvas: {width: 1500,backgroundColor: '#fff', letterRendering: true},
      x: 20,
      y: 20,
    });

    // htmlToImage.toJpeg(htmlContainer, { type: 'application/octet-stream' , backgroundColor: 'white', quality: 1 })
    //   .then(function (dataUrl) {
    //     // const url = window.URL
    //     //      .createObjectURL(dataUrl);
    //     // const link = document.createElement('a');
    //     // link.href = url;
    //     // link.setAttribute('download', filename + '.pdf');
    //     // document.body.appendChild(link);
    //     // link.click();

    //     // var downloadLink = document.createElement('a');
    //     //     downloadLink.target = '_blank';
    //     //     downloadLink.download = filename + '.pdf';

    //     //     // convert downloaded data to a Blob
    //     //     //var blob = new Blob([dataUrl], { type: 'application/octet-stream' });
    //     //    dataUrl.jp((b)=>{
    //     //         // create an object URL from the Blob
    //     //         var URL = window.URL || window.webkitURL;
    //     //         var downloadUrl = URL.createObjectURL(b);

    //     //         // set object URL as the anchor's href
    //     //         downloadLink.href = downloadUrl;

    //     //         // append the anchor to document body
    //     //         document.body.append(downloadLink);

    //     //         // fire a click event on the anchor
    //     //         downloadLink.click();
    //     //         // downloadLink.remove();
    //     //         URL.revokeObjectURL(downloadUrl);
    //     //     }, "application/pdf", 1);
            

    //     // var img = new Image();
    //     // img.src = dataUrl;
    //     //htmlContainer.appendChild(img);

    //     // doc.html(img, {
    //     //   callback: function (doc) {
    //     //     doc.save(filename + '.pdf');
    //     //   },
    //     //   x: 10,
    //     //   y: 10
    //     // });

    //     // var img = new Image();
    //     // img.src = dataUrl;
    //     const doc = new jsPDF('l', 'mm', 'a4');
    //     doc.addImage(dataUrl,'jpeg',10,10,270,70).save(filename + '.pdf');
    //   });
  }
}