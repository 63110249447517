import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ExportDataService, TableData } from '@services/export-data.service'

@Component({
  selector: 'app-download-menu',
  templateUrl: './download-menu.component.html',
  styleUrls: ['./download-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DownloadMenuComponent implements OnInit {

  @Input() title: string;
  @Input() data: TableData | any;
  @Input() datatype: 'table'|'detail' = 'table';

  constructor(private exportService : ExportDataService) { }

  ngOnInit(): void {
  }

  downloadAsCSV(){
    this.exportService.downLoadFile(this.title, this.data, this.datatype, 'csv');
  }

  downloadAsPDF(){
    this.exportService.downLoadFile(this.title, this.data, this.datatype, 'pdf');
  }

}
