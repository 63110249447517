<!-- content starts-->
<div class="content-wrapper">
  <app-breadcrumb [items]="['Reports']" [active_item]="'Donations'"></app-breadcrumb>
  <div class="card p-0 mb-0 pb-3">
    <div class="card-body px-0 pt-2 pb-0">
      <div class="ngxdt business-list">
        <div class="ngxdt-header justify-content-start column-reverse">
          <div class="ngxdt-header">
            <div class="ngxdt-select">
              <div class="date-range">
                <span class="date-range-label">Start Date:</span>
                <div class="date-range-picker">
                  <app-date-range-picker (selectedDates)="onStartDatesChange($event)"></app-date-range-picker>
                </div>
              </div>
            </div>
            <div class="ngxdt-select">
              <div class="date-range">
                <span class="date-range-label">End Date:</span>
                <div class="date-range-picker">
                  <app-date-range-picker (selectedDates)="onEndDatesChange($event)"></app-date-range-picker>
                </div>
              </div>
            </div>
          </div>

          <div class="ngxdt-search">
            <div class="ngxdt-search-wrapper">
              <i class="fa fa-search"></i>
              <input type="text" (focus)="ngxDatatableSSRConfig.showSearchButton(true)"
                (keydown)="($event.key === 'Enter')?search():true"
                (blur)="ngxDatatableSSRConfig.showSearchButton(false)" placeholder="Search"
                [(ngModel)]="ngxDatatableSSRConfig.searchText">
              <div class="btn-search">
                <button *ngIf="ngxDatatableSSRConfig.searchBtnVisible" (click)="search()">
                  <i class="fa fa-filter"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="ngxdt-header justify-content-start mt-3">
          <div class="ngxdt-select">
            <label for="campaignStatusFilter">Campaign Status:</label>
            <select id="campaignStatusFilter" style="border: 1px solid;" name="campaignStatusFilter" (change)="search()"
              [(ngModel)]="filterModel.campaignStatusFilter">
              <option class="text-muted" value="All">All</option>
              <option class="text-muted" value="CampaignOngoing">Campaign Ongoing</option>
              <option class="text-muted" value="PayoutPending">Payout Pending</option>
              <option class="text-muted" value="PayoutComplete">Payout Complete</option>
            </select>
          </div>
          <div class="ngxdt-buttons">
            <button class="btn btn-export" (click)="downloadAsPDF()">Export as PDF</button>
          </div>
        </div>

        <div class="ngxdt-body mt-4">
          <ngx-datatable #table class="bootstrap report fix-width" 
            [messages]="{
                emptyMessage: ngxDatatableSSRConfig.isLoading ? ngxDatatableSSRConfig.loadingMessage : ngxDatatableSSRConfig.emptyMessage | translate,
                totalMessage: ngxDatatableSSRConfig.totalMessage | translate,
                selectedMessage: false
              }" 
            [footerHeight]="ngxDatatableSSRConfig.footerHeight" 
            [limit]="ngxDatatableSSRConfig.pageSize"
            [rows]="rows" 
            [columnMode]="ngxDatatableSSRConfig.columnMode.force"
            [offset]="ngxDatatableSSRConfig.tableOffset" 
            [loadingIndicator]="false"
            [selectionType]="ngxDatatableSSRConfig.selectionType.single" 
            [selected]="selected" 
            [scrollbarH]="true"
            [scrollbarV]="true"
            [virtualization]="false">

            <ngx-datatable-column [width]="50" headerClass="make-center" cellClass="make-center" prop="id" name="#"
              [sortable]="false" [draggable]="false">
              <ng-template let-rowIndex="rowIndex + 1" let-value="value" ngx-datatable-cell-template>
                {{(ngxDatatableSSRConfig.pageSize * (ngxDatatableSSRConfig.pageNumber - 1)) + rowIndex}}.
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="150" prop="campaignName" name="Campaign Name" [sortable]="false"
              [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span title="{{ value }}">
                  {{ value }}
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="150" prop="foundationName" name="NGO Name" [sortable]="false"
              [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span title="{{ value }}">
                  {{ value }}
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="130" prop="campaignEndDate" name="Campaign End Date" [sortable]="false"
              [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date:'MM-dd-yyyy' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="120" prop="campaignTotalRaisedAmount" name="Total Donations"
              [sortable]="false" [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | number:'2.2-2'}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="80" prop="totalPlatformFeeAmount" name="Knobl Fee" [sortable]="false"
              [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | number:'2.2-2' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="100" prop="totalPaymentGatewayFeeAmount" name="Authorize Fee"
              [sortable]="false" [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | number:'2.2-2' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="100" prop="totalDonationAmount" name="NGO Amount" [sortable]="false"
              [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | number:'2.2-2' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="120" prop="campaignStatus" name="Status" [sortable]="false"
              [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ getPayoutStatus(row) }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="100" [maxWidth]="150" name="Action" headerClass="make-center"
              cellClass="make-center" [sortable]="false" [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <button type="button" class="btn btn-action-gray-filled ml-1" (click)="openModal(modalId, row)">
                  <i class="fa fa-eye"></i> Details
                </button>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <div>
                  <div class="page-size-control">
                    Show
                    <select class="page-size-dropdown" [(ngModel)]="ngxDatatableSSRConfig.pageSize"
                      (ngModelChange)="onPageSizeChanged($event)">
                      <option *ngFor="let opt of ngxDatatableSSRConfig.limitOptions" [ngValue]="opt.value">{{opt.key}}
                      </option>
                    </select>
                    entries
                  </div>
                  <div class="pagination-control">
                    <ngb-pagination #ngbPage [boundaryLinks]="false"
                      [collectionSize]="ngxDatatableSSRConfig.totalRecords" [pageSize]="ngxDatatableSSRConfig.pageSize"
                      [page]="ngxDatatableSSRConfig.pageNumber" [maxSize]="5" (pageChange)="onPageChanged($event)">
                    </ngb-pagination>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- content Ends-->

<!-- Modal starts -->
<ng-template #modalId let-modal>
  <div class="modal-header">
    <span class="modal-title mt-2">Payout Details</span>
    <button type="button" class="btn btn-close" (click)="modal.close()">X</button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header px-3 py-2">Payout Data</div>
          <div class="card-body px-3 py-2">
            <table class="table">
              <!-- <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First</th>
                  <th scope="col">Last</th>
                  <th scope="col">Handle</th>
                </tr>
              </thead> -->
              <tbody>
                <tr>
                  <th scope="row">Campaign Name</th>
                  <td>{{modalData.campaignName}}</td>
                </tr>
                <tr>
                  <th scope="row">Non-Profit Name</th>
                  <td>{{modalData.foundationName}}</td>
                </tr>
                <tr>
                  <th scope="row">Transaction Id</th>
                  <td>{{modalData?.payoutDetails?.data.transactionId}}</td>
                </tr>
                <tr>
                  <th scope="row">Transaction Current Status</th>
                  <td>{{modalData?.payoutDetails?.data.currentTransactionStatus}}</td>
                </tr>
                <tr>
                  <th scope="row">Transaction Date</th>
                  <td>
                    {{modalData?.payoutDetails?.data.transactionCreatedAt}}
                    <!-- {{ modalData?.payoutDetails?.data.transactionCreatedAt ? (modalData?.payoutDetails?.data.transactionCreatedAt + "z"| date : "MMM dd, yyyy HH:mm:ss") : "NA" }} -->
                  </td>
                </tr>
                <tr>
                  <th scope="row">Transaction Status Last Updated Date</th>
                  <td>{{modalData?.payoutDetails?.data.transactionStatusLastUpdatedAt}}</td>
                </tr>
                <tr>
                  <th scope="row">Currency Code</th>
                  <td>{{modalData?.payoutDetails?.data.currencyCode}}</td>
                </tr>
                <tr>
                  <th scope="row">Transaction Amount</th>
                  <td>{{modalData?.payoutDetails?.data.transactionAmount}}</td>
                </tr>
                <tr>
                  <th scope="row">Campaign Backers</th>
                  <td>{{modalData?.payoutDetails?.data.backersCount}}</td>
                </tr>
                <tr>
                  <th scope="row">Total Pledged Amount</th>
                  <td>{{modalData?.payoutDetails?.data.totalPledgedAmount}}</td>
                </tr>
                <tr>
                  <th scope="row">Donors Backers</th>
                  <td>{{modalData?.payoutDetails?.data.donorsCount}}</td>
                </tr>
                <tr>
                  <th scope="row">Total Received Amount</th>
                  <td>{{modalData?.payoutDetails?.data.totalReceivedAmount}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header px-3 py-2">Payout Status History</div>
          <div class="card-body px-3 py-2">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Status</th>
                  <th scope="col">Last Updated Date</th>
                  <th scope="col">Remark</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of modalData?.payoutDetails?.history">
                  <th scope="row">{{item.payoutTransactionStatus}}</th>
                  <td>{{item.lastUpdatedAt}}</td>
                  <td><i *ngIf="item.remark" class="fa fa-info-circle" aria-hidden="true" triggers="hover"
                      [autoClose]="true" [ngbTooltip]="item.remark" animation="true" container="body"
                      placement="top"></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <!-- <button type="button" class="btn btn-orange" (click)="updatePayoutStatus(modalData)" [disabled]="isUpdating">
      <span>{{ isUpdating ? "Updating..." : "Yes" }}</span>
    </button>
    <button type="button" class="btn btn-gray ml-4" (click)="modal.close()" [disabled]="isUpdating">
      <span>No</span>
    </button> -->
  </div>
</ng-template>
<!-- Modal ends -->