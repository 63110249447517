import { PaginationBaseModel } from "@models/pagination.model";

export class BusinessVM {
  id: number;
  creatorUserId: string;
  creatorUserFullName: string;
  creatorUserUsername: string;
  creatorUserProfilePictureUrl: string;
  creatorUserProfilePictureThumbUrl: string;
  name: string;
  username: string;
  email: string;
  website: string;
  coverPictureUrl: string;
  coverPictureThumbUrl: string;
  profilePictureUrl: string;
  profilePictureThumbUrl: string;
  applicationDate: string;
  statusId: number;
  status: string;
  totalRecords: number;
}

export class GetBusinessesRequestModel extends PaginationBaseModel{
  isWebRequest: boolean = true;
}

export class UpdateStatusRequestModel{
  businessUserId: number;
  action: string;
}

export enum BusinessAccountStatus {
  Approve = 'Approve',
  Decline = 'Decline',
}