import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResponseModel } from '../../shared/models/response.model';
import { DataService } from '../../shared/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class UserContentService {

  constructor(private service: DataService) { }

  //get all reported-contents with pagination
  public getAllReportedContents(data: any): Observable<ResponseModel> { 
    let url = `/campaignapi/content/getallreportedcontents`;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  //get all content-reporters with pagination
  public getAllContentReporters(data: any): Observable<ResponseModel> { 
    let url = `/campaignapi/content/reporters`;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  //save review action for the reported content
  public reviewContent(data: any): Observable<ResponseModel> { 
    let url = `/campaignapi/content/review`;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  //get reported campaign details
  public getCampaign(data: any): Observable<ResponseModel> { 
    let url = `/campaignapi/campaign/get`;
    return this.service.post(url,data,true,null,false).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  //get reported campaign details
  public getPost(data: any): Observable<ResponseModel> { 
    let url = `/campaignapi/post/getById`;
    return this.service.post(url,data,true,null,false).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  //get reported campaign details
  public getComment(data: any): Observable<ResponseModel> { 
    let url = `/campaignapi/post/comment/getCommentById`;
    return this.service.post(url,data,true,null,false).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
}
