
<div *ngIf="isLoading" class="data-status">Loading data...</div>
<div *ngIf="!isLoading && comment == null" class="data-status">No content found.</div>
<app-content-details-post *ngIf="!isLoading && comment != null" [contentReferenceId]="comment.postId">
  <div class="content-comment">
    <div class="content-comment-wrapper">
      <div class="image-thumbnail comment-creator-profile-img">
        <img [src]="comment.commentCreatorProfilePictureThumbUrl" alt="">
      </div>
      <div class="comment-details">
        <div class="comment-description">
          <span class="username">{{comment.commentCreatorUsername}}</span>
          <span class="comment-text">{{comment.commentText}}</span>
        </div>
        <div class="comment-timestamp">
          {{ comment.commentCreatedAt | date:"MM/dd/YY" }} at {{ comment.commentCreatedAt | date:"hh:mm:ss a" }}
        </div>
      </div>
    </div>
  </div>  
</app-content-details-post>