<div class="range-datepicker">
	<div class="input-group">
		<input name="datepicker" class="input-calendar" placeholder="mm/dd/yyyy - mm/dd/yyyy" ngbDatepicker
			[value]="inputDate" #datepicker="ngbDatepicker" [autoClose]="'outside'"
			(dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="dayTemplate"
			[footerTemplate]="footerTemplate" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1"
			readonly="true" />
		<button class="btn-calendar" (click)="datepicker.toggle()" type="button">
			<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25"
				viewBox="0 0 25 25">
				<defs>
					<clipPath id="clip-path">
						<rect id="Rectangle_199" data-name="Rectangle 199" width="25" height="25"
							transform="translate(498.848 99)" fill="#707070" stroke="#707070" stroke-width="1" />
					</clipPath>
				</defs>
				<g id="calender_icon" data-name="calender icon" transform="translate(-498.848 -99)"
					clip-path="url(#clip-path)">
					<g id="calendar_1_" data-name="calendar (1)" transform="translate(498.848 99)">
						<g id="Group_671" data-name="Group 671">
							<g id="Group_670" data-name="Group 670">
								<circle id="Ellipse_1" data-name="Ellipse 1" cx="0.977" cy="0.977" r="0.977"
									transform="translate(17.871 9.277)" fill="#707070" />
								<path id="Path_246" data-name="Path 246"
									d="M21.094,1.953h-1.27V.977a.977.977,0,0,0-1.953,0v.977H13.428V.977a.977.977,0,0,0-1.953,0v.977H7.08V.977a.977.977,0,0,0-1.953,0v.977H3.906A3.911,3.911,0,0,0,0,5.859V21.094A3.911,3.911,0,0,0,3.906,25h7.471a.977.977,0,0,0,0-1.953H3.906a1.955,1.955,0,0,1-1.953-1.953V5.859A1.955,1.955,0,0,1,3.906,3.906H5.127v.977a.977.977,0,0,0,1.953,0V3.906h4.395v.977a.977.977,0,0,0,1.953,0V3.906h4.443v.977a.977.977,0,0,0,1.953,0V3.906h1.27a1.955,1.955,0,0,1,1.953,1.953v5.566a.977.977,0,0,0,1.953,0V5.859A3.911,3.911,0,0,0,21.094,1.953Z"
									fill="#707070" />
								<path id="Path_247" data-name="Path 247"
									d="M19.092,13.184A5.908,5.908,0,1,0,25,19.092,5.915,5.915,0,0,0,19.092,13.184Zm0,9.863a3.955,3.955,0,1,1,3.955-3.955A3.96,3.96,0,0,1,19.092,23.047Z"
									fill="#707070" />
								<path id="Path_248" data-name="Path 248"
									d="M20.508,18.115h-.439V17.09a.977.977,0,0,0-1.953,0v2a.977.977,0,0,0,.977.977h1.416a.977.977,0,0,0,0-1.953Z"
									fill="#707070" />
								<circle id="Ellipse_2" data-name="Ellipse 2" cx="0.977" cy="0.977" r="0.977"
									transform="translate(13.623 9.277)" fill="#707070" />
								<circle id="Ellipse_3" data-name="Ellipse 3" cx="0.977" cy="0.977" r="0.977"
									transform="translate(9.375 13.525)" fill="#707070" />
								<circle id="Ellipse_4" data-name="Ellipse 4" cx="0.977" cy="0.977" r="0.977"
									transform="translate(5.127 9.277)" fill="#707070" />
								<circle id="Ellipse_5" data-name="Ellipse 5" cx="0.977" cy="0.977" r="0.977"
									transform="translate(5.127 13.525)" fill="#707070" />
								<circle id="Ellipse_6" data-name="Ellipse 6" cx="0.977" cy="0.977" r="0.977"
									transform="translate(5.127 17.773)" fill="#707070" />
								<circle id="Ellipse_7" data-name="Ellipse 7" cx="0.977" cy="0.977" r="0.977"
									transform="translate(9.375 17.773)" fill="#707070" />
								<circle id="Ellipse_8" data-name="Ellipse 8" cx="0.977" cy="0.977" r="0.977"
									transform="translate(9.375 9.277)" fill="#707070" />
							</g>
						</g>
					</g>
				</g>
			</svg>
		</button>
	</div>
</div>

<!-- Templates -->
<ng-template #dayTemplate let-date let-focused="focused">
	<span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
		[class.start-date]="isStartDate(date)" [class.end-date]="isEndDate(date)"
		[class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
		(mouseleave)="hoveredDate = null">
		{{ date.day }}
	</span>
</ng-template>
<ng-template #footerTemplate>
	<hr class="my-0">
	<div class="action-buttons">
		<button class="btn btn-sm btn-gray" (click)="onClear()">Clear</button>
		<button class="btn btn-sm btn-orange" (click)="onApply()" [disabled]="fromDate && !toDate">Apply</button>
	</div>
</ng-template>
<!-- end Templates -->