import { PaginationBaseModel } from "@models/pagination.model";

export class NonProfits {
}

export class SearchFoundationByAdminPgnQuery extends PaginationBaseModel {
    foundationSearchTypeFilter: string;
    foundationCategoryCode: string;
}

export interface SearchFoundationByAdminPgnQueryResponse {
  benevityCauseId: string;
  name: string;
  description: string;
  logoUrl: string;
  totalRecords: number;
}
export class NonProfitVM {
  benevityCauseId: string;
  name: string;
  description: string;
  logoUrl: string;
  totalRecords: number;
}

export enum FoundationStatus {
  Pending = 1,
  Submitted = 2,
  UnderReview = 3,
  Approved = 4,
  Declined = 5,
  Blocked = 6,
  Suspended = 7
}

export class UpdateFoundationStatusRequestModel {
  userId: string;
  foundationId: number;
  newStatusId: number;
  updatedByUserId: string;
}

export enum FoundationSearchTypeEnum {
  "All" = "All",
  "TrendingBenevity" = "Trending - Benevity",
  "TrendingKnobl" = "Trending - Knobl"
}

export enum FoundationCategoryCodeEnum {
  A = "Arts, Culture & Humanities",
  B = "Education",
  C = "Environment",
  D = "Animal-Related",
  E = "Health Care",
  F = "Mental Health & Crisis Intervention",
  G = "Voluntary Health Associations & Medical Disciplines",
  H = "Medical Research",
  I = "Crime & Legal-Related",
  J = "Employment",
  K = "Food, Agriculture & Nutrition",
  L = "Housing & Shelter",
  M = "Public Safety, Disaster Preparedness & Relief",
  N = "Recreation & Sports",
  O = "Youth Development",
  P = "Human Services",
  Q = "International, Foreign Affairs & National Security",
  R = "Civil Rights, Social Action & Advocacy",
  S = "Community Improvement & Capacity Building",
  T = "Philanthropy, Voluntarism & Grantmaking Foundations",
  U = "Science & Technology",
  V = "Social Science",
  W = "Public & Societal Benefit",
  X = "Religion-Related",
  Y = "Mutual & Membership Benefit",
  Z = "Unknown"
}