import { Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { LoggedInUserVM } from '@models/user.model';
import { StorageService } from '@services/storage.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GetCampaignRequestModel, CampaignVM } from '../user-content.model';
import { UserContentService } from '../user-content.service';

@Component({
  selector: 'app-content-details-campaign',
  templateUrl: './content-details-campaign.component.html',
  styleUrls: ['./content-details-campaign.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentDetailsCampaignComponent implements OnInit {

  @Input('contentReferenceId') campaignId: number;

  loggedInUser: LoggedInUserVM;
  campaign: CampaignVM = null;
  isLoading: boolean = false;   

  constructor(
    private service: UserContentService,
    private storageservice: StorageService)
  { 
    this.loggedInUser = this.storageservice.retrieve('user');
  }

  ngOnInit(): void {
    this.getData(this.campaignId);
  }

  getData(campaignId: number){
    this.clearData();

    const requestModel: GetCampaignRequestModel = {
      userId: this.loggedInUser?.id,
      isCorporatePage: false,
      corporatePageId: 0,
      campaignId: campaignId
    };

    this.isLoading = true;

    this.service.getCampaign(requestModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      this.campaign = res?.response;
      this.isLoading = false;
    });
  }

  private handleError(error: any) {
    this.isLoading = false;
    this.clearData();
    return throwError(error);
  }

  private clearData(){
    this.campaign = null;
  }

  adjustSize(data){
    if(!data || data == "") return 'inherit';

    var dataLength = data.toString().length;

    const size = this.getSize(dataLength);
    return {
      'font-size': size,
      'line-height': size
    }    
  }

  getSize(dataLength: number){
    if(dataLength <= 6){
      return '30px';
    }
    else if(dataLength <= 8){
      return '20px';
    }
    else if(dataLength <= 10){
      return '16px';
    }
    else if(dataLength <= 12){
      return '14px';
    }
    else if(dataLength <= 14){
      return '12px';
    }
    else if(dataLength <= 16){
      return '10px';
    }
    else{
      return '8px';
    }
  }
}