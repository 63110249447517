import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoginModel } from './auth.model';
import { DataService } from '@services/data.service';
import { ResponseModel } from '@models/response.model';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  isLoggedIn:boolean = false;
  
  constructor(private service: DataService) {
  }

  // sign in function
  signIn(data: LoginModel): Observable<ResponseModel> {
    let url = '/identityapi/account/admin/login';
    return this.service.post(url, data, false).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
}