import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggedInUserVM } from '@models/user.model';
import { StorageService } from '@services/storage.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ResponseModel } from '../../shared/models/response.model';
import { DataService } from '../../shared/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  public loggedInUserId: string;

  constructor(
    private service: DataService,
    private storage: StorageService,
    private router: Router,
  ) { 
    this.loggedInUserId = this.storage.retrieve('user')?.id;
  }

  //get all payouts checklist with pagination
  public getPayoutsReportData(data: any): Observable<ResponseModel> { 
    let url = `/campaignapi/report/payouts/getall`;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  //update payout status
  public updatePayoutStatus(data: any): Observable<ResponseModel> { 
    let url = `/campaignapi/report/payouts/updatestatus`;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }


  //get all campaigns with pagination
  public getAllCampaignsReportData(data: any): Observable<ResponseModel> { 
    let url = `/campaignapi/report/campaigns/getall`;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  public getBackersReportData(data: any): Observable<ResponseModel> { 
    let url = `/campaignapi/campaign/getbackersprogress`;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  public getCampaignsByCreatorReportData(data: any): Observable<ResponseModel> { 
    let url = `/campaignapi/profile/getConnectedCampaigns`;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
  
//#region User Profile Details

public getProfileReportByUserId(userId: string): Observable<ResponseModel> { 
  let url = `/campaignapi/Report/GetProfileReport/`+userId;
  return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
    return response;
  }));
}

public getProfileDataById(data: any): Observable<ResponseModel> { 
  let url = `/campaignapi/profile/Get`;
  return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
    return response;
  }));
}

public getAllActiveCampaignByUserId(data: any): Observable<ResponseModel> { 
  let url = `/campaignapi/campaigns/Getall`;
  return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
    return response;
  }));
}

public getAllPostsByUserId(data: any): Observable<ResponseModel> { 
  let url = `/campaignapi/Profile/Getposts`;
  return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
    return response;
  }));
}

public getKarmaDeatilsByUserId(data: any): Observable<ResponseModel> { 
  let url = `/campaignapi/Profile/Getkarmadata`;
  return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
    return response;
  }));
}

// with “IsRaisedAmountDataRequired = false”    - for get connected campaigns
public getConnectedCampaignsByUserId(data: any): Observable<ResponseModel> { 
  let url = `/campaignapi/Profile/GetConnectedCampaigns`;
  return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
    return response;
  }));
}

public getNonProfitByUserId(data: any): Observable<ResponseModel> { 
  let url = `/campaignapi/Profile/GetConnectedFoundations`;
  return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
    return response;
  }));
}

// with “IsRaisedAmountDataRequired = true”  - for get Fund Raised campaigns
public getFundRaisedByUserId(data: any): Observable<ResponseModel> { 
  let url = `/campaignapi/Profile/GetConnectedCampaigns`;
  return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
    return response;
  }));
}

public getBackersByUserId(data: any): Observable<ResponseModel> { 
  let url = `/campaignapi/Profile/GetCampaignBackers`;
  return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
    return response;
  }));
}

// Followers/Followings
public getFollowers(data: any): Observable<ResponseModel> { 
  let url = `/campaignapi/follower/getfollowers`;
  return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
    return response;
  }));
}

public getFollowings(data: any): Observable<ResponseModel> { 
  let url = `/campaignapi/follower/getfollowings`;
  return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
    return response;
  }));
}

// Transactions
public getTransactions(data: any): Observable<ResponseModel> { 
  let url = `/paymentapi/transaction/getall`;
  return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
    return response;
  }));
}

public getTransactionDetails(transactionId: number): Observable<ResponseModel> {
  let url = `/paymentapi/transaction/get/${transactionId}`;
  return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
    return response;
  }));
}

//#endregion

//#Common Functions
getUserProfileDetails(userId: string, corporatePageId: number = 0){
  this.router.navigate(['reports/user-profile'], { queryParams: { userId: userId, corporatePageId: corporatePageId } });
}

//#end Common Functions

}
