export class PaginationModel {
    sortColumn: string;
    sortDirection: string;
    pageNumber: number = 1;
    pageSize: number = 10;
    offset:number = 0;
    searchText: string;
    getAll: boolean = false;
    totalRecords:number=0;
    eventType=EventType;
    //actionSourceId
    actionSourceId:number;
}

export class PaginationBaseModel {
    sortColumn: string = '';
    sortDirection: string = 'ASC';
    pageNumber: number = 1;
    pageSize: number = 10;
    searchText: string = '';
    getAll: boolean = false;
}

export enum EventType {
    Default ='default',
    Page = 'page',
    Sort = 'sort',
    Search = 'search',
    Filter = 'filter'
}
