import { Component, Injectable, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
// const I18N_VALUES = {
//   'en-US': {
//     weekdays: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
//     months: ['January, ', 'February, ', 'March, ', 'April, ', 'May, ', 'June, ', 'July, ', 'August, ', 'September, ', 'October, ', 'November, ', 'December,'],
//   }
// };
// @Injectable()
// export class I18n {
//   language = 'en-US';
// }

// @Injectable()
// export class CustomDatepickerI18n extends NgbDatepickerI18n {
//   constructor(private _i18n: I18n) { super(); }
//   getWeekdayShortName(weekday: number): string { return I18N_VALUES[this._i18n.language].weekdays[weekday - 1]; }
//   getMonthShortName(month: number): string { return I18N_VALUES[this._i18n.language].months[month - 1]; }
//   getMonthFullName(month: number): string { return this.getMonthShortName(month); }
//   getDayAriaLabel(date: NgbDateStruct): string { return `${date.day}-${date.month}-${date.year}`; }
// }
@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // providers:
  // [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }, NgbModalConfig, NgbModal]
})
export class DatepickerComponent   {
  // navigation = 'arrows';
  // currentYear: string;
  // _currentDate = new Date();
   fromDate: NgbDate | null;
   toDate: NgbDate | null;
   modelStartDate: any;
   maxDate: NgbDateStruct;
   minDate: NgbDateStruct;
   hoveredDate: NgbDate | null = null;
  

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
		this.fromDate = calendar.getToday();
		this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
	}
  onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

  // ngOnInit(): void {
  //   this.currentYear = this._currentDate.getFullYear().toString();
  //   console.log(this.currentYear);
  //   this.onDateSelection({ this._currentDate.getFullYear(), this._currentDate.getMonth() + 1,  this._currentDate.getDate() });

  // }
//  

}