import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResponseModel } from '../../shared/models/response.model';
import { DataService } from '../../shared/services/data.service';

import { SearchFoundationByAdminPgnQuery } from './non-profits.model';

@Injectable({
  providedIn: 'root'
})
export class NonProfitsService {

  constructor(private service: DataService) { }

  //get all with pagination
  public searchFoundations(data: SearchFoundationByAdminPgnQuery = new SearchFoundationByAdminPgnQuery()): Observable<ResponseModel> { 
    let url = `/campaignapi/foundation/search`;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
  
  // resend invite to non-profit on email
  public updateNonProfitStatus(data: any): Observable<ResponseModel> { 
    let url = `/campaignapi/foundation/updatestatus`;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }
}
