import { Component, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';

import { UserContentService } from '../user-content.service';
import { GetContentReportersRequestModel, GetReportedContentsRequestModel, ReportedContentStatus, ReportedContentVM, ReviewContentModalDataModel, ReviewContentRequestModel } from '../user-content.model';
import { LoggedInUserVM } from '@models/user.model';
import { StorageService } from '@services/storage.service';

import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';


@Component({
  selector: 'app-user-content-list',
  templateUrl: './user-content-list.component.html',
  styleUrls: ['./user-content-list.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class UserContentListComponent implements OnInit {
  public loggedInUser: LoggedInUserVM;
  
  public selected = [];
  modalTitle: string = "Content Details";
  modalData: any;
  isSaving: boolean = false;

  contentReportersModalData: GetContentReportersRequestModel;
  reviewContentModalData: ReviewContentModalDataModel;
  reviewAction: typeof ReportedContentStatus = ReportedContentStatus;

  ngxDatatableSSRConfig: NgxDatatableSSRConfig;
  getAllRequestModel: GetReportedContentsRequestModel;
  rows: ReportedContentVM[] = [];
  configureNgxDatable(){
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.ngxDatatableSSRConfig.sortColumn = 'contentId';
    this.ngxDatatableSSRConfig.sortDirection = 'DESC';
    this.ngxDatatableSSRConfig.pageNumber = 1;
    this.ngxDatatableSSRConfig.pageSize = 15;
    this.ngxDatatableSSRConfig.searchText = '';
    this.ngxDatatableSSRConfig.getAll = false;
  }

  constructor(
    private service: UserContentService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private storageservice: StorageService,
    private toaster: ToastrService) 
  { 
    this.config.centered = true;
    this.loggedInUser = this.storageservice.retrieve('user');
  }

  ngOnInit(): void {
    this.configureNgxDatable();
    this.getAllRequestModel = new GetReportedContentsRequestModel();
    this.loadListData();
  }

  loadListData(){
    this.ngxDatatableSSRConfig.isLoading = true;
    this.getAllRequestModel = {
      sortColumn : this.ngxDatatableSSRConfig.sortColumn,
      sortDirection:this.ngxDatatableSSRConfig.sortDirection,
      pageNumber: this.ngxDatatableSSRConfig.pageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll
    };

    this.rows = [];

    this.service.getAllReportedContents(this.getAllRequestModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      this.rows = res.response;
      const totalRecords = this.rows?.length > 0 ? this.rows[0].totalRecords : 0;
      this.ngxDatatableSSRConfig.totalRecords = totalRecords;
      this.ngxDatatableSSRConfig.isLoading = false;
    });
  }

  private handleError(error: any) {
    this.toaster.error(error.error.message);
    this.ngxDatatableSSRConfig.isLoading = false;
    return throwError(error);
  }

  convertHtmlToImage(contentContainer) {
    //console.log(contentContainer);
    htmlToImage.toJpeg(contentContainer, { quality: 0.95 })
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        contentContainer.appendChild(img);
      });
  }

  reviewContent(reviewContentModalData: ReviewContentModalDataModel, action: string, contentContainer: any){
    // let image = this.convertHtmlToImage(contentContainer);
    // return;
    const reviewContentRequestModel: ReviewContentRequestModel = {
      contentReferenceId: reviewContentModalData.contentReferenceId,
      reportedContentTypeId: reviewContentModalData.reportedContentTypeId,
      reviewAction: action,
      contentReviewerUserId: this.loggedInUser.id,
      contentReviewComment: null
    };
    
    reviewContentModalData.isActionBtnDisabled = true;
    if(action == this.reviewAction.Allowed){
      reviewContentModalData.isActionAllowProcessing = true;
    }
    else if(action == this.reviewAction.Deleted){
      reviewContentModalData.isActionDeleteProcessing = true;
    }

    this.service.reviewContent(reviewContentRequestModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      reviewContentModalData.isActionBtnVisible = false;
      this.modalService.dismissAll();
      this.loadListData();
    }); 
  }

  search(){
    this.ngxDatatableSSRConfig.onPageChanged(1);    
    this.loadListData();
  }  

  onPageSizeChanged(pageSize:number){
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);    
    this.loadListData();
  }

  onPageChanged(pageNum:number){
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);    
    this.loadListData();
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
  }

  showContentReporters(modalId, row: ReportedContentVM, onlyRecentReportersRequired: boolean = false){
    this.modalService.dismissAll();

    this.contentReportersModalData = new GetContentReportersRequestModel();
    this.contentReportersModalData.reportedContentTypeId = row.reportedContentTypeId;
    this.contentReportersModalData.contentReferenceId = row.contentReferenceId;
    this.contentReportersModalData.onlyRecentReportersRequired = onlyRecentReportersRequired;
    
    this.modalService.open(modalId, {size:'xl'});
  }
  
  openModal(modalId, row: ReportedContentVM) {
    this.reviewContentModalData = {
      isActionBtnVisible: row.contentReportersRecentCount > 0 ? true : false,
      isActionBtnDisabled: false,
      isActionAllowProcessing: false,
      isActionDeleteProcessing: false,

      contentReferenceId: row.contentReferenceId,
      reportedContentTypeId: row.reportedContentTypeId,
      reportedContentType: row.reportedContentType,
    }
    
    this.modalService.dismissAll();
    
    this.modalService.open(modalId);
  }

}
