import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { NgxDatatableConfig } from "@models/ngx-datatable-config.model";
import { PaginationModel } from 'src/app/shared/models/pagination.model';
import { NgbDatepickerI18n, NgbDateStruct, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
const I18N_VALUES = {
  'fr': {
    weekdays: ['S','M','T','W','T','F','S'],
    months: ['January,', 'February,', 'March,', 'April,', 'May,', 'June,', 'July,', 'August,', 'September,', 'October,', 'November,', 'December,'],
  }
  // other languages you would support
};
@Injectable()
export class I18n {
  language = 'fr';
 
}
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) { super(); }
  getWeekdayShortName(weekday: number): string {return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];}
  getMonthShortName(month: number): string { return I18N_VALUES[this._i18n.language].months[month - 1]; }
  getMonthFullName(month: number): string { return this.getMonthShortName(month); }
  getDayAriaLabel(date: NgbDateStruct): string { return `${date.day}-${date.month}-${date.year}`; }
  ngxdtConfig = new NgxDatatableConfig();
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  

  constructor() {
  }


  ngOnInit(): void { 
  }

  
}

