import { Component, Input, OnInit } from '@angular/core';
import { LoggedInUserVM } from '@models/user.model';
import { StorageService } from '@services/storage.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GetPostRequestModel, PostVM } from '../user-content.model';
import { UserContentService } from '../user-content.service';

@Component({
  selector: 'app-content-details-post',
  templateUrl: './content-details-post.component.html',
  styleUrls: ['./content-details-post.component.scss']
})
export class ContentDetailsPostComponent implements OnInit {
  
  @Input('contentReferenceId') postId: number;

  loggedInUser: LoggedInUserVM;
  post: PostVM = null;
  isLoading: boolean = false;
  
  constructor(
    private service: UserContentService,
    private storageservice: StorageService)
  { 
    this.loggedInUser = this.storageservice.retrieve('user');
  }

  ngOnInit(): void {
    this.getData(this.postId);
  }


  getData(postId: number){
    this.clearData();

    const requestModel: GetPostRequestModel = {
      userId: this.loggedInUser?.id,
      isCorporatePage: false,
      corporatePageId: 0,
      postId: postId
    };

    this.isLoading = true;

    this.service.getPost(requestModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      this.post = res?.response;
      this.isLoading = false;
    });
  }

  private handleError(error: any) {
    this.isLoading = false;
    this.clearData();
    return throwError(error);
  }

  private clearData(){
    this.post = null;
  }
}
