import { AfterViewInit, Component, HostListener, Injectable, OnInit, ViewChild } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ServerErrorModel } from '@models/server-error.model';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';
import Validation from 'src/app/shared/utils/validation';

import { UserService } from '../../user-management/user.service';
import { GetUsersRequestModel, UserVM } from '../../user-management/user.model';

@Component({
  selector: 'app-admin-user-list',
  templateUrl: './admin-user-list.component.html',
  styleUrls: ['./admin-user-list.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class AdminUserListComponent implements OnInit, AfterViewInit {

  @ViewChild('table') datatable: DatatableComponent;
  
  ngxDatatableSSRConfig: NgxDatatableSSRConfig;
  getAllRequestModel: GetUsersRequestModel;
  rows: UserVM[] = [];

  public selected = [];

  emailServerError: ServerErrorModel;
  usernameServerError: ServerErrorModel;

  isSaving: boolean = false;
  hidePassword: boolean = true;
  hideConfirmPassword: boolean = true;

  isUpdating: boolean = false;
  
  creatForm: FormGroup;  
  creatFormSubmitted: boolean = false;
  updateForm: FormGroup;  
  updateFormSubmitted: boolean = false;

  configureNgxDatable(){
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.ngxDatatableSSRConfig.sortColumn = '';
    this.ngxDatatableSSRConfig.sortDirection = 'DESC';
    this.ngxDatatableSSRConfig.pageNumber = 1;
    this.ngxDatatableSSRConfig.pageSize = 15;
    this.ngxDatatableSSRConfig.searchText = '';
    this.ngxDatatableSSRConfig.getAll = false;
  }

  @HostListener('window:resize') onResize() {
    if (this.datatable) {
      // setTimeout(() => {
      //   this.datatable.element.querySelector('.datatable-scroll').setAttribute('style', 'width:' + this.datatable.element.querySelector('.datatable-body-row').clientWidth + 'px');
      // }, 1000);
    }
  }   

  constructor(
    private service: UserService,
    private fb : FormBuilder,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private router: Router,
    private toaster: ToastrService) 
    { 
      this.config.centered = true;

      this.creatForm = this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: [{value: ''}, [Validators.required, Validators.email]],
        phoneNumber: ['', [Validators.required, Validators.pattern(Validation.PhonePattern)]],
        username: [{value: ''}, Validators.required],
        password: ['', [Validators.required, Validators.pattern(Validation.PasswordPattern)]],
        confirmPassword: ['', Validators.required]
      }, 
      {
        updateOn: "submit",
        validators: [Validation.match('password', 'confirmPassword')]
      });

      this.updateForm = this.fb.group({
        userId:['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        phoneNumber: ['', [Validators.required, Validators.pattern(Validation.PhonePattern)]],
        description: [''],
        username: [{value: '', disabled: true}, Validators.required],
        email: [{value: '', disabled: true}, [Validators.required, Validators.email]]        
      },
      { 
        updateOn: "submit" 
      });
    }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //     this.datatable.element.querySelector('.datatable-scroll .').setAttribute('style', 'width:' + '300' + 'px');
    //   }, 1000);
  }

  ngOnInit(): void {
    this.configureNgxDatable();    
    this.loadListData();
  }

  loadListData(){
    this.getAllRequestModel = new GetUsersRequestModel();
    this.ngxDatatableSSRConfig.isLoading = true;

    this.getAllRequestModel = {
      sortColumn : this.ngxDatatableSSRConfig.sortColumn,
      sortDirection:this.ngxDatatableSSRConfig.sortDirection,
      pageNumber: this.ngxDatatableSSRConfig.pageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,

      isAdminUsersRequired: true
    };

    this.rows = [];

    this.service.getAllUsers(this.getAllRequestModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      this.rows = res.response;
      const totalRecords = this.rows?.length > 0 ? this.rows[0].totalRecords : 0;
      this.ngxDatatableSSRConfig.totalRecords = totalRecords;
      this.ngxDatatableSSRConfig.isLoading = false;
    });
  }

  private handleError(error: any) {
    this.toaster.error(error.error.message);    
    this.ngxDatatableSSRConfig.isLoading = false;
    return throwError(error);
  }

  search(){
    this.ngxDatatableSSRConfig.onPageChanged(1);    
    this.loadListData();
  }  

  onPageSizeChanged(pageSize:number){
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);    
    this.loadListData();
  }

  onPageChanged(pageNum:number){
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);    
    this.loadListData();
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
  }
  
  get fc(): { [key: string]: AbstractControl } {
    return this.creatForm.controls;
  }
  get fu(): { [key: string]: AbstractControl } {
    return this.updateForm.controls;
  }

  openUpdateModal(modalId:any) {
    this.isUpdating = false;
    this.modalService.dismissAll();
    this.onUpdateFormReset();
    
    let editModel: UserVM = this.selected[0];
    if(editModel !== null){
      const initialFormData = {
        userId: editModel.userId,
        firstName: editModel.firstName,
        lastName: editModel.lastName,
        phoneNumber: editModel.phoneNumber,
        description: editModel.description,
        username: editModel.userName,
        email: editModel.email
      };
      this.updateForm.setValue(initialFormData);

      this.modalService.open(modalId,{backdrop: 'static'});
    }    
  }
  
  openCreateModal(modalId:any) {
    this.isSaving = false;
    this.modalService.dismissAll();
    this.onCreateFormReset();

    this.modalService.open(modalId,{backdrop: 'static'});
  }

  onUpdateFormReset(): void {
    this.updateFormSubmitted = false;
    this.updateForm.reset();
  }
  onCreateFormReset(): void {
    this.creatFormSubmitted = false;
    this.creatForm.reset();
    this.emailServerError = new ServerErrorModel();
    this.usernameServerError = new ServerErrorModel();
    this.hidePassword = true;
    this.hideConfirmPassword = true;
  }

  onUpdateFormSubmit(){
    this.updateFormSubmitted = true;
    this.isUpdating = true;

    if (this.updateForm.valid) {
      this.service.updateAdminUser(this.updateForm.value)
      .pipe(catchError((err) => {
        this.isUpdating = false;
        return this.handleError(err)
      }))
      .subscribe(res => {      
        this.toaster.success(res.message);
        this.isUpdating = false;
        this.modalService.dismissAll();
        this.ngOnInit();
      });
    }
    else{
      setTimeout(()=>{
        this.isUpdating = false;
        return;
      },200);
    }
  }

  onCreateFormSubmit(){
    this.creatFormSubmitted = true;
    this.isSaving = true;
    this.emailServerError = new ServerErrorModel();
    this.usernameServerError = new ServerErrorModel();

    if (this.creatForm.valid) {
      this.service.createAdminUser(this.creatForm.value)
      .pipe(catchError((error) => {
        this.isSaving = false;
        if(error.error.errors.length > 0){
          error.error.errors.forEach(err => {
            if(err == "Email already registered!"){
              this.emailServerError.hasError = true;
              this.emailServerError.message = err;
            }
            if(err == "UserName already taken!"){
              this.usernameServerError.hasError = true;
              this.usernameServerError.message = err;
            }
          });
        }
        return this.handleError(error)
      }))
      .subscribe(res => {      
        this.toaster.success(res.message);
        this.isSaving = false;
        this.modalService.dismissAll();
        this.ngOnInit();
      });
    }
    else{
      setTimeout(()=>{
        this.isSaving = false;
        return;
      },200);
    }
  }

}
