import { Component, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';

import { AllCampaignsReportVM, BackerVM, GetBackersRequestModel, GetCampaignsReportRequestModel } from '../reports.model';
import { ReportsService } from '../reports.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-campaign-backers-list',
  templateUrl: './campaign-backers-list.component.html',
  styleUrls: ['./campaign-backers-list.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class CampaignBackersListComponent implements OnInit {

  public selected = [];

  campaignId: number;
  sub:any;

  modalTitle: string = "Campaign Details";
  modalData: BackerVM;
  isSaving: boolean = false;  

  ngxDatatableSSRConfig: NgxDatatableSSRConfig;
  getAllRequestModel: GetBackersRequestModel;
  rows: BackerVM[] = [];

  configureNgxDatable(){
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.ngxDatatableSSRConfig.sortColumn = 'campaignId';
    this.ngxDatatableSSRConfig.sortDirection = 'DESC';
    this.ngxDatatableSSRConfig.pageNumber = 1;
    this.ngxDatatableSSRConfig.pageSize = 15;
    this.ngxDatatableSSRConfig.searchText = '';
    this.ngxDatatableSSRConfig.getAll = false;
  }


  constructor(
    private service: ReportsService,
    private activateRouter: ActivatedRoute,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private toaster: ToastrService
  ) {
    
  }

  ngOnInit(): void {
    this.sub = this.activateRouter.queryParams.subscribe(params => {
      this.campaignId = params['id'];
    });
    // if(this.campaignId == null || this.campaignId == undefined || this.campaignId == ''){
    //   this.router.navigate(['non-profit/register/invalid']);
    //   return;
    // }
    this.configureNgxDatable();    
    this.loadListData();
  }

  loadListData(){
    this.getAllRequestModel = new GetBackersRequestModel();

    this.ngxDatatableSSRConfig.isLoading = true;
    this.getAllRequestModel = {
      sortColumn : this.ngxDatatableSSRConfig.sortColumn,
      sortDirection:this.ngxDatatableSSRConfig.sortDirection,
      pageNumber: this.ngxDatatableSSRConfig.pageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,

      userId: "",
      isCorporatePage: false,
      corporatePageId: 0,
      campaignId: this.campaignId
    };

    this.rows = [];

    this.service.getBackersReportData(this.getAllRequestModel)
    .pipe(catchError((err) => this.handleError(err)))
    .subscribe(res => {
      this.rows = res.response;
      //const totalRecords = this.rows?.length > 0 ? this.rows[0].totalRecords : 0;      
      const totalRecords = this.rows?.length;
      this.ngxDatatableSSRConfig.totalRecords = totalRecords;
      this.ngxDatatableSSRConfig.isLoading = false;
    });
  }

  private handleError(error: any) {
    this.toaster.error(error.error.message);
    this.ngxDatatableSSRConfig.isLoading = false;
    return throwError(error);
  }

  search(){
    this.ngxDatatableSSRConfig.onPageChanged(1);    
    this.loadListData();
  }  

  onPageSizeChanged(pageSize:number){
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);    
    this.loadListData();
  }

  onPageChanged(pageNum:number){
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);    
    this.loadListData();
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
  }
  
  openModal(modalId:any) {
    let editModel: BackerVM = this.selected[0];

    this.isSaving = false;
    this.modalService.dismissAll();
    this.modalData = new BackerVM();

    if(editModel !== null){
      this.modalTitle = "Campaign Details";
      // this.modalData.id = editModel.id;
      // this.modalData.creatorUserId = editModel.creatorUserId;
      // this.modalData.creatorUserFullName = editModel.creatorUserFullName;
      // this.modalData.creatorUserUsername = editModel.creatorUserUsername;
      // this.modalData.creatorUserProfilePictureUrl = editModel.creatorUserProfilePictureUrl;
      // this.modalData.creatorUserProfilePictureThumbUrl = editModel.creatorUserProfilePictureThumbUrl;
      // this.modalData.name = editModel.name;
      // this.modalData.username = editModel.username;
      // this.modalData.email = editModel.email;
      // this.modalData.website = editModel.website;

      // this.modalData.coverPictureUrl = editModel.coverPictureUrl;
      // this.modalData.coverPictureThumbUrl = editModel.coverPictureThumbUrl;
      // this.modalData.profilePictureUrl = editModel.profilePictureUrl;      
      // this.modalData.profilePictureThumbUrl = editModel.profilePictureThumbUrl;

      // this.modalData.applicationDate = editModel.applicationDate;
      // this.modalData.statusId = editModel.statusId;
      // this.modalData.status = editModel.status;      
      // this.modalData.totalRecords = editModel.totalRecords;
    }
    else{
      this.modalTitle = "";
    }    
    //this.modalService.open(modalId);
  }


}
