import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';

import { AllCampaignsReportVM, CampaignFilterType, GetCampaignsReportRequestModel } from '../../reports.model';
import { ReportsService } from '../../reports.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackingMethodVM } from 'src/app/components/masters/tracking-methods/tracking-methods.model';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { TrackingMethodsService } from 'src/app/components/masters/tracking-methods/tracking-methods.service';


@Component({
  selector: 'app-rpt-campaigns',
  templateUrl: './rpt-campaigns.component.html',
  styleUrls: ['./rpt-campaigns.component.scss'],
  providers: [NgbModalConfig, NgbModal],
  encapsulation: ViewEncapsulation.None,
})
export class RptCampaignsComponent implements OnInit {
  public selected = [];

  onStartDatesChange(data) {
    this.filterModel.campaignStartDateStart = this._parserFormatter.format(data.startDate);
    this.filterModel.campaignStartDateEnd = this._parserFormatter.format(data.endDate);
    this.search();
  }
  onEndDatesChange(data) {
    this.filterModel.campaignEndDateStart = this._parserFormatter.format(data.startDate);
    this.filterModel.campaignEndDateEnd = this._parserFormatter.format(data.endDate);
    this.search();
  }

  //***/DateRange***
  modalTitle: string = "Campaign Details";
  modalData: AllCampaignsReportVM;
  isSaving: boolean = false;

  activityTypes: TrackingMethodVM[] = [];

  ngxDatatableSSRConfig: NgxDatatableSSRConfig;
  getAllRequestModel: GetCampaignsReportRequestModel;
  filterModel: GetCampaignsReportRequestModel = {
    campaignStartDateStart: null,
    campaignStartDateEnd: null,
    campaignEndDateStart: null,
    campaignEndDateEnd: null,
    campaignTypeId: 0,
    activityTypeId: 0,
    campaignFilterType: CampaignFilterType.All.toString(),

    sortColumn: '',
    sortDirection: '',
    pageNumber: 0,
    pageSize: 0,
    searchText: '',
    getAll: false
  };

  rows: AllCampaignsReportVM[] = [];

  configureNgxDatable() {
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.ngxDatatableSSRConfig.sortColumn = 'campaignId';
    this.ngxDatatableSSRConfig.sortDirection = 'DESC';
    this.ngxDatatableSSRConfig.pageNumber = 1;
    this.ngxDatatableSSRConfig.pageSize = 15;
    this.ngxDatatableSSRConfig.searchText = '';
    this.ngxDatatableSSRConfig.getAll = false;
  }


  constructor(
    private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,
    private service: ReportsService,
    private serviceTrackingMethod: TrackingMethodsService,
    private router: Router,
    private route: ActivatedRoute,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private _parserFormatter: NgbDateParserFormatter,
    private toaster: ToastrService
  ) {

  }

  ngOnInit(): void {
    this.loadActivityData();
    this.configureNgxDatable();
    this.loadListData();

  }

  loadListData() {
    this.getAllRequestModel = new GetCampaignsReportRequestModel();

    this.ngxDatatableSSRConfig.isLoading = true;
    this.getAllRequestModel = {
      sortColumn: this.ngxDatatableSSRConfig.sortColumn,
      sortDirection: this.ngxDatatableSSRConfig.sortDirection,
      pageNumber: this.ngxDatatableSSRConfig.pageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,

      activityTypeId: this.filterModel.activityTypeId,
      campaignTypeId: this.filterModel.campaignTypeId,
      campaignFilterType: this.filterModel.campaignFilterType,

      campaignStartDateStart: this.filterModel.campaignStartDateStart,
      campaignStartDateEnd: this.filterModel.campaignStartDateEnd,
      campaignEndDateStart: this.filterModel.campaignEndDateStart,
      campaignEndDateEnd: this.filterModel.campaignEndDateEnd
    };

    this.rows = [];

    this.service.getAllCampaignsReportData(this.getAllRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.rows = res.response;
        const totalRecords = this.rows?.length > 0 ? this.rows[0].totalRecords : 0;
        this.ngxDatatableSSRConfig.totalRecords = totalRecords;
        this.ngxDatatableSSRConfig.isLoading = false;
      });
  }

  loadActivityData() {
    const req = {
      sortColumn: "trackingMethodName",
      sortDirection: "ASC",
      pageNumber: 1,
      pageSize: 15,
      searchText: "",
      getAll: true
    };

    this.serviceTrackingMethod.getTrackingMethods(req)
      .pipe(catchError((err) => { return throwError(err); }))
      .subscribe(res => {
        this.activityTypes = res.response;
      });
  }

  private handleError(error: any) {
    this.toaster.error(error.error.message);
    this.ngxDatatableSSRConfig.isLoading = false;
    return throwError(error);
  }

  search() {
    this.ngxDatatableSSRConfig.onPageChanged(1);
    this.loadListData();
  }

  onPageSizeChanged(pageSize: number) {
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);
    this.loadListData();
  }

  onPageChanged(pageNum: number) {
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);
    this.loadListData();
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
  }

  getBackers(row: AllCampaignsReportVM) {
    this.router.navigate(['reports/backers'], { queryParams: { id: row.campaignId } });
    //this.router.navigate(['backers'], { relativeTo: this.route, queryParams: { id: row.campaignId } })
  }

  getCampaignCreators(row: AllCampaignsReportVM) {
    this.router.navigate(['reports/campaign-creator'], { queryParams: { userId: row.campaignCreatorUserId, corporatePageId: row.campaignCreatorCorporatePageId } });
  }

  getBody(): any[] {
    let data = [];
    this.rows.forEach(s => data.push([s.campaignName, s.campaignCreatorUsername, s.campaignType, s.foundationName, s.campaignStartDate, s.campaignEndDate, s.trackingAppName, s.trackingMethodName, s.campaignActivityGoal, this.getStatus(s), s.backersCount, s.campaignTotalPledgeAmount.toFixed(2), s.campaignTotalRaisedAmount.toFixed(2)]));
    return data;
  }

  // getExportData(){
  //   return {
  //     head: ['Campaign Name', 'Campaign Creator', 'Campaign Type', 'Non-Profit', 'Start Date', 'End Date', 'App', 'Activity Type', 'Goal', 'Campaign Status', 'Number of Backers', 'Total Pledged', 'Total Collected'],
  //     body: this.getBody(),
  //   }
  // }

  public downloadAsPDF() {
    const doc = new jsPDF('l', 'mm', 'a4');
    autoTable(doc, {
      head: [['Campaign Name', 'Campaign Creator', 'Campaign Type', 'Non-Profit', 'Start Date', 'End Date', 'App', 'Activity Type', 'Goal', 'Campaign Status', 'Number of Backers', 'Total Pledged', 'Total Collected']],
      body: this.getBody(),

    });
    doc.save('campaigns.pdf');
  }
  getStatus(row: AllCampaignsReportVM): string {
    return (row.campaignStatusId == 3) ? ((row.isCampaignGoalAchieved) ? "Successful" : "Unsuccessful") : ((row.campaignStatusId == 2) ? "In-Progress" : ((row.campaignStatusId == 1) ? "Future" : ""))
  }

  openModal(modalId: any) {
    // let editModel: AllCampaignsReportVM = this.selected[0];

    // this.isSaving = false;
    // this.modalService.dismissAll();
    // this.modalData = new AllCampaignsReportVM();

    // if(editModel !== null){
    //   this.modalTitle = "Campaign Details";
    //   // this.modalData.id = editModel.id;
    //   // this.modalData.creatorUserId = editModel.creatorUserId;
    //   // this.modalData.creatorUserFullName = editModel.creatorUserFullName;
    //   // this.modalData.creatorUserUsername = editModel.creatorUserUsername;
    //   // this.modalData.creatorUserProfilePictureUrl = editModel.creatorUserProfilePictureUrl;
    //   // this.modalData.creatorUserProfilePictureThumbUrl = editModel.creatorUserProfilePictureThumbUrl;
    //   // this.modalData.name = editModel.name;
    //   // this.modalData.username = editModel.username;
    //   // this.modalData.email = editModel.email;
    //   // this.modalData.website = editModel.website;

    //   // this.modalData.coverPictureUrl = editModel.coverPictureUrl;
    //   // this.modalData.coverPictureThumbUrl = editModel.coverPictureThumbUrl;
    //   // this.modalData.profilePictureUrl = editModel.profilePictureUrl;      
    //   // this.modalData.profilePictureThumbUrl = editModel.profilePictureThumbUrl;

    //   // this.modalData.applicationDate = editModel.applicationDate;
    //   // this.modalData.statusId = editModel.statusId;
    //   // this.modalData.status = editModel.status;      
    //   // this.modalData.totalRecords = editModel.totalRecords;
    // }
    // else{
    //   this.modalTitle = "";
    // }    
    // //this.modalService.open(modalId);
  }
  // onDateSelection(date: NgbDate) {
  // 	if (!this.fromDate && !this.toDate) {
  // 		this.fromDate = date;
  // 	} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
  // 		this.toDate = date;
  // 	} else {
  // 		this.toDate = null;
  // 		this.fromDate = date;
  // 	}
  // }

  // isHovered(date: NgbDate) {
  // 	return (
  // 		this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
  // 	);
  // }

  // isInside(date: NgbDate) {
  // 	return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  // }

  // isRange(date: NgbDate) {
  // 	return (
  // 		date.equals(this.fromDate) ||
  // 		(this.toDate && date.equals(this.toDate)) ||
  // 		this.isInside(date) ||
  // 		this.isHovered(date)
  // 	);
  // }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }



}
