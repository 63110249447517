import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalConfig, } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';

import { UserService } from '../user.service';
import { DeleteUserRequestModel, GetUsersRequestModel, UserStatus, UserVM } from '../user.model';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})
export class UserListComponent implements OnInit, AfterViewInit {
  @ViewChild('table') datatable: DatatableComponent;

  ngxDatatableSSRConfig: NgxDatatableSSRConfig;
  getAllRequestModel: GetUsersRequestModel;
  rows: UserVM[] = [];

  public selected = [];
  modalTitle: string = 'User Details';
  modalData: UserVM;
  isSaving: boolean = false;

  UserStatusEnum: typeof UserStatus = UserStatus;

  configureNgxDatable() {
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.ngxDatatableSSRConfig.sortColumn = '';
    this.ngxDatatableSSRConfig.sortDirection = 'DESC';
    this.ngxDatatableSSRConfig.pageNumber = 1;
    this.ngxDatatableSSRConfig.pageSize = 15;
    this.ngxDatatableSSRConfig.searchText = '';
    this.ngxDatatableSSRConfig.getAll = false;
  }
  @HostListener('window:resize') onResize() {
    if (this.datatable) {
      // setTimeout(() => {
      //   this.datatable.element.querySelector('.datatable-scroll').setAttribute('style', 'width:' + this.datatable.element.querySelector('.datatable-body-row').clientWidth + 'px');
      // }, 1000);
    }
  }

  constructor(
    private service: UserService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private toaster: ToastrService
  ) {
    this.config.centered = true;
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //     this.datatable.element.querySelector('.datatable-scroll .').setAttribute('style', 'width:' + '300' + 'px');
    //   }, 1000);
  }

  ngOnInit(): void {
    this.configureNgxDatable();
    this.getAllRequestModel = new GetUsersRequestModel();
    this.loadListData();
  }

  loadListData() {
    this.ngxDatatableSSRConfig.isLoading = true;
    this.getAllRequestModel = {
      sortColumn: this.ngxDatatableSSRConfig.sortColumn,
      sortDirection: this.ngxDatatableSSRConfig.sortDirection,
      pageNumber: this.ngxDatatableSSRConfig.pageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,

      isAdminUsersRequired: false,
    };

    this.rows = [];

    this.service
      .getAllUsers(this.getAllRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        this.rows = res.response;
        const totalRecords =
          this.rows?.length > 0 ? this.rows[0].totalRecords : 0;
        this.ngxDatatableSSRConfig.totalRecords = totalRecords;
        this.ngxDatatableSSRConfig.isLoading = false;
      });
  }

  private handleError(error: any) {
    this.toaster.error(error.error.message);
    this.ngxDatatableSSRConfig.isLoading = false;
    return throwError(error);
  }

  search() {
    this.ngxDatatableSSRConfig.onPageChanged(1);
    this.loadListData();
  }

  onPageSizeChanged(pageSize: number) {
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);
    this.loadListData();
  }

  onPageChanged(pageNum: number) {
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);
    this.loadListData();
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
  }

  openModal(modalId: any) {
    let editModel: UserVM = this.selected[0];

    this.isSaving = false;
    this.modalService.dismissAll();
    this.modalData = new UserVM();

    if (editModel !== null) {
      this.modalTitle = 'User Details';

      this.modalData.userId = editModel.userId;

      this.modalData.firstName = editModel.firstName;
      this.modalData.lastName = editModel.lastName;
      this.modalData.fullName = editModel.fullName;
      this.modalData.userName = editModel.userName;
      this.modalData.email = editModel.email;
      this.modalData.phoneNumber = editModel.phoneNumber;
      this.modalData.description = editModel.description;

      this.modalData.profilePictureUrl = editModel.profilePictureUrl;
      this.modalData.profilePictureThumbUrl = editModel.profilePictureThumbUrl;
      this.modalData.coverPictureUrl = editModel.coverPictureUrl;
      this.modalData.coverPictureThumbUrl = editModel.coverPictureThumbUrl;

      this.modalData.userRole = editModel.userRole;
      this.modalData.ageRange = editModel.ageRange;

      this.modalData.userStatus = editModel.userStatus;
      this.modalData.isLoggedIn = editModel.isLoggedIn;

      this.modalData.lastLoginDate = editModel.lastLoginDate;
      this.modalData.createdDate = editModel.createdDate;

      this.modalData.totalRecords = editModel.totalRecords;
    } else {
      this.modalTitle = '';
    }
    this.modalService.open(modalId, { backdrop: 'static' });
  }

  displayStyle = "none";
  deleteComment = "";
  deleteUserId = "";
  deletingUser: boolean = false;
  openDeletePopup(deleteUserId) {
    this.displayStyle = "block";
    this.deleteUserId = deleteUserId;
    this.deleteComment = "";
  }
  closeDeletePopup() {
    this.displayStyle = "none";
    this.deleteUserId = "";
    this.deleteComment = "";

    this.deletingUser = false;
  }
  deleteUser(){
    const deleteUserRequestModel : DeleteUserRequestModel = {
      deleteUserId: this.deleteUserId,
      deleteComment: this.deleteComment
    }
    
    this.deletingUser = true;

    this.service
      .deleteUser(deleteUserRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        this.deletingUser = false;
        this.closeDeletePopup();        
        this.modalService.dismissAll();        
        this.toaster.success(res.message);
        this.loadListData();
      });
  }

}