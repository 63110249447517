import { Component, OnInit, Renderer2 } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbDateParserFormatter, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableSSRConfig } from '@models/ngx-datatable-ssr-config.model';

import { CampaignStatus, CampaignStatusFilter, GetPayoutsReportRequestModel, PaymentCheckoutReportVM, UpdatePayoutStatusRequestModel } from '../reports.model';
import { ReportsService } from '../reports.service';
import { Router } from '@angular/router';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { LoggedInUserVM } from '@models/user.model';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-report-payouts-history',
  templateUrl: './report-payouts-history.component.html',
  styleUrls: ['./report-payouts-history.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class ReportPayoutsHistoryComponent implements OnInit {
  public loggedInUser: LoggedInUserVM;

  public selected = [];

  campaignStatusEnum: typeof CampaignStatus = CampaignStatus;

  modalTitle: string = "Payout Details";
  modalData: PaymentCheckoutReportVM = new PaymentCheckoutReportVM();
  isUpdating: boolean = false;

  ngxDatatableSSRConfig: NgxDatatableSSRConfig;
  getAllRequestModel: GetPayoutsReportRequestModel;
  filterModel: GetPayoutsReportRequestModel = {
    campaignStartDateStart: null,
    campaignStartDateEnd: null,
    campaignEndDateStart: null,
    campaignEndDateEnd: null,
    campaignStatusFilter: CampaignStatusFilter.All.toString(),

    sortColumn: '',
    sortDirection: '',
    pageNumber: 0,
    pageSize: 0,
    searchText: '',
    getAll: false
  };

  rows: PaymentCheckoutReportVM[] = [];

  configureNgxDatable() {
    this.ngxDatatableSSRConfig = new NgxDatatableSSRConfig();
    this.ngxDatatableSSRConfig.sortColumn = 'foundationName';
    this.ngxDatatableSSRConfig.sortDirection = 'DESC';
    this.ngxDatatableSSRConfig.pageNumber = 1;
    this.ngxDatatableSSRConfig.pageSize = 15;
    this.ngxDatatableSSRConfig.searchText = '';
    this.ngxDatatableSSRConfig.getAll = false;
  }

  constructor(
    private service: ReportsService,
    private router: Router,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private _parserFormatter: NgbDateParserFormatter,
    private storageservice: StorageService,
    private toaster: ToastrService) {
    this.loggedInUser = this.storageservice.retrieve('user');
  }

  ngOnInit(): void {
    this.configureNgxDatable();
    this.loadListData();
  }

  loadListData() {
    this.getAllRequestModel = new GetPayoutsReportRequestModel();

    this.ngxDatatableSSRConfig.isLoading = true;
    this.getAllRequestModel = {
      sortColumn: this.ngxDatatableSSRConfig.sortColumn,
      sortDirection: this.ngxDatatableSSRConfig.sortDirection,
      pageNumber: this.ngxDatatableSSRConfig.pageNumber,
      pageSize: this.ngxDatatableSSRConfig.pageSize,
      searchText: this.ngxDatatableSSRConfig.searchText,
      getAll: this.ngxDatatableSSRConfig.getAll,

      campaignStatusFilter: this.filterModel.campaignStatusFilter,

      campaignStartDateStart: this.filterModel.campaignStartDateStart,
      campaignStartDateEnd: this.filterModel.campaignStartDateEnd,
      campaignEndDateStart: this.filterModel.campaignEndDateStart,
      campaignEndDateEnd: this.filterModel.campaignEndDateEnd
    };

    this.rows = [];

    this.service.getPayoutsReportData(this.getAllRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        this.rows = res.response;
        const totalRecords = this.rows?.length > 0 ? this.rows[0].totalRecords : 0;
        this.ngxDatatableSSRConfig.totalRecords = totalRecords;
        this.ngxDatatableSSRConfig.isLoading = false;
      });
  }
  getPayoutStatus(row: PaymentCheckoutReportVM):string {
    if(row.campaignStatus == CampaignStatus.Start.toString()){
      return CampaignStatusFilter.CampaignOngoing.toString();
    }
    else if(row.campaignStatus == CampaignStatus.Complete.toString() && row.isCampaignGoalAchieved && !row.isPaymentDisbursed){
      return CampaignStatusFilter.PayoutPending.toString();
    }
    else if(row.campaignStatus == CampaignStatus.Complete.toString() && row.isCampaignGoalAchieved && row.isPaymentDisbursed){
      return CampaignStatusFilter.PayoutComplete.toString();
    }
    return null;
  }

  private handleError(error: any) {
    this.ngxDatatableSSRConfig.isLoading = false;
    this.isUpdating = false;
    this.toaster.error(error.error.message);
    return throwError(error);
  }

  search() {
    this.ngxDatatableSSRConfig.onPageChanged(1);
    this.loadListData();
  }

  onPageSizeChanged(pageSize: number) {
    this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);
    this.loadListData();
  }

  onPageChanged(pageNum: number) {
    this.ngxDatatableSSRConfig.onPageChanged(pageNum);
    this.loadListData();
  }

  getBackers(row: PaymentCheckoutReportVM) {
    this.router.navigate(['reports/backers'], { queryParams: { id: row.campaignId } });
  }

  getCampaignCreators(row: PaymentCheckoutReportVM) {
    this.router.navigate(['reports/campaign-creator'], { queryParams: { userId: row.campaignCreatorUserId, corporatePageId: row.campaignCreatorCorporatePageId } });
  }

  // getUserProfileDetails(row: PaymentCheckoutReportVM){
  //   this.router.navigate(['reports/user-profile'], { queryParams: { userId: row.campaignCreatorUserId, corporatePageId: row.campaignCreatorCorporatePageId } });
  // }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
  }

  //***DateRange***
  onStartDatesChange(data) {
    this.filterModel.campaignStartDateStart = this._parserFormatter.format(data.startDate);
    this.filterModel.campaignStartDateEnd = this._parserFormatter.format(data.endDate);
    this.search();
  }
  onEndDatesChange(data) {
    this.filterModel.campaignEndDateStart = this._parserFormatter.format(data.startDate);
    this.filterModel.campaignEndDateEnd = this._parserFormatter.format(data.endDate);
    this.search();
  }
  //***end DateRange***

  getBody(): any[] {
    let data = [];
    this.rows.forEach(s => data.push([s.foundationName, s.campaignName, s.campaignCreatorUsername, s.campaignStartDate, s.campaignEndDate, s.campaignTotalPledgeAmount.toFixed(2), s.campaignTotalRaisedAmount.toFixed(2), this.getKnoblFee(s.campaignTotalRaisedAmount).toFixed(2), this.getAuthorizeFee(s.campaignTotalRaisedAmount).toFixed(2)]));
    return data;
  }

  public downloadAsPDF() {
    const doc = new jsPDF('l', 'mm', 'a4');
    autoTable(doc, {
      head: [['Non-Profit', 'Campaign Name', 'Campaign Creator', 'Start Date', 'End Date', 'Total Pledged', 'Total Collected', 'Knobl Fee', 'Authorize Fee']],
      body: this.getBody(),
    });
    doc.save('payouts.pdf');
  }

  getKnoblFee(value: number): number {
    return (value > 0 ? (((value * 0.08) + 0.30) - ((value * 2.9 / 100) + 0.30)) : 0);
  }
  getAuthorizeFee(value: number): number {
    return (value > 0 ? (value * 2.9 / 100) + 0.30 : 0);
  }

  openModal(modalId, row: PaymentCheckoutReportVM) {
    this.modalData = row;
    this.modalService.open(modalId, { centered: true, size: 'lg', backdrop: 'static' });
  }
  
  // updatePayoutStatus(row: PaymentCheckoutReportVM) {
  //   this.isUpdating = true;
  //   let requestModel: UpdatePayoutStatusRequestModel = {
  //     campaignId: row.campaignId,
  //     updatedByUserId: this.loggedInUser.id
  //   }
  //   this.service.updatePayoutStatus(requestModel)
  //     .pipe(catchError((err) => this.handleError(err)))
  //     .subscribe(res => {
  //       if (res.isSuccess) {
  //         row.isPaymentDisbursed = true;
  //         this.modalService.dismissAll();
  //       }
  //       this.isUpdating = false;
  //     });
  // }
}
