<!-- content starts-->
<div class="content-wrapper">
    <app-breadcrumb title="Campaign Backers" [items]="['Reports','Campaigns']" [active_item]="'Backers'"></app-breadcrumb>
    <div class="card p-0 mb-0 pb-3">
      <div class="card-body px-0 pt-2 pb-0">
        <div class="ngxdt business-list">
    
          <div class="ngxdt-header">            
            <div class="ngxdt-search">
              <div class="ngxdt-search-wrapper">
                <i class="fa fa-search"></i>
                <input type="text" (keydown)="($event.key === 'Enter')?search():true" (focus)="ngxDatatableSSRConfig.showSearchButton(true)" (blur)="ngxDatatableSSRConfig.showSearchButton(false)" placeholder="Search" [(ngModel)]="ngxDatatableSSRConfig.searchText">
                <div class="btn-search" >
                  <button *ngIf="ngxDatatableSSRConfig.searchBtnVisible" (click)="search()">
                    <i class="fa fa-filter"></i>
                  </button>
                </div>
                
              </div>
            </div>
          </div>
      
          <div class="ngxdt-body">
            <ngx-datatable
              #table
              class="bootstrap"
              [messages]="{
                emptyMessage: ngxDatatableSSRConfig.isLoading ? ngxDatatableSSRConfig.loadingMessage : ngxDatatableSSRConfig.emptyMessage | translate,
                totalMessage: ngxDatatableSSRConfig.totalMessage | translate,
                selectedMessage: false
              }"
              [footerHeight]="ngxDatatableSSRConfig.footerHeight"
              [limit]="ngxDatatableSSRConfig.pageSize"
              [rows]="rows"
              [columnMode]="ngxDatatableSSRConfig.columnMode.force"
              [offset]="ngxDatatableSSRConfig.tableOffset"
              [loadingIndicator]="false"
              [selectionType]="ngxDatatableSSRConfig.selectionType.single"
              [selected]="selected"
              (select)="openModal(modalId);"
              [scrollbarH]="true"
              [scrollbarV]="true"
              [virtualization]="false"
            >
              <ngx-datatable-column [width]="50" headerClass="make-center" cellClass="make-center" prop="id" name="#" [sortable]="false" [draggable]="false">
                <ng-template let-rowIndex="rowIndex + 1" let-value="value" ngx-datatable-cell-template >
                  {{(ngxDatatableSSRConfig.pageSize * (ngxDatatableSSRConfig.pageNumber - 1)) + rowIndex}}.
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="150" prop="backerUsername" name="Backer" [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="150" prop="backerFullname" name="Name" [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>              
              <ngx-datatable-column [width]="100" prop="pledgeAmount" name="Pledged Amount" [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value | number:'2.2-2' }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="100" prop="joiningDate" name="Joining Date" [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value | date:'MM-dd-yyyy' }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [width]="100" prop="totalCompletedActivityValue" name="Activity Performed" [sortable]="false" [draggable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value | number:'2.1-1' }} {{row.trackingUnitName}}
                </ng-template>
              </ngx-datatable-column>
              
              <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template>
                  <div>
                    <div class="page-size-control">
                      Show
                      <select class="page-size-dropdown" [(ngModel)]="ngxDatatableSSRConfig.pageSize" (ngModelChange)="onPageSizeChanged($event)">
                        <option *ngFor="let opt of ngxDatatableSSRConfig.limitOptions" [ngValue]="opt.value">{{opt.key}}</option>
                      </select>
                      entries
                    </div>
                    <div class="pagination-control">
                      <ngb-pagination
                            #ngbPage
                            [boundaryLinks]="false"
                            [collectionSize]="ngxDatatableSSRConfig.totalRecords"
                            [pageSize]="ngxDatatableSSRConfig.pageSize"
                            [page]="ngxDatatableSSRConfig.pageNumber"
                            [maxSize]="5"
                            (pageChange)="onPageChanged($event)">
                      </ngb-pagination>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-footer>
      
            </ngx-datatable>
          </div>
  
        </div>
      </div>
    </div>
  </div>
  <!-- content Ends-->
  
  <!-- Modal starts -->
  <ng-template #modalId let-modal>
    
  </ng-template>
  <!-- Modal ends -->
  
