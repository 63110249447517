<div *ngIf="isLoading" class="data-status">Loading data...</div>
<div *ngIf="!isLoading && campaign == null" class="data-status">No content found.</div>
<div *ngIf="!isLoading && campaign != null" class="content-wrapper">
  <div #reportedContent class="reported-content">
    <div class="content-campaign">
      <div class="campaign-header">
        <div class="campaign-header-wrapper">
          <h5>Campaign Overview</h5>
        </div>
      </div>
      
      <div class="campaign-image">
        <img *ngIf="!campaign.isLinkAttached" [src]="campaign.campaignCoverPictureUrl" alt="campaign image" />
        <span class="campaign-type">Social</span>
      </div>

      <div class="campaign-info">
        <h5 class="campaign-title">{{campaign.campaignName}}</h5>
        <p class="campaign-description">{{campaign.campaignDescription}}</p>
      </div>

      <div class="campaign-creator-profile">
        <div class="campaign-creator-wrapper">
          <div class="image-thumbnail campaign-creator-profile-img">
            <img [src]="campaign.campaignCreatorProfilePictureUrl" alt="">
          </div>
          <div>
            <div class="campaign-creator">Creator</div>
            <div class="campaign-username">{{campaign.campaignCreatorUsername}}</div>
          </div>
        </div>
      </div>

      <div class="campaign-duration-section">
        <div class="campaign-dates">
          <div class="date start-date">
            <div class="date-pointer">
              <div class="arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 15 20">
                  <path id="Arrow" d="M5.5,1,15,20H0Z" transform="translate(-11, 4) rotate(215)" fill="#ff4714"></path>
                </svg>
              </div>
            </div>
            <div class="date-title">
              Starts on
            </div>
            <div class="date-value">
              {{campaign.campaignStartDate | date:'dd MMM YY'}}
            </div>
          </div>
          <div class="date end-date">
            <div class="date-pointer">
              <div class="arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 15 20">
                  <path id="Arrow" d="M5.5,1,15,20H0Z" transform="translate(-11, 4) rotate(215)" fill="#ff4714"></path>
                </svg>
              </div>
            </div>
            <div class="date-title">
              End on
            </div>
            <div class="date-value">
              {{campaign.campaignEndDate | date:'dd MMM YY'}}
            </div>
          </div>
        </div>
        <div class="campaign-duration">
          <div class="round-box">
            <div class="box-content">
              <span class="main-text">{{campaign.campaignDurationInDays}}</span>
              <span class="sub-text">Days</span>
            </div>
          </div>
        </div>
      </div>

      <div class="campaign-btn">
        View Related Post
      </div>

      <div class="campaign-details-section">
        <div class="campaign-details">
          <span class="campaign-details-title">Community Goal</span>
          <div class="round-box">
            <div class="box-content">
              <span class="img-icon">
                <img [src]="campaign.trackingMethodIconPictureUrl" alt="tracking activity logo" />
              </span>
              <span class="main-text"
                [ngStyle]="adjustSize(campaign.campaignActivityGoal)">{{campaign.campaignActivityGoal}}</span>
              <span class="sub-text unit-name">{{campaign.trackingUnitName}}</span>
            </div>
          </div>
        </div>
        <div class="campaign-section-divider">
          <div class="arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 15 20">
              <path id="Arrow" d="M5.5,1,15,20H0Z" transform="translate(-11, 4) rotate(215)" fill="#ff4714"></path>
            </svg>
          </div>
        </div>
        <div class="campaign-details">
          <span class="campaign-details-title">Using</span>
          <div class="round-box">
            <img class="box-image" [src]="campaign.trackingAppIconPictureThumbUrl" alt="tracking app logo">
          </div>
        </div>
      </div>

      <div class="campaign-details-section">
        <div class="campaign-details">
          <span class="campaign-details-title">{{campaign.campaignCreatorUsername}}</span>
          <div class="round-box">
            <div class="box-content">
              <span class="main-text mt-2"
                [ngStyle]="adjustSize(campaign.campaignTotalPledgeAmount)">${{campaign.campaignTotalPledgeAmount | number:'1.0':'en-US'}}</span>
              <span class="sub-text mt-1">Pledged</span>
            </div>
          </div>
        </div>
        <div class="campaign-section-divider">
          <div class="arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 15 20">
              <path id="Arrow" d="M5.5,1,15,20H0Z" transform="translate(-11, 4) rotate(215)" fill="#ff4714"></path>
            </svg>
          </div>
        </div>
        <div class="campaign-details">
          <span class="campaign-details-title">To The Nonprofit</span>
          <div class="round-box">
            <img class="box-image" [src]="campaign.foundationLogoPictureThumbUrl" alt="non-profit logo">
          </div>
        </div>
      </div>

      <div class="campaign-stats-section">
        <div class="stats-item">
          <div class="stats-icon">
            <img src="../../../../assets/images/campaignlogo/Backer Icon.png" alt="backers">
          </div>
          <div class="stats-data ">
            <div class="stats-value">{{campaign.backersCount}}</div>
            <div class="stats-title">Backers</div>
          </div>
        </div>
        <div class="stats-item">
          <div class="stats-icon">
            <img src="../../../../assets/images/campaignlogo/Calender Icon.png" alt="days left">
          </div>
          <div class="stats-data">
            <div class="stats-value">{{campaign.calendarDaysCount}}</div>
            <div class="stats-title">Day Left</div>
          </div>
        </div>
        <div class="stats-item">
          <div class="stats-icon">
            <img src="../../../../assets/images/campaignlogo/Pledge Icon.png" alt="pledged">
          </div>
          <div class="stats-data">
            <div class="stats-value"            
              [ngStyle]="adjustSize(campaign.campaignTotalPledgeAmount)">${{campaign.campaignTotalPledgeAmount | number:'1.0':'en-US'}}</div>
            <div class="stats-title">Total Pledged</div>
          </div>
        </div>
      </div>

      <div class="campaign-progress-section">
        <div class="campaign-progress">
          <div class="campaign-progressbar">
            <ngb-progressbar type="warning" [value]="campaign.campaignProgress" height="8px" [animated]="true"
              max="100">
            </ngb-progressbar>
          </div>
          <div class="campaign-status">
            <div class="progressbar-status-title">{{campaign.campaignProgress}}% Complete</div>
            <div class="progressbar-status-title">{{campaign.campaignActivityGoal}} {{campaign.trackingUnitName |
              titlecase }} Goal
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>