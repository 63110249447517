import { PaginationBaseModel } from "@models/pagination.model";

export class Reports {
}

//enums
export enum CampaignStatus {
    "Schedule" = "Schedule",
    "Start" = "Start",
    "Complete" = "Complete",
    "Expire" = "Expire",
    "Cancel" = "Cancel",
    "Suspend" = "Suspend",
    "RollBack" = "RollBack"
}
export enum CampaignFilterType {
    "Successful" = 'Successful',
    "Unsuccessful" = 'Unsuccessful',
    "InProgress" = 'In-Progress',
    "Future" = 'Future',
    "All" = 'All'
}
export enum CampaignStatusFilter {
    "CampaignOngoing" = "Campaign Ongoing",
    "PayoutPending" = "Payout Pending",
    "PayoutComplete" = "Payout Complete",
    "All" = "All"
}
export enum CampaignType {
    "Independent" = 1,
    "Social" = 2
}

export class AllCampaignsReportVM {
    campaignId: number;
    campaignName: string;
    campaignCoverPictureUrl: string;
    campaignCoverPictureThumbUrl: string;
    campaignTypeId: number;
    campaignType: string;
    campaignCreatorUserId: string;
    campaignCreatorUsername: string;
    campaignCreatorCorporatePageId: number;
    campaignCreatorProfilePictureUrl: string;
    campaignCreatorProfilePictureThumbUrl: string;
    campaignStartDate: string;
    campaignEndDate: string;
    isCampaignGoalAchieved: boolean;
    campaignActivityGoal: number;
    campaignPledgeAmount: number;
    campaignTotalPledgeAmount: number;
    campaignTotalRaisedAmount: number;
    totalDonationAmount: number;
    totalPaymentGatewayFeeAmount: number;
    totalPlatformFeeAmount: number;
    campaignProgress: string;
    campaignProgressPercentage: string;
    campaignStatusId: number;
    campaignStatus: string;
    foundationId: number;
    foundationName: string;
    foundationLogoPictureUrl: string;
    foundationLogoPictureThumbUrl: string;
    trackingAppId: number;
    trackingAppName: string;
    trackingAppIconPictureUrl: string;
    trackingAppIconPictureThumbUrl: string;
    trackingMethodId: number;
    trackingMethodName: string;
    trackingMethodIconPictureUrl: string;
    trackingMethodIconPictureThumbUrl: string;
    backersCount: number;
    totalRecords: number;
}

export class PaymentCheckoutReportVM {
    campaignId: number;
    campaignName: string;
    campaignCoverPictureFileName: string;
    campaignTypeId: number;
    campaignType: string;
    campaignCreatorUserId: string;
    campaignCreatorCorporatePageId: number;
    campaignCreatorUsername: string;
    campaignCreatorProfilePictureFilename: string;
    campaignCreatorCoverPictureFilename: string;
    campaignStartDate: string;
    campaignEndDate: string;
    campaignProgress: number;
    campaignProgressPercentage: number;
    campaignPledgeAmount: number;
    campaignTotalPledgeAmount: number;
    campaignTotalRaisedAmount: number;
    totalDonationAmount: number;
    totalPaymentGatewayFeeAmount: number;
    totalPlatformFeeAmount: number;
    isCampaignGoalAchieved: boolean;
    campaignActivityGoal: number;
    campaignStatusId: number;
    campaignStatus: string;
    foundationId: number;
    foundationName: string;
    foundationLogoPictureFileName: string;
    trackingAppId: number;
    trackingAppName: string;
    trackingAppIconPictureFileName: string;
    trackingMethodId: number;
    trackingMethodName: string;
    trackingMethodIconPictureFileName: string;
    backersCount: number;
    isPaymentDisbursed: boolean;
    totalRecords: number;
    
    payoutDetails: PayoutDetail;
}

export class GetCampaignsReportRequestModel extends PaginationBaseModel{
    campaignStartDateStart: string;
    campaignStartDateEnd: string;
    campaignEndDateStart: string;
    campaignEndDateEnd: string;
    campaignTypeId: number;
    activityTypeId: number;
    campaignFilterType: string;
}
export class GetPayoutsReportRequestModel extends PaginationBaseModel{
    campaignStartDateStart: string;
    campaignStartDateEnd: string;
    campaignEndDateStart: string;
    campaignEndDateEnd: string;
    campaignStatusFilter: string;
}
export class UpdatePayoutStatusRequestModel {
    campaignId: number;
    updatedByUserId: string;
}


export class BackerVM {
    campaignUserMappingId: number;
    campaignUserMappingStatusId: number;
    campaignUserMappingStatus: string;
    campaignId: number;
    backerUserId: string;
    isBackerCorporatePage: boolean;
    backerCorporatePageId: number;
    backerUsername: string;
    backerFullname: string;
    backerProfilePictureUrl: string;
    backerProfilePictureThumbUrl: string;
    isFollowed: boolean;
    followerId: number;
    joiningDate: string;
    joiningType: string;
    pledgeAmount: number;
    trackingMethodId: number;
    trackingMethodName: string;
    trackingMethodIconPictureUrl: string;
    trackingMethodIconPictureThumbUrl: string;
    trackingUnitId: number;
    trackingUnitName: string;
    totalCompletedActivityValue: number;
    isCampaignCreator: boolean;
}
export class GetBackersRequestModel extends PaginationBaseModel{
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
    campaignId: number;
}

export class CreatorCampaignVM {
    campaignId: number;
    campaignName: string;
    campaignCoverPictureUrl: string;
    campaignCoverPictureThumbUrl: string;
    campaignTypeId: number;
    campaignType: string;
    campaignCreatorUserId: string;
    campaignCreatorUsername: string;
    campaignCreatorCorporatePageId: number;
    campaignCreatorProfilePictureUrl: string;
    campaignCreatorProfilePictureThumbUrl: string;
    campaignStartDate: string;
    campaignEndDate: string;
    campaignDurationInDays: number;
    isCampaignGoalAchieved: boolean;
    campaignPledgeAmount: number;
    campaignTotalRaisedAmount: number;
    campaignActivityGoal: number;
    campaignProgress: string;
    campaignProgressPercentage: string;
    campaignStatusId: number;
    campaignStatus: string;
    foundationId: number;
    foundationName: string;
    foundationLogoPictureUrl: string;
    foundationLogoPictureThumbUrl: string;
    trackingAppId: number;
    trackingAppName: string;
    trackingAppIconPictureUrl: string;
    trackingAppIconPictureThumbUrl: string;
    trackingMethodId: number;
    trackingMethodName: string;
    trackingMethodIconPictureUrl: string;
    trackingMethodIconPictureThumbUrl: string;
    backersCount: number;
}

export class GetCreatorCampaignsRequestModel extends PaginationBaseModel{
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
    isRaisedAmountDataRequired: boolean;
    campaignFilterType: string;
}


//#region User Profile Details
// ProfileReportByUser

export class ProfileReportByUserVM {
    userId: string;
    getProfileDataQueryResponse: ProfileRM;
    getKarmaDataQueryResponse: KarmaDataRM;
    getCampaignsPgnQueryResponse: CampaignsRM[];
    getProfilePostsPgnQueryResponse: ProfilePostsRM[];
    getKarmaCampaignsPgnQueryResponse: KarmaCampaignsRM[];
    getKarmaFoundationsPgnQueryResponse: NonProfitRM[];
    getCampaignBackersPgnQueryResponse: BackersRM[];
}

// ProfileData
export class ProfileRM extends PaginationBaseModel{
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
    profileUserId: string;
    isProfileCorporatePage: boolean;
    profileCorporatePageId: number;
}

export class ProfileVM {
    profileUserId: string;
    isProfileCorporatePage: boolean;
    profileCorporatePageId: number;
    profileUsername: string;
    profileFullname: string;
    profileCorporateWebsite: string;
    profilePictureUrl: string;
    profilePictureThumbUrl: string;
    profileCoverPictureUrl: string;
    profileCoverPictureThumbUrl: string;
    profileUserRole: string;
    profileDescription: string;
    isFollow: boolean;
    followerId: number;
    isFollowing: boolean;
    followerCount: number;
    followingCount: number;
}

//AllActiveCampaign
export class CampaignsRM extends PaginationBaseModel {
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
    getOnlyActiveCampaigns: boolean;
}

export class CampaignsVM {
    campaignId: number;
    campaignName: string;
    campaignCoverPictureUrl: string;
    campaignCoverPictureThumbUrl: string;
    campaignTypeId: number;
    campaignType: string;
    campaignCreatorUserId: string;
    campaignCreatorUsername: string;
    campaignCreatorCorporatePageId: number;
    campaignCreatorProfilePictureUrl: string;
    campaignCreatorProfilePictureThumbUrl: string;
    campaignStartDate: string;
    campaignEndDate: string;
    campaignProgress: string;
    campaignPledgeAmount: number;
    campaignTotalPledgeAmount: number;
    campaignActivityGoal: number;
    isCampaignGoalAchieved: boolean;
    campaignProgressPercentage: string;
    campaignStatusId: number;
    campaignStatus: string;
    foundationId: number;
    foundationName: string;
    foundationLogoPictureUrl: string;
    foundationLogoPictureThumbUrl: string;
    trackingAppId: number;
    trackingAppName: string;
    trackingAppIconPictureUrl: string;
    trackingAppIconPictureThumbUrl: string;
    trackingMethodId: number;
    trackingMethodName: string;
    trackingMethodIconPictureUrl: string;
    trackingMethodIconPictureThumbUrl: string;
}


//AllPosts
export class ProfilePostsRM extends PaginationBaseModel {
    isUserPostRequired: boolean;
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
    profileUserId: string;
    isProfileCorporatePage: boolean;
    profileCorporatePageId: number;
    onlyCampaignPostRequired: boolean;
    campaignId: number;
}

export class ProfilePostsVM {
    postId: number;
    postCreatorUserId: string;
    postCreatorUsername: string;
    postCreatorCorporatePageId: number;
    postCreatorProfilePictureUrl: string;
    postCreatorProfilePictureThumbUrl: string;
    isCampaignPost: boolean;
    campaignData: CampaignData;
    postDescription: string;
    postImagePictureUrl: string;
    postImagePictureThumbUrl: string;
    isLinkAttached: boolean;
    postLinkData: LinkData;
    postLikeCount: number;
    postCommentCount: number;
    postCreatedAt: string;
    postLastUpdatedAt: string;
    isPostLiked: boolean;
}

export class LinkData {
    postLinkUrl: string;
    linkWebSiteUrl: string;
    linkTitle: string;
    linkDescription: string;
    linkImageUrl: string;
}

export class CampaignData {
    campaignId: number;
    campaignTypeId: number;
    campaignType: string;
    campaignCoverPictureUrl: string;
    campaignCoverPictureThumbUrl: string;
    campaignName: string;
    campaignStartDate: string;
    campaignEndDate: string;
    trackingAppId: number;
    trackingAppName: string;
    trackingAppIconPictureUrl: string;
    trackingAppIconPictureThumbUrl: string;
    trackingMethodId: number;
    trackingMethodName: string;
    trackingMethodIconPictureUrl: string;
    trackingMethodIconPictureThumbUrl: string;
    trackingUnitId: number;
    trackingUnitName: string;
    campaignActivityGoal: number;
    isCampaignGoalAchieved: boolean;
    campaignPledgeAmount: number;
    foundationId: number;
    foundationName: string;
    foundationLogoPictureUrl: string;
    foundationLogoPictureThumbUrl: string;
    campaignStatusId: number;
    campaignStatus: string;
    campaignProgress: string;
    campaignProgressPercentage: string;
    campaignProgressAtPostCreation: string;
}

 //KarmaDeatils
 export class KarmaDataRM extends PaginationBaseModel {
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
    profileUserId: string;
    isProfileCorporatePage: boolean;
    profileCorporatePageId: number;
}

export class KarmaDataVM {
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
    campaignsCount: number;
    foundationsCount: number;
    totalRaisedFundAmount: number;
    totalRaisedFundAmountText: string;
    campaignBackersCount: number;
    campaignBackersCountText: string;
}

// ConnectedCampaigns || FundRaised
export class KarmaCampaignsRM extends PaginationBaseModel {
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
    isRaisedAmountDataRequired: boolean;
    campaignFilterType: string;
}

export class KarmaCampaignsVM {
    campaignId: number;
    campaignName: string;
    campaignCoverPictureUrl: string;
    campaignCoverPictureThumbUrl: string;
    campaignTypeId: number;
    campaignType: string;
    campaignCreatorUserId: string;
    campaignCreatorUsername: string;
    campaignCreatorCorporatePageId: number;
    campaignCreatorProfilePictureUrl: string;
    campaignCreatorProfilePictureThumbUrl: string;
    campaignStartDate: string;
    campaignEndDate: string;
    campaignDurationInDays: number;
    isCampaignGoalAchieved: boolean;
    campaignPledgeAmount: number;
    campaignTotalRaisedAmount: number;
    campaignActivityGoal: number;
    campaignProgress: string;
    campaignProgressPercentage: string;
    campaignStatusId: number;
    campaignStatus: string;
    foundationId: number;
    foundationName: string;
    foundationLogoPictureUrl: string;
    foundationLogoPictureThumbUrl: string;
    trackingAppId: number;
    trackingAppName: string;
    trackingAppIconPictureUrl: string;
    trackingAppIconPictureThumbUrl: string;
    trackingMethodId: number;
    trackingMethodName: string;
    trackingMethodIconPictureUrl: string;
    trackingMethodIconPictureThumbUrl: string;
    backersCount: number;
}

// NonProfit
export class NonProfitRM extends PaginationBaseModel {
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
}

export class NonProfitVM {
    id: number;
    name: string;
    logoPictureUrl: string;
    logoPictureThumbUrl: string;
}

// Backers
export class BackersRM extends PaginationBaseModel {
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
    isProfileBackersRequired: boolean;
    profileUserId: string;
    isProfileCorporatePage: boolean;
    profileCorporatePageId: number;
    isOnlyCampaignBackersRequired: boolean;
    campaignId: number;
    isBusinessBackersRequired: boolean;
}

export class BackersVM {
    backerUserId: string;
    isBackerCorporatePage: boolean;
    backerCorporatePageId: number;
    backerUsername: string;
    backerFullname: string;
    backerProfilePictureUrl: string;
    backerProfilePictureThumbUrl: string;
    isFollowed: boolean;
    followerId: number;
}

// Followers
export class FollowersRM extends PaginationBaseModel {
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;

    profileUserId: string;
    isProfileCorporatePage: boolean;
    profileCorporatePageId: number;
}
export class FollowersVM {
    followerUserId: string;
    isFollowerCorporatePage: boolean;
    followerCorporatePageId: number;
    followerFullname: string;
    followerUsername: string;
    followerProfilePictureUrl: string;
    followerProfilePictureThumbUrl: string;
    isFollowed: boolean;
    followerId: number;
    followerCreatedAt: string;
}

// Followings
export class FollowingsRM extends PaginationBaseModel {
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
    
    profileUserId: string;
    isProfileCorporatePage: boolean;
    profileCorporatePageId: number;
}
export class FollowingsVM {
    followingUserId: string;
    isFollowingCorporatePage: boolean;
    followingCorporatePageId: number;
    followingFullname: string;
    followingUsername: string;
    followingProfilePictureUrl: string;
    followingProfilePictureThumbUrl: string;
    isFollowed: boolean;
    followerId: number;
    followingCreatedAt: string;
}

// Transactions
export class TransactionsRM extends PaginationBaseModel {
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
}
export class TransactionsVM {    
    id: number;
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
    transactionStatus: string;
    transactionTotalAmount: number;
    transactionTimeToString: string;
    benefittingFoundationId: number;
    benefittingFoundationName: string;
}

// TransactionDetails
export class TransactionDetailsVM {
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
    id: number;
    transactionStatus: string;
    transactionTotalAmount: number;
    donationAmount: number;
    paymentGatewayFeeAmount: number;
    platformFeeAmount: number;
    transactionTime: string;
    transactionTimeToString: string;
    transactionId: string;
    benefittingFoundationId: number;
    benefittingFoundationName: string;
    campaignId: number;
    campaignName: string;
    paymentCard: PaymentMethodVM;

    public TransactionDetailsVM(){
        this.paymentCard = new PaymentMethodVM();
    }
}

export class PaymentMethodVM {
    cardId: string;
    cardHolderName: string;
    cardLast4: string;
    cardExpYear: number;
    cardExpMonth: number;
    brandName: string;
    userId: string;
    isCorporatePage: boolean;
    corporatePageId: number;
}

//#endregion

// region payouts report
export class PayoutVM{
    ngoName: string;
    campaignName: string;
    campaignEndDate:string;
    campaignTotalRaisedAmount:number;
    campaignStatus: string;
    disbursed:boolean;
    campaignStatusId:number;
    campaignCreatorUserId: string;
    campaignCreatorCorporatePageId: number;
    campaignId: number;
}

export class PayoutDetail {
    data: PayoutData;
    history: PayoutHistory[];
}

export class PayoutData {
    transactionId: string;
    currentTransactionStatus: string;
    transactionCreatedAt: string;
    transactionStatusLastUpdatedAt: string;
    currencyCode: string;
    transactionAmount: number;
    backersCount: number;
    totalPledgedAmount: number;
    donorsCount: number;
    totalReceivedAmount: number;
}
export class PayoutHistory {
    payoutTransactionStatus: string;
    lastUpdatedAt: string;
    remark: string;
}
// #endregion

// export enum enumCampaignStatus {
//     PayoutPending = "Payout Pending",
//     CampaignOngoing = "Campaign Ongoing",
//     PayoutComplete = "Payout Complete",
// }