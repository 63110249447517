<!-- content starts-->
<div class="content-wrapper">
  <app-breadcrumb [items]="['User Management']" [active_item]="'Admin Users'"></app-breadcrumb>
  <div class="card">
    <div class="card-body">
      <div class="ngxdt users-list">
        <div class="ngxdt-header">
          <div class="ngxdt-buttons">
            <button
              class="btn btn-add nav-pills"
              (click)="openCreateModal(createFormModalId)"
            >
              <i class="fa fa-plus-square-o mr-2 align-self-center f-12"></i>
              <span>ADD</span>
            </button>
          </div>
          <div class="ngxdt-search">
            <div class="ngxdt-search-wrapper">
              <i class="fa fa-search"></i>
              <input
                (keydown)="$event.key === 'Enter' ? search() : true"
                type="text"
                (focus)="ngxDatatableSSRConfig.showSearchButton(true)"
                (blur)="ngxDatatableSSRConfig.showSearchButton(false)"
                placeholder="Search"
                [(ngModel)]="ngxDatatableSSRConfig.searchText"
              />
              <div class="btn-search">
                <button
                  *ngIf="ngxDatatableSSRConfig.searchBtnVisible"
                  (click)="search()"
                >
                  <i class="fa fa-filter"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="ngxdt-body">
          <ngx-datatable
            #table
            class="bootstrap"
            [messages]="{
              emptyMessage: ngxDatatableSSRConfig.isLoading
                ? ngxDatatableSSRConfig.loadingMessage
                : (ngxDatatableSSRConfig.emptyMessage | translate),
              totalMessage: ngxDatatableSSRConfig.totalMessage | translate,
              selectedMessage: false
            }"
            [footerHeight]="ngxDatatableSSRConfig.footerHeight"
            [limit]="ngxDatatableSSRConfig.pageSize"
            [rows]="rows"
            [columnMode]="ngxDatatableSSRConfig.columnMode.force"
            [offset]="ngxDatatableSSRConfig.tableOffset"
            [loadingIndicator]="false"
            [selectionType]="ngxDatatableSSRConfig.selectionType.single"
            [selected]="selected"
            (select)="openUpdateModal(updateFormModalId)"
            [scrollbarH]="true"
            [scrollbarV]="true"
            [virtualization]="false"
          >
            <ngx-datatable-column
              [width]="50"
              headerClass="make-center"
              cellClass="make-center"
              prop="id"
              name="#"
              [sortable]="false"
              [draggable]="false"
            >
              <ng-template
                let-rowIndex="rowIndex + 1"
                let-value="value"
                ngx-datatable-cell-template
              >
                {{
                  ngxDatatableSSRConfig.pageSize *
                    (ngxDatatableSSRConfig.pageNumber - 1) +
                    rowIndex
                }}.
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [width]="150"
              prop="fullName"
              name="Full Name"
              [sortable]="false"
              [draggable]="false"
            >
              <ng-template
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
              <span title="{{ value }}">
                {{ value }}
              </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [width]="150"
              prop="userName"
              name="User Name"
              [sortable]="false"
              [draggable]="false"
            >
              <ng-template
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
              <app-data-with-image [data]="value" title="{{ value }}" [imageUrl]="row.profilePictureThumbUrl"></app-data-with-image>
                
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [width]="150"
              prop="email"
              name="E-mail"
              [sortable]="false"
              [draggable]="false"
            >
              <ng-template
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
              <span title="{{ value }}">
                {{ value }}
              </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [width]="100"
              prop="phoneNumber"
              name="Phone Number"
              [sortable]="false"
              [draggable]="false"
            >
              <ng-template
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [width]="100"
              prop="createdDate"
              name="Created On"
              [sortable]="false"
              [draggable]="false"
            >
              <ng-template
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
                {{ value | date: "yyyy-MM-dd" }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <div>
                  <div class="page-size-control">
                    Show
                    <select
                      class="page-size-dropdown"
                      [(ngModel)]="ngxDatatableSSRConfig.pageSize"
                      (ngModelChange)="onPageSizeChanged($event)"
                    >
                      <option
                        *ngFor="let opt of ngxDatatableSSRConfig.limitOptions"
                        [ngValue]="opt.value"
                      >
                        {{ opt.key }}
                      </option>
                    </select>
                    entries
                  </div>
                  <div class="pagination-control">
                    <ngb-pagination
                      #ngbPage
                      [boundaryLinks]="false"
                      [collectionSize]="ngxDatatableSSRConfig.totalRecords"
                      [pageSize]="ngxDatatableSSRConfig.pageSize"
                      [page]="ngxDatatableSSRConfig.pageNumber"
                      [maxSize]="5"
                      (pageChange)="onPageChanged($event)"
                    >
                    </ngb-pagination>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- content Ends-->

<!-- Modal starts -->
<ng-template #createFormModalId let-modal>
  <form
    [formGroup]="creatForm"
    (ngSubmit)="onCreateFormSubmit()"
    autocomplete="off"
    class="theme-form"
  >
    <div class="modal-header">
      <span class="modal-title mt-2">Create User</span>
      <button
        type="button"
        class="btn btn-close"
        (click)="modal.close()"
        [disabled]="isSaving"
      >
        X
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <label class="form-label"
                >First Name:
                <input
                  type="text"
                  formControlName="firstName"
                  class="form-control"
                />
                <div class="form-control-error">
                  <span
                    *ngIf="fc.firstName.errors?.required && creatFormSubmitted"
                  >
                    First Name is required.
                  </span>
                </div>
              </label>
            </div>
            <div class="col-md-6">
              <label class="form-label"
                >Last Name:
                <input
                  type="text"
                  formControlName="lastName"
                  class="form-control"
                />
                <div class="form-control-error">
                  <span
                    *ngIf="fc.lastName.errors?.required && creatFormSubmitted"
                  >
                    Last Name is required.
                  </span>
                </div>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="form-label"
                >E-mail:
                <input
                  type="text"
                  formControlName="email"
                  class="form-control"
                />
                <div class="form-control-error">
                  <span *ngIf="fc.email.errors?.required && creatFormSubmitted">
                    Email is required.
                  </span>
                  <span *ngIf="fc.email.errors?.email && creatFormSubmitted">
                    Invalid email.
                  </span>
                  <span *ngIf="emailServerError.hasError && creatFormSubmitted">
                    {{ emailServerError.message }}
                  </span>
                </div>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="form-label"
                >Phone Number:
                <input
                  type="text"
                  formControlName="phoneNumber"
                  class="form-control"
                />
                <div class="form-control-error">
                  <span
                    *ngIf="
                      fc.phoneNumber.errors?.required && creatFormSubmitted
                    "
                  >
                    Phone Number is required.
                  </span>
                  <span
                    *ngIf="fc.phoneNumber.errors?.pattern && creatFormSubmitted"
                  >
                    Please, Enter 10 digit Phone Number.
                  </span>
                </div>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="form-label"
                >Username:
                <input
                  type="text"
                  formControlName="username"
                  class="form-control"
                />
                <div class="form-control-error">
                  <span
                    *ngIf="fc.username.errors?.required && creatFormSubmitted"
                  >
                    Username is required.
                  </span>
                  <span
                    *ngIf="usernameServerError.hasError && creatFormSubmitted"
                  >
                    {{ usernameServerError.message }}
                  </span>
                </div>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="form-label"
                >Password:
                <span class="help-btn">
                  <i
                    class="fa fa-question-circle"
                    aria-hidden="true"
                    tooltipClass="help-tooltip"
                    triggers="hover"
                    [autoClose]="true"
                    [ngbTooltip]="helpText"
                    placement="right"
                  ></i>
                </span>
                <ng-template #helpText>
                  <span>Passwords must have at least:</span>
                  <span>1. 8 characters long</span>
                  <span
                    >2. one special character from ( ! * @ # $ % ^ & + = )</span
                  >
                  <span>3. one digit (0-9)</span>
                  <span>4. one uppercase (A-Z)</span>
                </ng-template>
                <div class="password-showhide-control mt-1">
                  <input
                    [type]="hidePassword ? 'password' : 'text'"
                    formControlName="password"
                    class="form-control mt-1"
                  />
                  <button
                    type="button"
                    class="btn-showHide"
                    (click)="hidePassword = !hidePassword"
                  >
                    {{ hidePassword ? "show" : "hide" }}
                  </button>
                </div>
                <div class="form-control-error">
                  <span
                    *ngIf="fc.password.errors?.required && creatFormSubmitted"
                  >
                    Password is required.
                  </span>
                  <span
                    *ngIf="fc.password.errors?.pattern && creatFormSubmitted"
                  >
                    Wrong password format. Please see help.
                  </span>
                </div>
              </label>
            </div>
            <div class="col-md-6">
              <label class="form-label"
                >Confirm Password:
                <div class="password-showhide-control mt-1">
                  <input
                    [type]="hideConfirmPassword ? 'password' : 'text'"
                    formControlName="confirmPassword"
                    class="form-control mt-1"
                  />
                  <button
                    type="button"
                    class="btn-showHide"
                    (click)="hideConfirmPassword = !hideConfirmPassword"
                  >
                    {{ hideConfirmPassword ? "show" : "hide" }}
                  </button>
                </div>
                <div class="form-control-error">
                  <span
                    *ngIf="
                      fc.confirmPassword.errors?.required && creatFormSubmitted
                    "
                  >
                    Confirm Password is required.
                  </span>
                  <span
                    *ngIf="
                      fc.confirmPassword.errors?.matching && creatFormSubmitted
                    "
                  >
                    Confirm Password does not match
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer mt-3">
      <button
        type="button"
        class="btn btn-gray"
        (click)="modal.close()"
        [disabled]="isSaving"
      >
        Cancel
      </button>
      <button type="submit" class="btn btn-orange ml-3" [disabled]="isSaving">
        <i
          *ngIf="isSaving"
          class="fa"
          [ngClass]="{ 'fa-spin fa-spinner': isSaving }"
        ></i>
        {{ isSaving ? "Saving..." : "Save" }}
      </button>
    </div>
  </form>
</ng-template>
<ng-template #updateFormModalId let-modal>
  <form
    [formGroup]="updateForm"
    (ngSubmit)="onUpdateFormSubmit()"
    autocomplete="off"
    class="theme-form"
  >
    <div class="modal-header">
      <span class="modal-title mt-2">Update User</span>
      <button
        type="button"
        class="btn btn-close"
        (click)="modal.close()"
        [disabled]="isUpdating"
      >
        X
      </button>
    </div>
    <div class="modal-body">
      <input type="hidden" formControlName="userId" />
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <label class="form-label"
                >First Name:
                <input
                  type="text"
                  formControlName="firstName"
                  class="form-control"
                />
                <div class="form-control-error">
                  <span
                    *ngIf="fu.firstName.errors?.required && updateFormSubmitted"
                  >
                    First Name is required.
                  </span>
                </div>
              </label>
            </div>
            <div class="col-md-6">
              <label class="form-label"
                >Last Name:
                <input
                  type="text"
                  formControlName="lastName"
                  class="form-control"
                />
                <div class="form-control-error">
                  <span
                    *ngIf="fu.lastName.errors?.required && updateFormSubmitted"
                  >
                    Last Name is required.
                  </span>
                </div>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="form-label"
                >E-mail:
                <input
                  type="text"
                  formControlName="email"
                  class="form-control"
                />
                <div class="form-control-error">
                  <span
                    *ngIf="fu.email.errors?.required && updateFormSubmitted"
                  >
                    Email is required.
                  </span>
                  <span *ngIf="fu.email.errors?.email && updateFormSubmitted">
                    Invalid email.
                  </span>
                </div>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="form-label"
                >Phone Number:
                <input
                  type="text"
                  formControlName="phoneNumber"
                  class="form-control"
                />
                <div class="form-control-error">
                  <span
                    *ngIf="
                      fu.phoneNumber.errors?.required && updateFormSubmitted
                    "
                  >
                    Phone Number is required.
                  </span>
                  <span
                    *ngIf="
                      fu.phoneNumber.errors?.pattern && updateFormSubmitted
                    "
                  >
                    Please, Enter 10 digit Phone Number.
                  </span>
                </div>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="form-label"
                >Username:
                <input
                  type="text"
                  formControlName="username"
                  class="form-control"
                />
                <div class="form-control-error">
                  <span
                    *ngIf="fu.username.errors?.required && updateFormSubmitted"
                  >
                    Username is required.
                  </span>
                </div>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="form-label"
                >Note:
                <textarea
                  rows="4"
                  formControlName="description"
                  class="form-control"
                >
                </textarea>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer mt-3">
      <button
        type="button"
        class="btn btn-gray"
        (click)="modal.close()"
        [disabled]="isUpdating"
      >
        Cancel
      </button>
      <button type="submit" class="btn btn-orange ml-3" [disabled]="isUpdating">
        <i
          *ngIf="isUpdating"
          class="fa"
          [ngClass]="{ 'fa-spin fa-spinner': isUpdating }"
        ></i>
        {{ isUpdating ? "Updating..." : "Update" }}
      </button>
    </div>
  </form>
</ng-template>

<!-- Modal ends -->
