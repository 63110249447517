<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-3 d-flex">
        <a *ngIf="showBackLink" class="tap-back1" href="javascript:void(window.history.back());">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="" viewBox="0 0 14 14">
            <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
          </svg>
        </a>
        <h3>{{title}}</h3>
      </div>
      <div class="col-9 responsive-breadcrumb">
        <ol class="breadcrumb">
          <!-- <li class="breadcrumb-item">
            <a [routerLink]="'/user-management/user-list'">
              <app-feather-icons [icon]="'dashboard'"></app-feather-icons>
            </a>
          </li> -->
          <li class="breadcrumb-item" *ngFor="let item of items">{{item}}</li>
          <li class="breadcrumb-item active">{{active_item}}</li>
        </ol>
      </div>
    </div>
  </div>
</div>