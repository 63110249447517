import { Routes } from '@angular/router';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { SettingsComponent } from 'src/app/components/settings/settings.component';
import { TrackingAppsComponent } from 'src/app/components/masters/tracking-apps/tracking-apps.component';
import { TrackingMethodsComponent } from 'src/app/components/masters/tracking-methods/tracking-methods.component';
import { ListNgoComponent } from 'src/app/components/non-profits/list-ngo/list-ngo.component';
import { RptCampaignsComponent } from 'src/app/components/reports/campaigns/rpt-campaigns/rpt-campaigns.component';
import { UserListComponent } from "src/app/components/user-management/user-list/user-list.component";
import { BusinessListComponent } from 'src/app/components/business/business-list/business-list.component';
import { CampaignCreatorCampaignsComponent } from 'src/app/components/reports/campaign-creator-campaigns/campaign-creator-campaigns.component';
import { CampaignBackersListComponent } from 'src/app/components/reports/campaign-backers-list/campaign-backers-list.component';
import { UserProfileComponent } from 'src/app/components/reports/user-profile/user-profile.component';
import { AdminUserListComponent } from 'src/app/components/admin-user/admin-user-list/admin-user-list.component';
import { UserContentListComponent } from 'src/app/components/user-content/user-content-list/user-content-list.component';
import { ReportDonationsComponent } from 'src/app/components/reports/report-donations/report-donations.component';
import { full } from './full.routes';
import { ReportPayoutsHistoryComponent } from 'src/app/components/reports/report-payouts-history/report-payouts-history.component';

export const content: Routes = [
  {
    path: 'dashboard',component:DashboardComponent
  },
  {
    path: 'user-management/user-list',component:UserListComponent
  },
  {
    path: 'masters/tracking-apps',component:TrackingAppsComponent
  },
  {
    path: 'masters/tracking-methods',component:TrackingMethodsComponent
  },
  {
    path: 'non-profit/list',component:ListNgoComponent
  },
  {
    path: 'business/list',component:BusinessListComponent
  },
  {
    path: 'admin-users',component:AdminUserListComponent
  },
  {
    path: 'reports/campaigns',component: RptCampaignsComponent,    
  },  
  {
    path: 'reports/donations',component:ReportDonationsComponent
  },
  {
    path: 'reports/backers',component:CampaignBackersListComponent
  },
  {
    path: 'reports/campaign-creator',component:CampaignCreatorCampaignsComponent
  },
  // {
  //   path: 'reports/payouts-checklist',component:ReportPayoutsChecklistComponent
  // },
  {
    path: 'reports/payouts-history',component:ReportPayoutsHistoryComponent
  },
  {
    path: 'reports/user-profile',component:UserProfileComponent
  },
  {
    path: 'settings',component:SettingsComponent
  },
  {
    path: 'user-content',component:UserContentListComponent
  },
  {
    path: '',
    pathMatch: "full",
    redirectTo: 'user-management/user-list'
  }
];
