<div class="image-width" *ngIf="icon=='dashboard'">
  <svg id="dashboard" xmlns="http://www.w3.org/2000/svg" width="27.55" height="27.55" viewBox="0 0 27.55 27.55">
    <path id="Path_79" data-name="Path 79" d="M10.618,9.183H2.009A2.011,2.011,0,0,1,0,7.174V2.009A2.011,2.011,0,0,1,2.009,0h8.609a2.011,2.011,0,0,1,2.009,2.009V7.174A2.011,2.011,0,0,1,10.618,9.183ZM2.009,1.722a.287.287,0,0,0-.287.287V7.174a.287.287,0,0,0,.287.287h8.609a.287.287,0,0,0,.287-.287V2.009a.287.287,0,0,0-.287-.287Zm0,0" fill="#707070"/>
    <path id="Path_80" data-name="Path 80" d="M10.618,229.4H2.009A2.011,2.011,0,0,1,0,227.394V215.341a2.011,2.011,0,0,1,2.009-2.009h8.609a2.011,2.011,0,0,1,2.009,2.009v12.053A2.011,2.011,0,0,1,10.618,229.4ZM2.009,215.054a.287.287,0,0,0-.287.287v12.053a.287.287,0,0,0,.287.287h8.609a.287.287,0,0,0,.287-.287V215.341a.287.287,0,0,0-.287-.287Zm0,0" transform="translate(0 -201.853)" fill="#707070"/>
    <path id="Path_81" data-name="Path 81" d="M287.95,350.515h-8.609a2.011,2.011,0,0,1-2.009-2.009v-5.166a2.011,2.011,0,0,1,2.009-2.009h8.609a2.011,2.011,0,0,1,2.009,2.009v5.166A2.011,2.011,0,0,1,287.95,350.515Zm-8.609-7.461a.287.287,0,0,0-.287.287v5.166a.287.287,0,0,0,.287.287h8.609a.287.287,0,0,0,.287-.287v-5.166a.287.287,0,0,0-.287-.287Zm0,0" transform="translate(-262.409 -322.966)" fill="#707070"/>
    <path id="Path_82" data-name="Path 82" d="M287.95,16.071h-8.609a2.011,2.011,0,0,1-2.009-2.009V2.009A2.011,2.011,0,0,1,279.341,0h8.609a2.011,2.011,0,0,1,2.009,2.009V14.062A2.011,2.011,0,0,1,287.95,16.071ZM279.341,1.722a.287.287,0,0,0-.287.287V14.062a.287.287,0,0,0,.287.287h8.609a.287.287,0,0,0,.287-.287V2.009a.287.287,0,0,0-.287-.287Zm0,0" transform="translate(-262.409)" fill="#707070"/>
  </svg>
</div>

<div class="image-width" *ngIf="icon=='users'">
  <svg xmlns="http://www.w3.org/2000/svg" width="36.631" height="21.978" viewBox="0 0 36.631 21.978">
    <g id="users" data-name="Group 304" transform="translate(60 9643)">
      <path id="Path_71" data-name="Path 71" d="M20.613,20.655A5.756,5.756,0,1,1,26.37,14.9,5.756,5.756,0,0,1,20.613,20.655Zm0-10.466a4.71,4.71,0,1,0,4.71,4.71,4.71,4.71,0,0,0-4.71-4.71Z" transform="translate(-62.298 -9652.143)" fill="#707070"/>
      <path id="Path_72" data-name="Path 72" d="M29.648,32.276H21.275V31.23h7.849V29.137A5.239,5.239,0,0,0,23.891,23.9H16.565a5.239,5.239,0,0,0-5.233,5.233V31.23h7.849v1.047H10.809a.523.523,0,0,1-.523-.523V29.137a6.286,6.286,0,0,1,6.28-6.28h7.326a6.286,6.286,0,0,1,6.28,6.28v2.616A.523.523,0,0,1,29.648,32.276Z" transform="translate(-61.913 -9653.298)" fill="#707070"/>
      <path id="Path_73" data-name="Path 73" d="M32.747,24.256a4.3,4.3,0,1,1,4.3-4.3A4.3,4.3,0,0,1,32.747,24.256Zm0-7.558A3.256,3.256,0,1,0,36,19.954,3.256,3.256,0,0,0,32.747,16.7Z" transform="translate(-63.443 -9652.691)" fill="#707070"/>
      <path id="Path_74" data-name="Path 74" d="M39.7,32.5H31.33V31.457h7.849V30.236A3.638,3.638,0,0,0,35.545,26.6H30.254V25.556h5.292a4.686,4.686,0,0,1,4.68,4.68V31.98A.523.523,0,0,1,39.7,32.5Z" transform="translate(-63.595 -9653.525)" fill="#707070"/>
      <path id="Path_75" data-name="Path 75" d="M8.747,24.256a4.3,4.3,0,1,1,4.3-4.3,4.3,4.3,0,0,1-4.3,4.3Zm0-7.558A3.256,3.256,0,1,0,12,19.954,3.256,3.256,0,0,0,8.747,16.7Z" transform="translate(-61.421 -9652.691)" fill="#707070"/>
      <path id="Path_76" data-name="Path 76" d="M10.039,32.5H1.666a.523.523,0,0,1-.523-.523V30.236a4.686,4.686,0,0,1,4.68-4.68h5.292V26.6H5.823a3.638,3.638,0,0,0-3.634,3.634v1.221h7.849Z" transform="translate(-61.143 -9653.525)" fill="#707070"/>
    </g>
  </svg>
</div>

<div class="image-width" *ngIf="icon=='masters'">
  <svg xmlns="http://www.w3.org/2000/svg" width="29.372" height="29.372" viewBox="0 0 29.372 29.372">
    <g id="masters" transform="translate(0)">
      <path id="Path_83" data-name="Path 83" d="M28.711,11.626h-2.8a11.567,11.567,0,0,0-1.124-2.713l1.981-1.981a.662.662,0,0,0,0-.936L23.375,2.606a.662.662,0,0,0-.936,0L20.459,4.586a11.567,11.567,0,0,0-2.713-1.124V.661A.662.662,0,0,0,17.085,0h-4.8a.662.662,0,0,0-.661.661v2.8A11.567,11.567,0,0,0,8.914,4.586L6.933,2.606a.662.662,0,0,0-.936,0L2.606,6a.662.662,0,0,0,0,.936L4.586,8.914a11.567,11.567,0,0,0-1.124,2.713H.661A.662.662,0,0,0,0,12.288v4.8a.662.662,0,0,0,.661.661h2.8a11.567,11.567,0,0,0,1.124,2.713L2.606,22.439a.662.662,0,0,0,0,.936L6,26.767a.662.662,0,0,0,.936,0l1.981-1.981a11.567,11.567,0,0,0,2.713,1.124v2.8a.662.662,0,0,0,.661.661h4.8a.662.662,0,0,0,.661-.661v-2.8a11.567,11.567,0,0,0,2.713-1.124l1.981,1.981a.662.662,0,0,0,.936,0l3.392-3.392a.662.662,0,0,0,0-.936l-1.981-1.981a11.567,11.567,0,0,0,1.124-2.713h2.8a.662.662,0,0,0,.661-.661v-4.8A.662.662,0,0,0,28.711,11.626Zm-.2,5.257H25.755a.662.662,0,0,0-.641.5,10.7,10.7,0,0,1-1.149,2.773.662.662,0,0,0,.1.805l1.948,1.948-3.107,3.107-1.948-1.948a.662.662,0,0,0-.805-.1,10.7,10.7,0,0,1-2.773,1.149.662.662,0,0,0-.5.641V28.51H12.489V25.755a.662.662,0,0,0-.5-.641,10.7,10.7,0,0,1-2.773-1.149.662.662,0,0,0-.805.1L6.465,26.014,3.358,22.907l1.948-1.948a.662.662,0,0,0,.1-.805,10.7,10.7,0,0,1-1.149-2.773.662.662,0,0,0-.641-.5H.863V12.489H3.618a.662.662,0,0,0,.641-.5A10.7,10.7,0,0,1,5.407,9.219a.662.662,0,0,0-.1-.805L3.358,6.465,6.465,3.358,8.413,5.306a.662.662,0,0,0,.805.1,10.7,10.7,0,0,1,2.772-1.149.662.662,0,0,0,.5-.641V.863h4.394V3.618a.662.662,0,0,0,.5.641,10.7,10.7,0,0,1,2.773,1.149.662.662,0,0,0,.805-.1l1.948-1.948,3.107,3.107L24.066,8.413a.662.662,0,0,0-.1.805,10.7,10.7,0,0,1,1.149,2.772.662.662,0,0,0,.641.5H28.51v4.394Z" fill="#707070"/>
      <path id="Path_84" data-name="Path 84" d="M132.17,183.289a6.924,6.924,0,0,1-.929.691v-2.036a3.656,3.656,0,0,0-1.887-3.2,2.643,2.643,0,1,0-3.542,0,3.664,3.664,0,0,0-1.886,3.2v2.033a6.93,6.93,0,0,1-1.983-9.919.431.431,0,0,0-.7-.5,7.8,7.8,0,0,0,11.5,10.381.431.431,0,0,0-.571-.647ZM127.583,175a1.781,1.781,0,1,1-1.781,1.781A1.783,1.783,0,0,1,127.583,175Zm-2.795,9.431v-2.49a2.8,2.8,0,0,1,1.939-2.66,2.632,2.632,0,0,0,1.71,0,2.792,2.792,0,0,1,1.94,2.66v2.491a6.931,6.931,0,0,1-5.589,0Z" transform="translate(-112.897 -163.403)" fill="#707070"/>
      <path id="Path_85" data-name="Path 85" d="M172.755,119.785a7.817,7.817,0,0,0-4.878,1.715.431.431,0,0,0,.54.673,6.858,6.858,0,0,1,4.338-1.525,6.935,6.935,0,0,1,5.824,10.7.431.431,0,1,0,.724.469,7.8,7.8,0,0,0-6.549-12.029Z" transform="translate(-158.069 -112.895)" fill="#707070"/>
    </g>
  </svg>  
</div>

<div class="image-width" *ngIf="icon=='non-profits'">
  <svg xmlns="http://www.w3.org/2000/svg" width="27.637" height="27.637" viewBox="0 0 27.637 27.637">
    <g id="non-profits" transform="translate(-0.868 -0.868)">
      <path id="global" d="M13.819,0A13.819,13.819,0,1,0,27.637,13.819,13.834,13.834,0,0,0,13.819,0Zm-.81,6.783a18.892,18.892,0,0,1-3.616-.508c.186-.441.388-.864.605-1.265a6.992,6.992,0,0,1,3.01-3.218Zm0,1.621v4.605H7.952A20.349,20.349,0,0,1,8.836,7.8,20.5,20.5,0,0,0,13.009,8.4Zm0,6.225v4.605a20.5,20.5,0,0,0-4.173.6,20.349,20.349,0,0,1-.884-5.205Zm0,6.226v4.99A6.992,6.992,0,0,1,10,22.627c-.218-.4-.419-.824-.605-1.265A18.889,18.889,0,0,1,13.009,20.854Zm1.619,0a18.892,18.892,0,0,1,3.616.508c-.186.441-.388.864-.605,1.265a6.992,6.992,0,0,1-3.01,3.218Zm0-1.621V14.628h5.057a20.349,20.349,0,0,1-.884,5.205A20.5,20.5,0,0,0,14.628,19.234Zm0-6.225V8.4A20.5,20.5,0,0,0,18.8,7.8a20.349,20.349,0,0,1,.884,5.205Zm0-6.226V1.793a6.992,6.992,0,0,1,3.01,3.218c.218.4.419.824.605,1.265A18.892,18.892,0,0,1,14.628,6.783Zm4.433-2.545a12.293,12.293,0,0,0-1.31-1.968A12.214,12.214,0,0,1,22.07,4.842a15.3,15.3,0,0,1-2.259.978A16.406,16.406,0,0,0,19.062,4.238Zm-10.486,0a16.406,16.406,0,0,0-.75,1.581,15.323,15.323,0,0,1-2.259-.978A12.214,12.214,0,0,1,9.886,2.27,12.3,12.3,0,0,0,8.575,4.238Zm-1.3,3.1a21.684,21.684,0,0,0-.948,5.668H1.647A12.142,12.142,0,0,1,4.416,6.055,16.655,16.655,0,0,0,7.278,7.341ZM6.33,14.628A21.683,21.683,0,0,0,7.278,20.3a16.655,16.655,0,0,0-2.862,1.287,12.142,12.142,0,0,1-2.769-6.954Zm1.5,7.189a16.406,16.406,0,0,0,.75,1.581,12.307,12.307,0,0,0,1.31,1.968A12.211,12.211,0,0,1,5.567,22.8,15.3,15.3,0,0,1,7.826,21.818ZM19.062,23.4a16.406,16.406,0,0,0,.75-1.581,15.323,15.323,0,0,1,2.259.978,12.214,12.214,0,0,1-4.319,2.572A12.3,12.3,0,0,0,19.062,23.4Zm1.3-3.1a21.684,21.684,0,0,0,.948-5.668H25.99a12.142,12.142,0,0,1-2.769,6.954A16.655,16.655,0,0,0,20.359,20.3Zm.948-7.287a21.683,21.683,0,0,0-.948-5.668,16.655,16.655,0,0,0,2.862-1.287,12.142,12.142,0,0,1,2.769,6.954Zm0,0" transform="translate(0.868 0.868)" fill="#fff"/>
    </g>
  </svg>
</div>

<div class="image-width" *ngIf="icon=='business'">
  <svg xmlns="http://www.w3.org/2000/svg" width="28.928" height="28.928" viewBox="0 0 28.928 28.928">
    <g id="Layer_49" data-name="Layer 49" transform="translate(-3 -3)">
      <path id="Path_86" data-name="Path 86" d="M13,7h2.225V9.225H13Z" transform="translate(1.126 0.45)" fill="#707070"/>
      <path id="Path_87" data-name="Path 87" d="M13,11h2.225v2.225H13Z" transform="translate(1.126 0.901)" fill="#707070"/>
      <path id="Path_88" data-name="Path 88" d="M13,15h2.225v2.225H13Z" transform="translate(1.126 1.351)" fill="#707070"/>
      <path id="Path_89" data-name="Path 89" d="M13,19h2.225v2.225H13Z" transform="translate(1.126 1.802)" fill="#707070"/>
      <path id="Path_90" data-name="Path 90" d="M17,7h2.225V9.225H17Z" transform="translate(1.576 0.45)" fill="#707070"/>
      <path id="Path_91" data-name="Path 91" d="M17,11h2.225v2.225H17Z" transform="translate(1.576 0.901)" fill="#707070"/>
      <path id="Path_92" data-name="Path 92" d="M17,15h2.225v2.225H17Z" transform="translate(1.576 1.351)" fill="#707070"/>
      <path id="Path_93" data-name="Path 93" d="M17,19h2.225v2.225H17Z" transform="translate(1.576 1.802)" fill="#707070"/>
      <path id="Path_94" data-name="Path 94" d="M30.815,29.7H25.252V9.676h2.225V26.365a1.113,1.113,0,1,0,2.225,0V8.563A1.113,1.113,0,0,0,28.59,7.45H25.252V4.113A1.113,1.113,0,0,0,24.139,3H10.788A1.113,1.113,0,0,0,9.676,4.113V7.45H6.338A1.113,1.113,0,0,0,5.225,8.563V29.7H4.113a1.113,1.113,0,0,0,0,2.225h26.7a1.113,1.113,0,0,0,0-2.225ZM7.45,9.676H9.676V26.365a1.113,1.113,0,1,0,2.225,0V5.225H23.027V29.7H20.8V26.365a1.113,1.113,0,0,0-1.113-1.113h-4.45a1.113,1.113,0,0,0-1.113,1.113V29.7H7.45ZM18.576,29.7H16.351V27.477h2.225Z" transform="translate(0 0)" fill="#707070"/>
    </g>
  </svg>
</div>

<div class="image-width" *ngIf="icon=='reports'">
  <svg id="preview" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path id="Path_66" data-name="Path 66" d="M5.1,13h9.936a.877.877,0,0,0,0-1.753H5.1A.877.877,0,1,0,5.1,13Z" transform="translate(-0.712 -3.865)" fill="#707070"/>
    <path id="Path_67" data-name="Path 67" d="M12.693,15.469H5.1a.877.877,0,1,0,0,1.753h7.6a.877.877,0,1,0,0-1.753Z" transform="translate(-0.712 -5.315)" fill="#707070"/>
    <path id="Path_68" data-name="Path 68" d="M12.693,19.687H5.1a.877.877,0,1,0,0,1.753h7.6a.877.877,0,0,0,0-1.753Z" transform="translate(-0.712 -6.764)" fill="#707070"/>
    <path id="Path_69" data-name="Path 69" d="M12.693,23.906H5.1a.877.877,0,1,0,0,1.753h7.6a.877.877,0,1,0,0-1.753Z" transform="translate(-0.712 -8.214)" fill="#707070"/>
    <path id="Path_70" data-name="Path 70" d="M23.794,19.823l-3.516-3.516a4.928,4.928,0,0,0-2-7.471V5.391a.7.7,0,0,0-.206-.5L13.388.206a.7.7,0,0,0-.5-.206H2.578A2.581,2.581,0,0,0,0,2.578V21.422A2.581,2.581,0,0,0,2.578,24H15.7a2.581,2.581,0,0,0,2.578-2.578V17.883a4.879,4.879,0,0,0,1-.582L22.8,20.817a.7.7,0,0,0,.994-.994Zm-4.972-3.977a3.5,3.5,0,0,1-1.429.868l-.048.015a3.529,3.529,0,0,1-1.008.146,3.516,3.516,0,0,1,0-7.031,3.528,3.528,0,0,1,1.008.146l.048.015a3.517,3.517,0,0,1,1.43,5.84ZM15.881,4.687H13.594V2.4Zm.994,16.734A1.173,1.173,0,0,1,15.7,22.594H2.578a1.173,1.173,0,0,1-1.172-1.172V2.578A1.173,1.173,0,0,1,2.578,1.406h9.609V5.391a.7.7,0,0,0,.7.7h3.984V8.467a5.008,5.008,0,0,0-.539-.029,4.922,4.922,0,0,0,0,9.844,5.009,5.009,0,0,0,.539-.029Z" fill="#707070"/>
  </svg>
</div>

<div class="image-width" *ngIf="icon=='settings'">
  <svg xmlns="http://www.w3.org/2000/svg" width="26.505" height="27.218" viewBox="0 0 26.505 27.218">
    <g id="settings" transform="translate(-6.705)">
      <g id="Group_633" data-name="Group 633" transform="translate(6.705)">
        <g id="Group_632" data-name="Group 632">
          <path id="Path_95" data-name="Path 95" d="M32.751,16.593l-2.5-1.956a9.539,9.539,0,0,0,.059-1.027,9.849,9.849,0,0,0-.059-1.027l2.5-1.957a1.225,1.225,0,0,0,.3-1.563l-2.6-4.5a1.227,1.227,0,0,0-1.5-.541L25.99,5.2a10.069,10.069,0,0,0-1.771-1.029L23.77,1.045A1.2,1.2,0,0,0,22.568,0H17.351a1.2,1.2,0,0,0-1.2,1.033L15.7,4.176a10.439,10.439,0,0,0-1.769,1.03l-2.96-1.188a1.206,1.206,0,0,0-1.494.532l-2.6,4.508a1.217,1.217,0,0,0,.295,1.57l2.5,1.955a9.909,9.909,0,0,0-.059,1.027c0,.312.019.644.057,1.027l-2.5,1.959a1.228,1.228,0,0,0-.295,1.563l2.6,4.5a1.229,1.229,0,0,0,1.5.541l2.952-1.185A10.222,10.222,0,0,0,15.7,23.043l.449,3.13a1.2,1.2,0,0,0,1.2,1.044h5.217a1.2,1.2,0,0,0,1.2-1.033l.45-3.141a10.439,10.439,0,0,0,1.769-1.03l2.96,1.188a1.209,1.209,0,0,0,1.494-.532l2.615-4.524A1.226,1.226,0,0,0,32.751,16.593Zm-.686,1-2.7,4.557-3.247-1.3a.569.569,0,0,0-.551.073,9.4,9.4,0,0,1-2.079,1.207.567.567,0,0,0-.347.445l-.573,3.517-5.293-.06-.5-3.456a.569.569,0,0,0-.347-.445,9.036,9.036,0,0,1-2.074-1.2.565.565,0,0,0-.346-.118.555.555,0,0,0-.212.041l-3.34,1.26-2.6-4.5a.1.1,0,0,1,.008-.117l2.752-2.151a.568.568,0,0,0,.213-.517,9.568,9.568,0,0,1-.087-1.208,9.8,9.8,0,0,1,.087-1.208.567.567,0,0,0-.213-.517L7.852,9.628l2.7-4.557,3.247,1.3a.566.566,0,0,0,.552-.073A9.325,9.325,0,0,1,16.431,5.1a.569.569,0,0,0,.346-.445l.574-3.518,5.294.061.494,3.457a.569.569,0,0,0,.347.445,9,9,0,0,1,2.074,1.2.563.563,0,0,0,.557.076l3.34-1.26,2.6,4.5a.094.094,0,0,1-.008.116L29.3,11.884a.567.567,0,0,0-.213.517,9.646,9.646,0,0,1,.087,1.208,9.63,9.63,0,0,1-.087,1.208.568.568,0,0,0,.213.517l2.749,2.15A.09.09,0,0,1,32.064,17.591Z" transform="translate(-6.705)" fill="#707070"/>
          <path id="Path_96" data-name="Path 96" d="M155.022,149.333a5.67,5.67,0,1,0,5.67,5.67A5.677,5.677,0,0,0,155.022,149.333Zm0,10.207A4.536,4.536,0,1,1,159.559,155,4.541,4.541,0,0,1,155.022,159.54Z" transform="translate(-141.769 -141.395)" fill="#707070"/>
        </g>
      </g>
    </g>
  </svg>
</div>

<div class="image-width" *ngIf="icon=='user-content'">
  <svg xmlns="http://www.w3.org/2000/svg" width="28.021" height="28.021" viewBox="0 0 28.021 28.021">
    <path id="user-content" d="M5.473,6.184A1.1,1.1,0,0,1,6.568,5.09H21.454a1.095,1.095,0,1,1,0,2.189H6.568A1.1,1.1,0,0,1,5.473,6.184Zm17.076,4.378a1.1,1.1,0,0,0-1.095-1.095H6.568a1.095,1.095,0,0,0,0,2.189H21.454A1.1,1.1,0,0,0,22.549,10.563ZM6.568,13.847a1.095,1.095,0,0,0,0,2.189H11.6a1.095,1.095,0,1,0,0-2.189Zm20.493,8.57a3.284,3.284,0,0,1-4.645,4.643l-5.986-6a1.092,1.092,0,0,1-.28-.481l-1.224-4.418a1.095,1.095,0,0,1,1.373-1.34l4.309,1.309a1.093,1.093,0,0,1,.457.274Zm-8.878-2.7,4.023,4.031L23.755,22.2l-4.046-4.055L17.567,17.5Zm7.329,4.244-.211-.211L23.753,25.3l.212.212a1.095,1.095,0,0,0,1.547-1.549ZM23.643,0H4.378A4.383,4.383,0,0,0,0,4.378V23.643a4.383,4.383,0,0,0,4.378,4.378H17.787a1.095,1.095,0,1,0,0-2.189H4.378a2.192,2.192,0,0,1-2.189-2.189V4.378A2.192,2.192,0,0,1,4.378,2.189H23.643a2.192,2.192,0,0,1,2.189,2.189V17.842a1.095,1.095,0,0,0,2.189,0V4.378A4.383,4.383,0,0,0,23.643,0Zm0,0" fill="#fff"/>
  </svg>
</div>