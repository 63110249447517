<div class="ngxdt user-content-list">
    
    <div class="ngxdt-header">
      
      <!-- <div class="ngxdt-search">
        <div class="ngxdt-search-wrapper">
          <i class="fa fa-search"></i>
          <input type="text" (keydown)="($event.key === 'Enter')?search():true" (focus)="ngxDatatableSSRConfig.showSearchButton(true)" (blur)="ngxDatatableSSRConfig.showSearchButton(false)" placeholder="Search" [(ngModel)]="ngxDatatableSSRConfig.searchText">
          <div class="btn-search" >
            <button *ngIf="ngxDatatableSSRConfig.searchBtnVisible" (click)="search()">
              <i class="fa fa-filter"></i>
            </button>
          </div>
          
        </div>
      </div> -->
    </div>

    <div class="ngxdt-body">
      <ngx-datatable
        #table
        class="bootstrap"
        [messages]="{
          emptyMessage: ngxDatatableSSRConfig.isLoading ? ngxDatatableSSRConfig.loadingMessage : ngxDatatableSSRConfig.emptyMessage | translate,
          totalMessage: ngxDatatableSSRConfig.totalMessage | translate,
          selectedMessage: false
        }"
        [footerHeight]="ngxDatatableSSRConfig.footerHeight"
        [limit]="ngxDatatableSSRConfig.pageSize"
        [rows]="rows"
        [columnMode]="ngxDatatableSSRConfig.columnMode.force"
        [offset]="ngxDatatableSSRConfig.tableOffset"
        [loadingIndicator]="false"
        [selectionType]="ngxDatatableSSRConfig.selectionType.cell"
        [scrollbarH]="true"
        [scrollbarV]="true"
        [virtualization]="false"
      >
        <ngx-datatable-column [width]="50" headerClass="make-center" cellClass="make-center" prop="id" name="#" [sortable]="false" [draggable]="false">
          <ng-template let-rowIndex="rowIndex + 1" let-value="value" ngx-datatable-cell-template >
            {{(ngxDatatableSSRConfig.pageSize * (ngxDatatableSSRConfig.pageNumber - 1)) + rowIndex}}.
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="150" prop="contentReporterFullname" name="Content Reporter" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="150" prop="contentReporterUsername" name="Reporter Username" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <app-data-with-image [data]="value" [imageUrl]="row.contentReporterProfilePictureThumbUrl"></app-data-with-image>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="100" prop="reportedContentStatus" name="Status" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="100" prop="contentReportedAt" name="Reported At" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value | date:'MM-dd-yyyy HH:mm:ss' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="100" prop="contentReviewerFullname" name="Reviewed By" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="100" prop="contentReviewedAt" name="Reviewed At" [sortable]="false" [draggable]="false">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value | date:'MM-dd-yyyy HH:mm:ss' }}
          </ng-template>
        </ngx-datatable-column>             
        
        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template>
            <div>
              <div class="page-size-control">
                Show
                <select class="page-size-dropdown" [(ngModel)]="ngxDatatableSSRConfig.pageSize" (ngModelChange)="onPageSizeChanged($event)">
                  <option *ngFor="let opt of ngxDatatableSSRConfig.limitOptions" [ngValue]="opt.value">{{opt.key}}</option>
                </select>
                entries
              </div>
              <div class="pagination-control">
                <ngb-pagination
                      #ngbPage
                      [boundaryLinks]="false"
                      [collectionSize]="ngxDatatableSSRConfig.totalRecords"
                      [pageSize]="ngxDatatableSSRConfig.pageSize"
                      [page]="ngxDatatableSSRConfig.pageNumber"
                      [maxSize]="5"
                      (pageChange)="onPageChanged($event)">
                </ngb-pagination>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-footer>

      </ngx-datatable>
    </div>

  </div>
