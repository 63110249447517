<!-- content starts-->
<div class="content-wrapper">
  <app-breadcrumb [items]="['User Management']" [active_item]="'Users'"></app-breadcrumb>
  <div class="card">
    <div class="card-body">
      <div class="ngxdt users-list">

        <div class="ngxdt-header">
          <div class="ngxdt-search">
            <div class="ngxdt-search-wrapper">
              <i class="fa fa-search"></i>
              <input type="text" (keydown)="$event.key === 'Enter' ? search() : true"
                (focus)="ngxDatatableSSRConfig.showSearchButton(true)"
                (blur)="ngxDatatableSSRConfig.showSearchButton(false)" placeholder="Search"
                [(ngModel)]="ngxDatatableSSRConfig.searchText" />
              <div class="btn-search">
                <button *ngIf="ngxDatatableSSRConfig.searchBtnVisible" (click)="search()">
                  <i class="fa fa-filter"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="ngxdt-body">
          <ngx-datatable #table class="bootstrap" [messages]="{
              emptyMessage: ngxDatatableSSRConfig.isLoading
                ? ngxDatatableSSRConfig.loadingMessage
                : (ngxDatatableSSRConfig.emptyMessage | translate),
              totalMessage: ngxDatatableSSRConfig.totalMessage | translate,
              selectedMessage: false
            }" [footerHeight]="ngxDatatableSSRConfig.footerHeight" [limit]="ngxDatatableSSRConfig.pageSize"
            [rows]="rows" [columnMode]="ngxDatatableSSRConfig.columnMode.force"
            [offset]="ngxDatatableSSRConfig.tableOffset" [loadingIndicator]="false"
            [selectionType]="ngxDatatableSSRConfig.selectionType.single" [selected]="selected"
            (select)="openModal(modalId)" [scrollbarH]="true" [scrollbarV]="true" 
            [virtualization]="false">
            <ngx-datatable-column [width]="50" headerClass="make-center" cellClass="make-center" prop="id" name="#"
              [sortable]="false" [draggable]="false">
              <ng-template let-rowIndex="rowIndex + 1" let-value="value" ngx-datatable-cell-template>
                {{
                ngxDatatableSSRConfig.pageSize *
                (ngxDatatableSSRConfig.pageNumber - 1) +
                rowIndex
                }}.
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="150" prop="fullName" name="Full Name" [sortable]="false" [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span title="{{ value }}">
                  {{ value }}
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="150" prop="userName" name="User Name" [sortable]="false" [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <app-data-with-image [data]="value" title="{{ value }}"
                  [imageUrl]="row.profilePictureThumbUrl"></app-data-with-image>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="150" prop="email" name="E-mail" [sortable]="false" [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span title="{{ value }}">
                  {{ value }}
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="100" prop="phoneNumber" name="Phone Number" [sortable]="false"
              [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="100" prop="createdDate" name="Created On" [sortable]="false"
              [draggable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date: "yyyy-MM-dd" }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <div>
                  <div class="page-size-control">
                    Show
                    <select class="page-size-dropdown" [(ngModel)]="ngxDatatableSSRConfig.pageSize"
                      (ngModelChange)="onPageSizeChanged($event)">
                      <option *ngFor="let opt of ngxDatatableSSRConfig.limitOptions" [ngValue]="opt.value">
                        {{ opt.key }}
                      </option>
                    </select>
                    entries
                  </div>
                  <div class="pagination-control">
                    <ngb-pagination #ngbPage [boundaryLinks]="false"
                      [collectionSize]="ngxDatatableSSRConfig.totalRecords" [pageSize]="ngxDatatableSSRConfig.pageSize"
                      [page]="ngxDatatableSSRConfig.pageNumber" [maxSize]="5" (pageChange)="onPageChanged($event)">
                    </ngb-pagination>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- content Ends-->

<!-- Modal starts -->
<ng-template #modalId let-modal>
  <form #modalForm="ngForm" class="theme-form">
    <div class="modal-header">
      <span class="modal-title mt-2">{{ modalTitle }}</span>
      <button type="button" class="btn btn-close" (click)="modal.close()" [disabled]="isUpdating">
        X
      </button>
    </div>
    <div class="modal-body">
      <input type="hidden" [(ngModel)]="modalData.userId" name="userId" [value]="modalData.userId" />

      <div class="row">
        <div class="col-md-4">
          <img [src]="modalData.profilePictureThumbUrl" class="img-thumbnail w-100" alt="User image">
          <div class="mt-1" *ngIf="modalData.userStatus != 'Deleted'">
            <button type="button" style="background-color: white; color: #FF4714; border: none;"
              class="btn-sm btn-orange p-0 m-0" (click)="openDeletePopup(modalData.userId)">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"
                class="bi bi-x-circle-fill pt-1" viewBox="0 0 16 16">
                <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
              </svg>
              Delete Account
            </button>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-12">
              <label class="form-label">Full Name:
                <input type="text" [(ngModel)]="modalData.fullName" name="fullName" class="form-control" disabled />
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="form-label">Username:
                <input type="text" [(ngModel)]="modalData.userName" name="userName" class="form-control" disabled />
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="form-label">E-mail:
                <input type="email" [(ngModel)]="modalData.email" name="email" class="form-control" disabled />
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="form-label">Phone Number:
                <input type="text" [(ngModel)]="modalData.phoneNumber" name="phoneNumber" class="form-control"
                  disabled />
              </label>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-12">
              <label class="form-label">Age Range:
                <input type="text" 
                      [(ngModel)]="modalData.ageRange" 
                      name="ageRange" 
                      class="form-control"
                      disabled>
              </label>
            </div>
          </div> -->
          <div class="row">
            <div class="col-md-12">
              <label class="form-label">Description:
                <textarea rows="4" [(ngModel)]="modalData.description" name="description" class="form-control"
                  disabled></textarea>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="modal-footer mt-3">
      <button *ngIf="modalData.statusId == 1 || modalData.statusId == 3" type="button" class="btn btn-orange" (click)="updateStatus(modalData.id, enumBusinessStatus.Approve)" [disabled]="isSaving">
        <i *ngIf="isSaving" class="fa" [ngClass]="{'fa-spin fa-spinner': isSaving}"></i>
        {{isSaving?"Updating":"APPROVE"}}
      </button>
      <button *ngIf="modalData.statusId == 1 || modalData.statusId == 2" type="button" class="btn btn-gray ml-4" (click)="updateStatus(modalData.id, enumBusinessStatus.Decline)" [disabled]="isSaving">
        <i *ngIf="isSaving" class="fa" [ngClass]="{'fa-spin fa-spinner': isSaving}"></i>
        {{isSaving?"Updating":"DECLINE"}}
      </button>
    </div> -->
    <div class="modal-footer mt-3">
      <button type="button" class="btn btn-gray" (click)="modal.close()" [disabled]="isUpdating">
        Cancel
      </button>
    </div>
  </form>
  <!-- delete account modal  -->
  <div class="modal mx-auto" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}" style="margin-top:6% ;">
    <div class="modal-dialog" role="document">
      <div class="modal-content mx-auto" style="width:76%; border-radius: 20px;">
        <div class="modal-body text-center" style="color: #FF4714;">
          <p class="font-weight-bold">Are you sure<br>you want to delete the account?</p>
          <p class="font-weight-bold">This action is not reversible</p>
          <p style="color: #707070;">All data including Profile, Posts<br>
            Comments and Likes will be deleted from the <br> application</p>
          <div class="mx-auto">
            <p class="mb-0 font-weight-bold">Reason for deletion:</p>
            <textarea class="form-control deleteComment" [(ngModel)]="deleteComment"></textarea>
          </div>
        </div>
        <div class="modal-footer mx-auto mb-2">
          <button type="button" class="btn btn-orange mx-3" (click)="deleteUser()" [disabled]="deletingUser">
            {{deletingUser?"Deleting...":"Delete"}}
          </button>
          <button type="button" class="btn btn-gray mx-3" (click)="closeDeletePopup()"
            [disabled]="deletingUser">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  <!-- delete account modal  -->
</ng-template>
<!-- Modal ends -->