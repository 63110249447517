import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResponseModel } from '../../../shared/models/response.model';
import { DataService } from '../../../shared/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class TrackingAppsService {

  constructor(private service: DataService) { }

  //get all with pagination
  public getTrackingApps(data: any): Observable<ResponseModel> { 
    let url = `/campaignapi/master/trackingApps/getAll`;
    return this.service.post(url,data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }  
}
