<div class="row">
	<div class="col">
		<div ngbDropdown class="d-inline-block">
			<button type="button" class="btn btn-outline-orange" id="downloadBtn" ngbDropdownToggle>
				 Download
			</button>
			<div ngbDropdownMenu aria-labelledby="downloadBtn" style="width: 100%;">
				<!-- <button ngbDropdownItem (click)="downloadAsCSV()">Export as CSV</button> -->
				<button ngbDropdownItem (click)="downloadAsPDF()">Export as PDF</button> 
			</div>
		</div>
	</div>
</div>
